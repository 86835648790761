import React, { useEffect } from "react"

import TradingChart from "../../modules/exchange/old-components/TradingChart"
import { useLocation } from "react-router-dom"
import { setTheme, toggleTheme } from "../../redux/slices/themeSlice"
import { useDispatch } from "react-redux"

function MobileChart() {
  // ** Hooks
  const location = useLocation()
  const dispatch = useDispatch()

  // ** URL Params
  const queryParams = new URLSearchParams(location.search)

  // Extract the parameters
  const theme = queryParams.get("theme") || "dark"
  const pairId = queryParams.get("pair_id") || "btcusdt"

  useEffect(() => {
    dispatch(setTheme(theme))
  }, [])

  return (
    <TradingChart
      containerStyle={{
        height: "100vh",
        width: "100vw",
        borderRadius: 0
      }}
      selectedMarketId={pairId}
    />
  )
}

export default MobileChart
