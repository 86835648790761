export const formatNumber = (num, decimalPlaces = 2) => {
  if (num === 0 || !num) {
    return '0.00'
  }
  
  if (num) {
    if (num === '0' || num === '0.0' || num === '0.00' || num === 0) {
      return '0.00'
    }
    if (typeof num === 'string') {
      num = Number(num)
    }
    if (num >= 1000) {
      const numStr = num.toFixed(decimalPlaces)
      const decimalIndex = numStr.indexOf('.')
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalIndex !== -1 ? 0 : decimalPlaces,
      })
      return formatter.format(parseFloat(numStr))
    } else {
      const numStr = num.toFixed(decimalPlaces)
      const decimalIndex = numStr.indexOf('.')
      return decimalIndex !== -1 ? parseFloat(numStr).toString() : numStr
    }
  }
}
