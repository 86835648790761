import React from 'react'

// react router
import { useHistory } from 'react-router-dom'

// styles
import Styles from './warningModal.module.css'

// icons
import { ReactComponent as WarningIcon } from '../../../assets/icons/imported/alert.svg'

// redux
import { useSelector } from 'react-redux'

// components
import WenbitModal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'

function DangerModal({
    open = false,
    handleClose
}) {

    const accentColor = 'var(--warning-color)'
    const modalData = useSelector(state => state.warningModalState)
    
  return (
    <>
        <WenbitModal
            open={open}
            showCloseButton={true}
            onClose={handleClose}
            showLineHeader={true}
            lineHeaderColor={accentColor}
            render={() => (
                <div className={Styles.bodyContainer}>
                    <WarningIcon
                        color={accentColor}
                        height={55}
                        width={55}
                    />
                    <span className={Styles.title}>{modalData.data.title}</span>
                    <span className={Styles.description}>{modalData.data.subtitle}</span>

                    <div className={Styles.buttonsContainer}>
                        <Button
                            text='Cancel'
                            variant='link'
                            style={{
                                width: 'fit-content',
                                color: accentColor
                            }}
                            onClick={() => {
                                handleClose()
                            }}
                        />
                        <Button
                            text='Confirm'
                            style={{
                                width: 'fit-content',
                                backgroundColor: accentColor
                            }}
                            onClick={() => {
                                modalData.data.callback()
                            }}
                        />
                    </div>
                </div>
            )}
        />
    </>
  )
}

export default DangerModal