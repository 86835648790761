import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

// styles
import Styles from "./modifySMSAuthModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { _getUserInfo } from "../../../../redux/actions"
import { setLoggedIn } from "../../../../redux/slices/loggedIn"
import { setUser, logoutUser } from "../../../user/redux/slices/userDataSlice"
import { clearBalances } from "../../../../redux/slices/balancesSlice"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// utils
import { FormattedMessage, injectIntl } from "react-intl"
import { closeModifySMSAuthModal } from "./helpers"
import { hideEmail } from "../../../helpers/HideEmail"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { _sendNewOtp, _sendOtp, _verifyOtp } from "./redux/actions"

const inputStyle = {
  marginBottom: 15
}

const modifySMSAuthSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9+\s()-]*$/, "Invalid phone number")
    .required("Phone number is required"),
  mobileVerificationCode: Yup.string()
    .required("Code is required")
    .matches(/^\d{5}$/, "Code must be 5 digits")
    .test("is-number", "Code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    }),
  currentMobileVerificationCode: Yup.string()
    .required("Code is required")
    .matches(/^\d{5}$/, "Code must be 5 digits")
    .test("is-number", "Code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    }),
  emailVerificationCode: Yup.string()
    .required("Code is required")
    .matches(/^\d{5}$/, "Code must be 5 digits")
    .test("is-number", "Code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    })
})


function ModifySMSAuthModal() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(modifySMSAuthSchema),
    mode: "onSubmit"
  })
  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.modifySMSAuthModalState.open)
  const modalData = useSelector((state) => state.modifySMSAuthModalState.data)
  const userData = useSelector((state) => state.userData.value)

  const [loading, setLoading] = useState(false)
  const [mobileCodeTimer, setMobileCodeTimer] = useState(0)
  const [currentMobileCodeTimer, setCurrentMobileCodeTimer] = useState(0)
  const [emailCodeTimer, setEmailCodeTimer] = useState(0)

  const handleGetMobileCode = () => {
    const phoneNumber = watch("phoneNumber")
    _sendNewOtp(
      {
        phone_number: phoneNumber
      },
      () => {
        startTimer(setMobileCodeTimer)
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  const handleGetCurrentMobileCode = () => {
    _sendOtp(
      {
        verification_type: "phone",
        action_type: "modify"
      },
      () => {
        startTimer(setCurrentMobileCodeTimer)
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  const handleGetEmailCode = () => {
    _sendOtp(
      {
        verification_type: "email",
        action_type: "modify"
      },
      () => {
        startTimer(setEmailCodeTimer)
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  const startTimer = (setTimer) => {
    setTimer(60)
  }

  useEffect(() => {
    reset()
  }, [modalOpen])

  useEffect(() => {
    let interval
    if (mobileCodeTimer > 0) {
      interval = setInterval(() => {
        setMobileCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [mobileCodeTimer])

  useEffect(() => {
    let interval
    if (currentMobileCodeTimer > 0) {
      interval = setInterval(() => {
        setCurrentMobileCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [currentMobileCodeTimer])

  useEffect(() => {
    let interval
    if (emailCodeTimer > 0) {
      interval = setInterval(() => {
        setEmailCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [emailCodeTimer])

  const onSubmit = (formData) => {
    setLoading(true)
    // console.log("CALL API HERE", formData)
    _verifyOtp(
      {
        email_otp: formData.emailVerificationCode,
        old_phone_otp: formData.currentMobileVerificationCode,
        new_phone_otp: formData.mobileVerificationCode,
        phone_number: formData.phoneNumber
      },
      () => {
        _getUserInfo(
          (data) => {
            setLoading(false)
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "SMS Authentication Modified",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            closeModifySMSAuthModal()
            dispatch(setLoggedIn(true))
            dispatch(setUser(data))
            setLoading(false)
            if (data.errors) {
              dispatch(logoutUser())
              dispatch(setLoggedIn(false))
              dispatch(clearBalances())
              setLoading(false)
            }
          },
          (error) => {
            closeModifySMSAuthModal()
            setLoading(false)
            if (error?.errors) {
              dispatch(logoutUser())
              dispatch(clearBalances())
              dispatch(setLoggedIn(false))
            }
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
    // in success or error -> setLoading(false)
  }

  return (
    <>
      <WenbitModal
        open={modalOpen}
        onClose={closeModifySMSAuthModal}
        title={modalData?.title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            inputLabel="Enter your mobile number"
            showBorder
            containerStyle={{ ...inputStyle }}
            type="phoneNumber"
            onChange={(num) => setValue("phoneNumber", num)}
            error={errors.phoneNumber}
          />

          <Input
            inputLabel="Mobile verification code"
            // indicatorLabel="Enter the 6-digit code sent to the phone number ends with ** 98"
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={
                  mobileCodeTimer > 0 ? `${mobileCodeTimer} sec` : "Get Code"
                }
                onClick={handleGetMobileCode}
                disabled={mobileCodeTimer > 0}
              />
            }
            {...register("mobileVerificationCode")}
            error={errors.mobileVerificationCode}
            maxLength={5}
          />

          <Input
            inputLabel="Current mobile verification code"
            indicatorLabel={
              userData?.phones?.length &&
              `Enter the 6-digit code sent to the phone number ends with ** ${userData.phones[0]?.number?.slice(
                -2
              )}`
            }
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={
                  currentMobileCodeTimer > 0
                    ? `${currentMobileCodeTimer} sec`
                    : "Get Code"
                }
                onClick={handleGetCurrentMobileCode}
                disabled={currentMobileCodeTimer > 0}
              />
            }
            {...register("currentMobileVerificationCode")}
            error={errors.currentMobileVerificationCode}
            maxLength={5}
          />

          <Input
            inputLabel="Email Verification Code"
            indicatorLabel={
              userData?.email &&
              `Enter the 6-digit code sent to ${hideEmail(userData.email)}.com`
            }
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={emailCodeTimer > 0 ? `${emailCodeTimer} sec` : "Get Code"}
                onClick={handleGetEmailCode}
                disabled={emailCodeTimer > 0}
              />
            }
            {...register("emailVerificationCode")}
            error={errors.emailVerificationCode}
            maxLength={5}
          />

          <Link to="/">Security verification unavailable?</Link>

          <Button
            text="Submit"
            type="submit"
            style={{
              marginTop: 20
            }}
            isLoading={loading}
          />
        </form>
      </WenbitModal>
    </>
  )
}

export default injectIntl(ModifySMSAuthModal)
