import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { FormattedMessage, injectIntl } from "react-intl"
import {
  logoutUser,
  setUser
} from "../../modules/user/redux/slices/userDataSlice"
import { formatNumber } from "../../modules/helpers/formatNumber"

// components
import NavLink from "./NavLink"
import Button from "../Button/Button"
import HoverPopper from "../HoverPopper"
import MoreMegaMenu from "./MoreMegaMenu"
import BlogsMegaMenu from "./BlogsMegaMenu"
import TableButton from "../TableButton/TableButton"
import LanguageCurrencySelector from "./LanguageCurrencySelector"
import DrawerNavigation from "../DrawerNavigation/DrawerNavigation"

// helpers
import { hideEmail } from "../../modules/helpers/HideEmail"

// styles
import Styles from "./header.module.css"

// assets
import LogoDark from "../../assets/icons/wen-logos/wen-header-dark.svg"
import LogoLight from "../../assets/icons/wen-logos/wen-header-light.svg"

// icons
import { BiChevronDown } from "react-icons/bi"
import { ReactComponent as SunIcon } from "../../assets/icons/imported/sun.svg"
import { ReactComponent as MoonIcon } from "../../assets/icons/imported/moon.svg"
import { ReactComponent as LinkIcon } from "../../assets/icons/imported/link.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/imported/clock.svg"
import { ReactComponent as ClosedIcon } from "../../assets/icons/imported/close.svg"
import { ReactComponent as LogOutIcon } from "../../assets/icons/imported/logout.svg"
import { ReactComponent as WalletIcon } from "../../assets/icons/imported/wallet.svg"
import { ReactComponent as ReturnIcon } from "../../assets/icons/imported/return.svg"
import { ReactComponent as MarketIcon } from "../../assets/icons/imported/market.svg"
import { ReactComponent as ProfileIcon } from "../../assets/icons/imported/profile.svg"
import { ReactComponent as BurgerMenuIcon } from "../../assets/icons/imported/burger.svg"
import { ReactComponent as PortfolioIcon } from "../../assets/icons/imported/portfolio.svg"
// MUI
import Menu from "@mui/material/Menu"
import Fade from "@mui/material/Fade"
import Popper from "@mui/material/Popper"
import { IconButton } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import LinearProgress from "@mui/material/LinearProgress"

// routes
import { exchangeRoutes } from "../../constants/routesConstants"

// redux
import { useSelector, useDispatch } from "react-redux"
import { setLoggedIn } from "../../redux/slices/loggedIn"
import { toggleTheme } from "../../redux/slices/themeSlice"
import { _getDepositAddress, _logout } from "../../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../modules/snackbar/redux/snackbarSlice"
import {
  setWarningModalData,
  setWarningModalOpen
} from "../../modules/modals/warning/redux/warningModalSlice"
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from "../../modules/modals/select-currency/redux/selectCurrencyModalSlice"
import {
  setWithdrawFormModalOpen,
  setwithdrawFormModalData
} from "../../modules/modals/deposit-withdraw/redux/withdrawFormModalSlice"

import { estimateUnitValue } from "../../modules/helpers/estimateValueBase"
import KYCStatusBadge from "../KYCStatusBadge/KYCStatusBadge"

// utils
import {
  hideBackdrop,
  showBackdrop
} from "../../utility/backdrop/backdropHelpers"
import { handleDeposit } from "../../utility/deposit-withdraw-helpers/handleDeposit"
import { openDrawerNavigation } from "../DrawerNavigation/helpers"
import { handleWithdraw } from "../../utility/deposit-withdraw-helpers/handleWithdraw"

function Header() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  // drawer navigation state
  const drawerOpen = useSelector(
    (state) => state.languageCurrencySelectModalState.open
  )

  const handleWalletNotFound = () => {
    dispatch(setWarningModalOpen(true))
    dispatch(
      setWarningModalData({
        title: "Wallet Address Not Found",
        subtitle: "There was no wallet found for the selected coin",
        callback: () => dispatch(setWarningModalOpen(false))
      })
    )
  }

  // redux states
  const theme = useSelector((state) => state.theme.value)
  const userData = useSelector((state) => state.userData?.value)
  const hasDocumentLabel = userData?.labels?.some(
    (label) => label.key === "document"
  )
  const TotalDeposits = useSelector(
    (state) => state.fundsData.data.total_deposits
  )
  const TotalFunds = useSelector((state) => state.fundsData.data.total_funds)
  const TotalBtcBalance = useSelector(
    (state) => state.fundsData.data.total_balance_btc
  )
  const TotalWithdraws = useSelector(
    (state) => state.fundsData.data.total_withdrawals
  )
  const hideBalance = useSelector((state) => state.userData.value.hide_balance)
  const marketsList = useSelector((state) => state.marketList.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const currencies = useSelector((state) => state.currencies.value)
  const convertedPrice = estimateUnitValue(
    "usdt",
    "btc",
    parseFloat(0),
    currencies,
    marketsList,
    marketTickers
  )

  // profile popup
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // wallet popup
  const [walletPopperOpen, setWalletPopperOpen] = useState(false)
  const [walletAnchorEl, setWalletAnchorEl] = useState(null)
  const handleWalletHover = (event) => {
    setWalletAnchorEl(event.currentTarget)
    setWalletPopperOpen((previousOpen) => !previousOpen)
  }
  const handleWalletLeave = (e) => {
    const { bottom } = e.currentTarget.getBoundingClientRect()
    const { clientX, clientY } = e
    const isOutside = clientY > bottom

    if (isOutside) {
      return
    }
    setWalletAnchorEl(false)
    setWalletPopperOpen(false)
  }
  const canBeOpen = walletPopperOpen && Boolean(walletAnchorEl)
  const id = canBeOpen ? "transition-popper" : undefined

  const handleLogout = () => {
    showBackdrop()
    _logout(
      () => {
        dispatch(logoutUser())
        dispatch(setLoggedIn(false))
        dispatch(setUser(""))
        setAnchorEl(null)
        hideBackdrop()
        // history.push('/markets')
      },
      (error) => {
        hideBackdrop()
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  function getEmailUserName(email) {
    const emailParts = email.split("@")
    const username = emailParts[0]
    return username
  }

  return (
    <header
      className={Styles.headerContainer}
      style={{
        backgroundColor: exchangeRoutes.includes(
          location.pathname.split("/")[1]
        )
          ? "var(--secondary-color)"
          : theme === "light"
          ? "white"
          : "black",
        border:
          exchangeRoutes.includes(location.pathname.split("/")[1]) && "none",
        borderWidth: theme === "light" && 0,
        boxShadow:
          theme === "dark"
            ? "0 0 100px 10px rgba(0, 0, 0, 0.511)"
            : "0 0 10px 10px rgba(0, 0, 0, 0.02)"
      }}
    >
      {/* left container */}
      <div className={Styles.leftContainer}>
        {/* <Link to='/trade'> */}
        <img
          className={Styles.headerLogo}
          src={theme === "dark" ? LogoDark : LogoLight}
          alt="logo"
          onClick={() => history.push("/")}
        />
        {/* </Link> */}

        {/* divider */}
        <div className={Styles.divider}></div>

        {/* mobile theme toggle */}
        <IconButton
          aria-label="menu"
          className={Styles.mobileThemeToggleContainer}
          onClick={() => dispatch(toggleTheme())}
        >
          {theme === "dark" ? (
            <SunIcon width={20} height={20} color={"var(--gray-color)"} />
          ) : (
            <MoonIcon width={20} height={20} color={"var(--gray-color)"} />
          )}
        </IconButton>

        {/* links container */}
        <div className={Styles.linksContainer}>
          <NavLink
            to="/markets"
            text={<FormattedMessage id="page.header.navbar.markets" />}
            isCurrentRoute={location.pathname.startsWith("/markets")}
          />
          <NavLink
            to="/trade"
            text={<FormattedMessage id="page.header.navbar.exchange" />}
            isCurrentRoute={location.pathname.startsWith("/trade")}
          />
          {/* <NavLink to="/launchpad" text={<FormattedMessage id='page.header.navbar.launchpad' />} isCurrentRoute={location.pathname.startsWith('/launchpad')}/> */}
          {/* <NavLink to="/blogs" text={<FormattedMessage id='page.header.navbar.blogs' />} isCurrentRoute={location.pathname.startsWith('/blogs')}/> */}
          <NavLink
            to="/convert/BTC_USDT"
            text={<FormattedMessage id="page.header.navbar.convert" />}
            isCurrentRoute={location.pathname.startsWith("/convert")}
          />

          <HoverPopper
            popperContent={<BlogsMegaMenu />}
            popperStyle={{
              padding: "20px",
              borderRadius: "0 0 7.5px 7.5px"
            }}
          >
            <NavLink
              to="/blogs/trending"
              text={<FormattedMessage id="page.header.navbar.blogs" />}
              isCurrentRoute={location.pathname.startsWith("/blogs")}
            />
          </HoverPopper>

          <HoverPopper
            popperContent={<MoreMegaMenu />}
            popperStyle={{
              padding: "20px",
              borderRadius: "0 0 7.5px 7.5px"
            }}
          >
            <NavLink
              // to="/blogs/trending"
              text="More"
              // isCurrentRoute={location.pathname.startsWith("/blogs")}
            />
          </HoverPopper>
        </div>
      </div>
      {userData.email ? (
        // right container signed in
        <div className={Styles.rightContainerSignedIn}>
          <LanguageCurrencySelector />

          {/* buy crypto - wallet */}
          <button
            onClick={() => history.push("/launchpad")}
            className={`${theme === "light" && Styles.headerButtonLight} ${
              Styles.headerButtons
            } ${
              location.pathname.startsWith("/account/buy") &&
              Styles.selectedHeaderButton
            }`}
            style={{
              backgroundColor:
                !exchangeRoutes.includes(location.pathname.split("/")[1]) &&
                theme === "dark" &&
                "#00191ecf"
            }}
          >
            <MarketIcon width={14} style={{ marginRight: 5 }} />
            Buy Crypto
          </button>

          <button
            className={`${theme === "light" && Styles.headerButtonLight} ${
              Styles.headerButtons
            } ${
              location.pathname.startsWith("/account/wallet") &&
              Styles.selectedHeaderButton
            }`}
            onMouseEnter={handleWalletHover}
            onMouseLeave={handleWalletLeave}
            // onClick={() => {
            //   history.push("/account/wallet/spot")
            // }}
            style={{
              backgroundColor:
                !exchangeRoutes.includes(location.pathname.split("/")[1]) &&
                theme === "dark" &&
                "#00191ecf"
            }}
          >
            <WalletIcon width={17} style={{ marginRight: 5 }} />
            Wallet
          </button>

          {/* wallet popper */}
          <Popper
            id={id}
            open={canBeOpen}
            anchorEl={walletAnchorEl}
            placement="bottom-start"
            sx={{
              zIndex: 2
            }}
            onMouseLeave={() => {
              setWalletAnchorEl(false)
              setWalletPopperOpen(false)
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div
                  className={Styles.walletPopperContainer}
                  style={{
                    boxShadow:
                      theme === "dark"
                        ? "0px 2px 20px rgba(0,0,0,.4)"
                        : "0px 2px 20px rgba(0,0,0,.1)",
                    backgroundColor: exchangeRoutes.includes(
                      location.pathname.split("/")[1]
                    )
                      ? "var(--secondary-color)"
                      : theme === "light"
                      ? "white"
                      : "black"
                  }}
                >
                  {/* header */}
                  <div className={Styles.walletPopperHeader}>
                    <div>
                      <WalletIcon width={17} />
                      <span>Wallet Balance</span>
                    </div>
                    <TableButton
                      buttonText="View Wallet"
                      buttonIcon={<LinkIcon width={11} />}
                      onClick={() => history.push("/account/wallet/spot/all")}
                    />
                  </div>

                  <div className={Styles.walletBalanceContainer}>
                    <span>
                      {hideBalance
                        ? "********"
                        : `$${formatNumber(TotalFunds, 2)}`}
                    </span>
                    <span>
                      {" "}
                      &#8776;{" "}
                      {hideBalance
                        ? "********"
                        : `${formatNumber(TotalBtcBalance, 6)}`}{" "}
                      BTC
                    </span>
                  </div>

                  {/* deposit/withdraw container */}
                  <div
                    className={Styles.depositWithdrawContainer}
                    style={{
                      backgroundColor: exchangeRoutes.includes(
                        location.pathname.split("/")[1]
                      )
                        ? theme === "light"
                          ? "white"
                          : "black"
                        : "var(--secondary-color)"
                    }}
                  >
                    <div>
                      <div>
                        <ReturnIcon width={14} />
                        <span>Total Deposited</span>
                      </div>
                      <div>
                        <span>
                          {hideBalance
                            ? "*******"
                            : `$${formatNumber(TotalDeposits)}`}
                        </span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <ClockIcon width={14} />
                        <span>Total Withdrawn</span>
                      </div>
                      <div>
                        <span>
                          {hideBalance
                            ? "*******"
                            : `$${formatNumber(TotalWithdraws)}`}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* buttons container */}
                  <div className={Styles.walletPopporButtonsContainer}>
                    <Button
                      text="Deposit"
                      onClick={() => {
                        dispatch(
                          setSelectCurrencyModalData({
                            title: "Select coin to deposit",
                            showTabs: false,
                            type: "coin",
                            searchPlaceholder: "Search coin name",
                            currenciesToHide: [],
                            disableBackdropClick: true,
                            callback: (coin) => {
                              handleDeposit(coin?.id)
                              // dispatch(setSelectedCoinId(coin.id))
                              // showBackdrop()
                              // _getDepositAddress(
                              //   {
                              //     coin: coin.id
                              //   },
                              //   (data) => {
                              //     console.log(data)
                              //     if (data.address === null) {
                              //       setTimeout(() => {
                              //         _getDepositAddress(
                              //           {
                              //             coin: coin.id
                              //           },
                              //           (data) => {
                              //             hideBackdrop()
                              //             if (data.address) {
                              //               setSelectCurrencyModalOpen(false)
                              //               dispatch(
                              //                 setDepositAddress(data.address)
                              //               )
                              //               dispatch(
                              //                 setWalletDepositModalOpen(true)
                              //               )
                              //             } else {
                              //               handleWalletNotFound()
                              //             }
                              //           },
                              //           (err) => {
                              //             hideBackdrop()
                              //             handleWalletNotFound()
                              //           }
                              //         )
                              //       }, 15000) // Delay of 15000 milliseconds (15 seconds)
                              //     } else {
                              //       hideBackdrop()
                              //       setSelectCurrencyModalOpen(false)
                              //       dispatch(setDepositAddress(data.address))
                              //       dispatch(setWalletDepositModalOpen(true))
                              //     }
                              //   },
                              //   (err) => {
                              //     hideBackdrop()
                              //   }
                              // )
                            }
                          })
                        )
                        dispatch(setSelectCurrencyModalOpen(true))
                      }}
                    />
                    <Button
                      text="Withdraw"
                      variant="dark"
                      style={{
                        backgroundColor: exchangeRoutes.includes(
                          location.pathname.split("/")[1]
                        )
                          ? theme === "light"
                            ? "white"
                            : "black"
                          : "var(--secondary-color)"
                      }}
                      onClick={handleWithdraw}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </Popper>

          {/* user info */}
          {/* <ProfileAvatar size={40} /> */}
          <img
            src={userData?.user_avatar?.url}
            alt=""
            className={Styles.userPicture}
          />
          <div className={Styles.usernameStatusContainer}>
            <span className={Styles.userName} onClick={handleClick}>
              {userData.username ?? getEmailUserName(userData.email)}
              <BiChevronDown />
            </span>
            <KYCStatusBadge
              status={`${
                hasDocumentLabel
                  ? userData?.labels?.find((el) => el.key === "document")
                      ?.value === "verified"
                    ? "verified"
                    : "pending"
                  : "not-verified"
              }`}
            />
          </div>

          {/* profile menu */}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: exchangeRoutes.includes(
                  location.pathname.split("/")[1]
                )
                  ? "var(--secondary-color)"
                  : theme === "light"
                  ? "white"
                  : "black",
                borderRadius: "0 0 5px 5px",
                transform: "translateX(-7px) translateY(33.5px) !important"
              }
            }}
            MenuListProps={{
              sx: {
                backgroundColor: exchangeRoutes.includes(
                  location.pathname.split("/")[1]
                )
                  ? "var(--secondary-color)"
                  : theme === "light"
                  ? "white"
                  : "black",
                border: "1px (--secondary-color) solid",
                color: "#6C7080",
                zIndex: 1
              }
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "hidden",
                filter:
                  theme === "dark"
                    ? "drop-shadow(0px 2px 20px rgba(0,0,0,.4))"
                    : "drop-shadow(0px 2px 10px rgba(0,0,0,.1))",
                borderRadius: 0,
                mt: 1.5,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  backgroundColor: exchangeRoutes.includes(
                    location.pathname.split("/")[1]
                  )
                    ? "var(--secondary-color)"
                    : theme === "light"
                    ? "white"
                    : "black",
                  transform: "translateY(-50%) rotate(45deg)",
                  border: "1px #6C7080 solid",
                  zIndex: 1
                }
              }
            }}

            // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div
              style={{
                fontSize: 11,
                fontFamily: '"Overpass"',
                display: "flex",
                alignItems: "center",
                gap: "6px",
                padding: "6px 36px",
                color: "#AEB1BF"
              }}
            >
              {hideEmail(userData.email)}
              {/* <KYCStatusBadge
                status={`${
                  hasDocumentLabel
                    ? userData?.labels?.find((el) => el.key === "document")
                        ?.value === "verified"
                      ? "verified"
                      : "pending"
                    : "not-verified"
                }`}
              /> */}
            </div>
            <MenuItem
              onClick={() => {
                handleClose()
                history.push("/account/profile")
              }}
              style={{
                fontSize: 13,
                fontFamily: '"Overpass"',
                display: "flex",
                gap: "6px",
                alignItem: "center",
                // justifyContent: 'flex-start',
                padding: "8px 36px",
                marginBottom: 2,
                marginTop: 2
              }}
              sx={{
                "&:hover": {
                  // backgroundColor: theme === 'dark' ? 'black' : 'rgba(211, 211, 211, 0.1)',
                  backgroundColor: exchangeRoutes.includes(
                    location.pathname.split("/")[1]
                  )
                    ? theme === "light"
                      ? "white"
                      : "black"
                    : "var(--secondary-color)",
                  borderRight: "2.75px var(--primary-color) solid",
                  color: "var(--primary-color) !important"
                }
              }}
            >
              <ProfileIcon
                height={17}
                width={17}
                style={{
                  marginRight: 13,
                  marginLeft: -2.5
                }}
              />
              <span style={{ marginTop: 2 }}>My Account</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose()
                history.push("/account/wallet/history")
              }}
              style={{
                fontSize: 13,
                fontFamily: '"Overpass"',
                display: "flex",
                gap: "6px",
                alignItem: "center",
                // justifyContent: 'flex-start',
                padding: "8px 36px",
                marginBottom: 2,
                marginTop: 2
              }}
              sx={{
                "&:hover": {
                  // backgroundColor: theme === 'dark' ? 'black' : 'rgba(211, 211, 211, 0.1)',
                  backgroundColor: exchangeRoutes.includes(
                    location.pathname.split("/")[1]
                  )
                    ? theme === "light"
                      ? "white"
                      : "black"
                    : "var(--secondary-color)",
                  borderRight: "2.75px var(--primary-color) solid",
                  color: "var(--primary-color) !important"
                }
              }}
            >
              <WalletIcon
                height={17}
                style={{
                  marginRight: 10,
                  marginLeft: -4
                }}
              />
              <span style={{ marginTop: 2 }}>Wallet History</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose()
                handleLogout()
              }}
              style={{
                fontSize: 13,
                fontFamily: '"Overpass"',
                display: "flex",
                gap: "6px",
                alignItem: "center",
                padding: "8px 36px",
                marginBottom: 2,
                marginTop: 2
              }}
              sx={{
                "&:hover": {
                  // backgroundColor: theme === 'dark' ? 'black' : 'rgba(211, 211, 211, 0.1)',
                  backgroundColor: exchangeRoutes.includes(
                    location.pathname.split("/")[1]
                  )
                    ? theme === "light"
                      ? "white"
                      : "black"
                    : "var(--secondary-color)",
                  borderRight: "2.75px var(--negative-color) solid",
                  color: "var(--negative-color) !important"
                }
              }}
            >
              <LogOutIcon
                height={17}
                style={{
                  marginRight: 10
                }}
              />
              <span style={{ marginTop: 2 }}>Log out</span>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        // right container logged out
        <div className={Styles.rightContainerLoggedOut}>
          <LanguageCurrencySelector />
          <Button
            text={<FormattedMessage id="page.header.navbar.signIn" />}
            variant="dark"
            onClick={() => history.push("/login")}
            style={{
              backgroundColor:
                !exchangeRoutes.includes(location.pathname.split("/")[1]) &&
                "var(--secondary-color)"
            }}
          />
          <Button
            text={<FormattedMessage id="page.header.navbar.signUp" />}
            onClick={() => {
              history.push("/signup")
            }}
          />
        </div>
      )}

      {/* ---------------DRAWER NAVIGATION--------------- */}
      {/* ----------------------------------------------- */}
      <div className={Styles.menuButtonContainer}>
        <IconButton
          aria-label="menu"
          style={{ marginRight: 20 }}
          className={Styles.menuButton}
          onClick={openDrawerNavigation}
        >
          <BurgerMenuIcon
            width={20}
            height={20}
            color={theme === "dark" && "white"}
          />
        </IconButton>
      </div>

      <DrawerNavigation />
      {/* ----------------------------------------------- */}
      {/* ----------------------------------------------- */}
    </header>
  )
}

export default injectIntl(Header)
