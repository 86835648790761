import { API } from "../../utility/API"

//************************************//
export const _logout = (callback, callbackErr) => {
    // persistStore().purge()
    API.delete(`authenticate/identity/sessions`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _resendEmailVerification = (data, callback, callbackErr) => {
    // persistStore().purge()
    API.post(`/authenticate/identity/users/email/generate_code`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getUserInfo = (callback, callbackErr) => {
    // persistStore().purge()
    API.get(`authenticate/resource/users/me`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response)
        })
}
//************************************//
export const _getBalances = (callback) => {
    API.get(`trading/account/balances`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function ({data}) {
        })
}
//************************************//
export const _getDepositAddress = (data, callback, callbackErr) => {
    API.get(`trading/account/deposit_address/${data.coin}`)
        .then(function ({data}) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _verifyToken = (data, callback, callbackErr) => {
    API.post(`authenticate/identity/users/email/confirm_code`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _resetPassword = (data, callback, callbackErr) => {
    API.post(`authenticate/identity/users/password/confirm_code`, data)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response.data)
        })
}
//************************************//
export const _getFundsData = (callback, callbackErr) => {
    // persistStore().purge()
    API.get(`trading/account/portfolio/total`)
        .then(function ({ data }) {
            callback(data)
        })
        .catch(function (error) {
            callbackErr(error.response)
        })
}