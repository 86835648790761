import React, { useEffect, useState } from "react"

// react router
import { Link, useHistory } from "react-router-dom"

// styles
import Styles from "./landingPage.module.css"

// redux
import { useSelector } from "react-redux"

// svgs/photos
import heroSVG from "../assets/hero.svg"
import hero2SVG from "../assets/hero2/hero2PNG.png"
import hero2PNGLight from "../assets/hero2/hero2-light.png"
import hero2FrameSVG from "../assets/hero2/frame-hero2.svg"

// icons
import { ReactComponent as RightArrow } from "../../../assets/icons/imported/right-arrow.svg"
import Safety from "../assets/services-icons/Icon.svg"
import FastKYC from "../assets/services-icons/Icon-6.svg"
import Wallet from "../assets/get-started-icons/wallet.svg"
import LowCharges from "../assets/services-icons/Icon-2.svg"
import Diagram from "../assets/get-started-icons/diagram.svg"
import EasyDeposit from "../assets/services-icons/Icon-1.svg"
import AddUser from "../assets/get-started-icons/add-user.svg"
import Support_24_7 from "../assets/services-icons/Icon-7.svg"
import BonusRefferals from "../assets/services-icons/Icon-3.svg"
import DeepEncryption from "../assets/services-icons/Icon-5.svg"
import FastTransaction from "../assets/services-icons/Icon-4.svg"
import CreditCard from "../assets/get-started-icons/credit-card.svg"
// -- light moode icons
import SafetyLight from "../assets/services-icons/light/Icon-light.svg"
import FastKYCLight from "../assets/services-icons/light/Icon-light-6.svg"
import LowChargesLight from "../assets/services-icons/light/Icon-light-2.svg"
import EasyDepositLight from "../assets/services-icons/light/Icon-light-1.svg"
import Support_24_7Light from "../assets/services-icons/light/Icon-light-7.svg"
import BonusRefferalsLight from "../assets/services-icons/light/Icon-light-3.svg"
import DeepEncryptionLight from "../assets/services-icons/light/Icon-light-5.svg"
import FastTransactionLight from "../assets/services-icons/light/Icon-light-4.svg"

// components
import Button from "../../../components/Button/Button"
import GettingStarted from "../components/GettingStarted"
import SectionHeader from "../../../components/SectionHeader/SectionHeader"
import MarketCoinCard from "../../../components/MarketCoinCard/MarketCoinCard"

// apis
import { _getCoinKline } from "../redux/actions"
import ServicesCard from "../components/ServicesCard/ServicesCard"
import { _getCurrencies, _getMarketsList } from "../../exchange/redux/actions"

// react-slick
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function LandingPage() {
  // ** Hooks
  const history = useHistory()
  document.title = "Wenbit: Landing"

  // ** States
  const theme = useSelector((state) => state.theme.value)
  const isLoggedIn = useSelector((state) => state.userData.value.email)

  const [coins, setCoins] = useState([])
  const [kLineArray, setKLineArray] = useState([])
  // const coinsToShow = ['shibusdt', 'solusdt', 'manausdt', 'btcusdt', 'ftmusdt', 'axsusdt', 'dotusdt', 'sandusdt']
  const marketList = useSelector((state) => state.marketList.value)
  const coinsToShow = [
    "linkusd",
    "uniusd",
    "shibusd",
    "ftmusd",
    "ethusd",
    "btcusd"
    // "usdtusd",
  ]

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 840)
  const [hideStartTrading, setHideStartTrading] = useState(
    window.innerWidth < 900
  )

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 840)
    setHideStartTrading(window.innerWidth < 900)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  //react slick - carousel state
  const [currentCoinSlide, setCurrentCoinSlide] = useState(0)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (index) => setCurrentCoinSlide(index),
    dotsStyle: () => {
      return theme === "dark"
        ? Styles.reactSlickDarkDots
        : Styles.reactSlickLightDots
    }
  }
  //update the current slide index every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCoinSlide((currentCoinSlide + 1) % coinsToShow.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [currentCoinSlide])

  useEffect(() => {
    _getCurrencies((data) => {
      setCoins(data)
      _getCoinKline(
        {
          pairs: coinsToShow,
          limit: 15
        },
        (data) => {
          setKLineArray(data)
        }
      )
    })
  }, [])

  return (
    <div className={Styles.pageContainer}>
      {/* Hero section */}
      <div className={`${Styles.card} ${Styles.heroContainer}`}>
        <div className={Styles.heroTextContainer}>
          <div className={Styles.heroTextHeaderContainer}>
            <div>Grow your way</div>
            <div>to greatness,</div>
            <div>one bit at a time</div>
          </div>

          <div className={Styles.heroTextBodyContainer}>
            <p>
              WenBit is the only cryptocurrency exchange worth your time.
              <br />
              If you're ready to trade like a boss,
              <Link to="/signup">sign up</Link> to WenBit now.
            </p>

            <span>Don't just aspire - join the crypto revolution today!</span>
          </div>

          <Button
            text="Join the WenBit elite"
            style={{
              width: isMobileView ? "100%" : "fit-content",
              fontSize: isMobileView ? 18 : 14
            }}
            onClick={() => history.push("/trade")}
          />
        </div>
        <img src={heroSVG} className={Styles.heroSVG} />
      </div>

      {/* Top Markets section */}
      <div className={Styles.topMarketContainer}>
        <SectionHeader
          sectionTitle="Top Market"
          title="Unleash your crypto potential"
        />
        <div
          className={`${Styles.gridContainer} ${Styles.coinChartGridContainer}`}
        >
          {coinsToShow.map((pairId) => {
            const klineData = kLineArray.find((el) =>
              Object.keys(el).includes(pairId)
            )
            const market = marketList.find((el) => el?.id === pairId)
            return (
              <MarketCoinCard
                key={market?.id}
                icon={market?.icon_url}
                name={market?.base_coin_name}
                tag={market?.name?.split("/")[0]}
                price={market?.last}
                priceChangePercentage={market?.price_change_percent}
                style={{ width: "100%" }}
                kLineArray={klineData}
              />
            )
          })}
        </div>

        <div className={Styles.coinChartMobileContainer}>
          <Slider {...sliderSettings} arrows={false}>
            {coinsToShow.slice(0, 4).map((pairId) => {
              const klineData = kLineArray.find((el) =>
                Object.keys(el).includes(pairId)
              )
              const market = marketList.find((el) => el?.id === pairId)
              return (
                <MarketCoinCard
                  key={market?.id}
                  icon={market?.icon_url}
                  name={market?.base_coin_name}
                  tag={market?.name?.split("/")[0]}
                  price={market?.last}
                  priceChangePercentage={market?.price_change_percent}
                  kLineArray={klineData}
                />
              )
            })}
          </Slider>
        </div>
      </div>

      <GettingStarted />

      {/* trade and register */}
      <div
        className={`${Styles.tradeAndRegisterContainer} ${
          theme === "light" && Styles.tradeAndRegisterContainerLight
        }`}
      >
        {!isLoggedIn && (
          <div className={Styles.tradeAndRegisterMobileButtonsContainer}>
            <Button
              variant="dark"
              style={{
                backgroundColor: "var(--secondary-color)"
              }}
              type="link"
              text="Sign In"
              linkTo="/login"
            />
            <Button type="link" linkTo="/signup" text="Register" />
          </div>
        )}

        {/* left section */}
        <div className={Styles.tradeAndRegisterLeftSection}>
          <img
            src={theme === "light" ? hero2PNGLight : hero2SVG}
            className={Styles.hero2SVG}
          />
        </div>

        {/* right section */}
        <div className={Styles.tradeAndRegisterRightSection}>
          <span>Download the App</span>
          <h2>
            What are you waiting for? <br />
            Dive in now & experience <br />
            the future of finance! <br />
          </h2>
          <p>
            Fast trading anywhere! <br />
            Access anytime. <br />
            Live without restrictions.
          </p>

          {!isLoggedIn && (
            <div className={Styles.tradeAndRegisterButtonsContainer}>
              <Button
                variant="dark"
                style={{
                  backgroundColor: "var(--secondary-color)"
                }}
                type="link"
                text="Sign In"
                linkTo="/login"
              />
              <Button type="link" linkTo="/signup" text="Register" />
            </div>
          )}
        </div>
      </div>

      {/* Our Best Services */}
      <div
        className={`${Styles.topMarketContainer} ${Styles.servicesSectionContainer}`}
        style={{ margin: "60px 9%" }}
      >
        <SectionHeader
          sectionTitle="Benefits"
          title="Jaw-dropping features"
          description="Don't settle for mediocrity; step up your game bit by bit. You’ll want to brag about us."
        />

        {/* market cards container */}
        <div className={Styles.gridContainerServices}>
          <ServicesCard
            icon={theme === "dark" ? Safety : SafetyLight}
            title="Shields Up"
            text="Investors' peace of mind guaranteed with our secure and reliable digital investment protection."
          />
          <ServicesCard
            icon={theme === "dark" ? EasyDeposit : EasyDepositLight}
            title="Time is Money"
            text="Trade cryptocurrencies in real-time without delays on our platform where every second counts."
          />
          <ServicesCard
            icon={theme === "dark" ? LowCharges : BonusRefferalsLight}
            title="Encrypted Fort"
            text="Rest assured that your assets & transactions are secure with us, and your personal information is kept safe from prying eyes."
          />
          <ServicesCard
            icon={theme === "dark" ? BonusRefferals : LowChargesLight}
            title="Effortless Crypto Transactions"
            text="Easily deposit and withdraw funds with our user-friendly exchange design."
          />
          <ServicesCard
            icon={theme === "dark" ? FastTransaction : DeepEncryptionLight}
            title="Take it up a Gear"
            text="Get started with WenBit quickly and easily, whether you're an experienced trader or new to cryptocurrency. Take the fast lane with us."
          />
          <ServicesCard
            icon={theme === "dark" ? DeepEncryption : FastTransactionLight}
            title="Money Saver"
            text="Keep more of your profits with our industry-leading low transaction fees and reinvest in your portfolios."
          />
          <ServicesCard
            icon={theme === "dark" ? FastKYC : FastKYCLight}
            title="Karma is a Bit"
            text="Share your discovery with our Wen Community & get rewarded for helping it grow. Maximise your earnings by being an active member."
          />
          <ServicesCard
            icon={theme === "dark" ? Support_24_7 : Support_24_7Light}
            title="An Exchange that Doesn’t Sleep"
            text="Get 24/7 reliable support from our knowledgeable team for any trade-related questions or account assistance you may need."
          />
        </div>
      </div>

      {/* news letter container */}
      {/* ------------------ HIDING NEWSLETTER FOR NOW ------------------ */}
      {/* <div className={Styles.newsLetterContainer}>

            <div>
                <h2>Join our newsletter</h2>
                <p>Join now with Wenbit to get the latest news and start trading now</p>
            </div>

            <div>
                <input
                    placeholder='Enter your email'
                    type='email'
                />
                <Button
                    text='Subscribe'
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 32,
                        width: 'fit-content',
                        padding: '7px 15px'
                    }}
                />
            </div>

        </div> */}
      {/* -------------------------------------- */}
    </div>
  )
}

export default LandingPage
