import React from "react"

// redux
import { useSelector } from "react-redux"

// styles
import Styles from "./currencyInput.module.css"

// icons
import { BiChevronDown } from "react-icons/bi"

// packages
import { FormattedMessage } from "react-intl"

function CurrencyInput({
  title,
  secondaryTitle,
  coinName,
  currencyIcon,
  onChange = () => {},
  currencyImg,
  containerStyle,
  showDropdownArrow = false,
  onDropdownClick,
  ...rest
}) {

  const theme = useSelector(state => state.theme.value)

  return (
    <div 
      className={`${theme === 'light' && Styles.buySellInputContainerLight} ${Styles.buySellInputContainer}`} 
      style={{
        ...containerStyle
      }}
    >
      {/* left section */}
      <div className={Styles.buySellLeftSection}>
        {/* input field title */}
        <div className={Styles.buySellInputTitle}>{title}</div>

        {/* input group container */}
        {/* the default value below returns a worning, since there should not be value and default value. Consider solving it */}
        <div className={Styles.buySellInputGroupContainer}>
          <span>{currencyIcon}</span>
          <input
            style={{ width: "100%" }}
            defaultValue={0}
            onChange={onChange}
            {...rest}
          />
        </div>
      </div>

      {/* right section */}
      <div className={Styles.buySellRightSection}>
        {secondaryTitle && (
          <div className={Styles.secondaryTitle}>{secondaryTitle}</div>
        )}
        <div
          onClick={onDropdownClick}
          className={!onDropdownClick && Styles.notHoverable}
        >
          {currencyImg && <img src={currencyImg} />}
          {coinName && <span style={{ color: theme === 'light' && 'var(--gray-color)' }}>{coinName}</span>}
          {showDropdownArrow && <BiChevronDown color="#9295A6" />}
        </div>
      </div>
    </div>
  )
}

export default CurrencyInput
