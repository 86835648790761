import React from 'react'
import PropTypes from 'prop-types'
import Styles from './OrderbookRow.module.css'
import { formatNumber } from '../../../helpers/formatNumber'

const OrderbookRow = ({ isAsk, total = 0, order, width = total + '%' }) => {
  if (!order) {
    return null
  }
  
  const price = order[0]
  const quantity = order[1] || 0
  const totalPrice = formatNumber((Number(price) * Number(quantity)).toString().substring(0, 7)) || '0'
  const rowBackgroundColor = isAsk ? 'rgb(3, 166, 109, 0.2)' : 'rgb(220, 38, 38, 0.2)'
  const rowColor = isAsk ? 'var(--positive-color)' : 'var(--negative-color)'

  return (
    <div className={Styles.rowContainer}>
      <div style={{ color: rowColor }}>{price}</div>
      <div>{quantity}</div>
      <span className={Styles.rowBackground} style={{ width, backgroundColor: rowBackgroundColor }}></span>
      <div>{totalPrice}</div>
    </div>
  )
}

OrderbookRow.propTypes = {
  isAsk: PropTypes.bool,
  total: PropTypes.number,
  order: PropTypes.array,
  width: PropTypes.string,
}

export default OrderbookRow
