import React, { useState, forwardRef } from "react"

// MUI Imports
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Third Party Imports
import format from "date-fns/format"
import addDays from "date-fns/addDays"
import DatePicker from "react-datepicker"

// icons
import { ReactComponent as CalendarIcon } from "../../assets/icons/imported/calendar.svg"

// import 'react-datepicker/dist/react-datepicker.css'

function DateRangePicker({ handleDateChange = () => {} }) {
  // const popperPlacement = direction === 'ltr' ? 'bottom-start' : 'bottom-end'

  // ** States
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addDays(new Date(), 15))
  const [startDateRange, setStartDateRange] = useState(new Date())
  const [endDateRange, setEndDateRange] = useState(addDays(new Date(), 45))

  const handleOnChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleOnChangeRange = (dates) => {
    const [start, end] = dates
    setStartDateRange(start)
    setEndDateRange(end)
    handleDateChange(dates)
  }

  // const CustomInput = forwardRef((props, ref) => {
  //   const startDate = format(props.start, 'MM/dd/yyyy')
  //   // const endDate = props.end !== null ?  - {format(props.end, 'MM/dd/yyyy')} : null
  //   const value = `${startDate}${endDate !== null ? endDate : ''}`

  //   return <TextField inputRef={ref} label={props.label || ''} {...props} value={value} />
  // })
  // CustomInput.displayName = 'CustomInput'

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }} className="demo-space-x">
      <div
        style={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          backgroundColor: "black",
          padding: 4,
          borderRadius: 5
        }}
      >
        <CalendarIcon color="var(--gray-color)" />
        <DatePicker
          selectsRange
          monthsShown={2}
          endDate={endDateRange}
          selected={startDateRange}
          startDate={startDateRange}
          shouldCloseOnSelect={false}
          id="date-range-picker-months"
          onChange={handleOnChangeRange}
          // popperPlacement={popperPlacement}
          // customInput={<CustomInput label='Multiple Months' end={endDateRange} start={startDateRange} />}
        />
      </div>
    </Box>
  )
}

export default DateRangePicker
