import React, { useState, Fragment, useEffect, useRef } from "react"

// styles
import Styles from "./fileInput.module.css"

// MUI
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Button from "@mui/material/Button"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"

// icons
import { ReactComponent as UploadIcon } from "../../../assets/icons/imported/upload.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icons/imported/close.svg"

// dropzone
import { useDropzone } from "react-dropzone"

function FileInput({
  multiple = false,
  maxFiles = 1,
  maxSize = 2000000, // 50000 = 50kb
  accept = [], // ".png", ".jpg", ".jpeg"
  required = false,
  label,
  onFileChange = () => {},
  mainContainerStyle = {},
  dropzoneContainerStyle = {},
  error
}) {
  // ** States
  const [files, setFiles] = useState([])
  const [errorMessage, setErrorMessage] = useState("")

  // ** Refs
  const isFirstRender = useRef(true)

  // ** Dropzone
  const acceptObject = {} // Start accepted extensions with an empty object

  // Initialize "image/*" as an array if any image type is needed
  if (
    accept.includes(".png") ||
    accept.includes(".jpg") ||
    accept.includes(".jpeg")
  )
    acceptObject["image/*"] = []

  // Conditionally add "image/*" if the required image types are included
  if (accept.includes(".png"))
    acceptObject["image/*"] = [...acceptObject["image/*"], ".png"]
  if (accept.includes(".jpg"))
    acceptObject["image/*"] = [...acceptObject["image/*"], ".jpg"]
  if (accept.includes(".jpeg"))
    acceptObject["image/*"] = [...acceptObject["image/*"], ".jpeg"]

  // Conditionally add "application/pdf" if ".pdf" is included
  if (accept.includes(".pdf")) acceptObject["application/pdf"] = [".pdf"]

  // Conditionally add "application/vnd.openxmlformats-officedocument.wordprocessingml.document" if ".docx" is included
  if (accept.includes(".docx"))
    acceptObject[
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ] = [".docx"]

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    maxFiles,
    maxSize,
    accept: acceptObject,
    onDrop: (acceptedFiles) => {
      setErrorMessage("")
      setFiles(acceptedFiles.map((file) => Object.assign(file)))
    },
    onDropRejected: () => {
      setErrorMessage(
        `File size must be less than ${
          Math.round(maxSize / 100) / 10 > 1000
            ? `${(Math.round(maxSize / 100) / 10000).toFixed(1)} Mb`
            : `${(Math.round(maxSize / 100) / 10).toFixed(1)} Kb`
        }`
      )
      // display error toast
    }
  })

  const renderFilePreview = (file) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file)}
        />
      )
    } else {
      return <UploadIcon />
    }
  }

  const handleRemoveFile = (file) => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter((i) => i.name !== file.name)
    setFiles([...filtered])
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    onFileChange(files)
  }, [files])

  const fileList = files.map((file) => (
    <div className={Styles.listItemContainer} style={{ ...mainContainerStyle }}>
      <div className={Styles.listImageContainer}>{renderFilePreview(file)}</div>
      <div className={Styles.listDetailsContainer}>
        <span>{file.name}</span>
        <span>
          {Math.round(file.size / 100) / 10 > 1000
            ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
            : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
        </span>
      </div>
      <IconButton
        style={{ color: "var(--danger-color)" }}
        onClick={() => handleRemoveFile(file)}
      >
        <CloseIcon />
      </IconButton>
    </div>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <div className={Styles.mainContainer}>
      {label && (
        <span className={Styles.label}>
          {label} {required && <span className={Styles.required}>*</span>}
        </span>
      )}
      <div
        {...getRootProps({ className: "dropzone" })}
        className={Styles.uploadContainer}
        style={{
          ...dropzoneContainerStyle,
          borderColor: error && "var(--danger-color)"
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <div className={Styles.uploadIconContainer}>
            <UploadIcon style={{ color: "var(--primary-color)" }} />
          </div>
          <h4 className={Styles.uploadTitle}>
            Drop files here or click to upload.
          </h4>
          {accept && (
            <span className={Styles.allowedExtensionsText}>
              {accept?.length
                ? `${accept?.map((el) => `Allowed *${el}`)}.`
                : ""}{" "}
              {`Max size of ${
                Math.round(maxSize / 100) / 10 > 1000
                  ? `${(Math.round(maxSize / 100) / 10000).toFixed(1)} Mb`
                  : `${(Math.round(maxSize / 100) / 10).toFixed(1)} Kb`
              }`}
            </span>
          )}
          {maxFiles > 1 && (
            <span className={Styles.limitText}>
              Max {maxFiles} files and max size of{" "}
              {Math.round(maxSize / 100) / 10 > 1000
                ? `${(Math.round(maxSize / 100) / 10000).toFixed(1)} mb`
                : `${(Math.round(maxSize / 100) / 10).toFixed(1)} kb`}
            </span>
          )}
        </Box>
      </div>
      {(error || errorMessage) && (
        <span className={Styles.errorSpan}>
          {errorMessage ? errorMessage : error.message}
        </span>
      )}
      {files.length ? <Fragment>{fileList}</Fragment> : null}
    </div>
  )
}

export default FileInput
