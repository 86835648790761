import React from 'react'
import { Link, useHistory } from 'react-router-dom'

// components imports
import Button from '../../components/Button/Button'

// styles imports
import Styles from './notFound.module.css'

// photos
import notFoundIllustration from './assets/not-found.png'
import notFoundIllustrationLight from './assets/not-found-light.png'

// icons imports
import { AiFillHome } from 'react-icons/ai'

// redux
import { useSelector } from 'react-redux'

export default function notfound() {

  const history = useHistory()

  const theme = useSelector(state => state.theme.value)

  return (
    <div className={Styles.pageContainer}>

      {/* illustration */}
      <img
        src={theme === 'dark' ? notFoundIllustration : notFoundIllustrationLight}
      />
      <h2 style={{color: theme === 'light' && '#232530'}}>404</h2>
      <p style={{color: theme === 'light' && '#232530'}}>SORRY, THERE’S<br/>NOTHING HERE</p>

      <div>
        <Button
          type='link'
          linkTo='/trade'
          text='Go Home'
          style={{
            width: '90vw',
            maxWidth: '290px'
          }}
        />
      </div>

    </div>
  )
}
