import { createSlice } from '@reduxjs/toolkit'

export const orderBookSlice = createSlice({
  name: 'orderBook',
  initialState: {
    value: [],
    load: true
  },
  reducers: {
    changeOrderBook: (state, payload) => {
        state.value = payload.payload
    },
    clearOrderBook: (state, payload) => {
      state.value = []
    },
    addOrderBook: (state, payload) => {
      state.value.unshift(payload.payload)
    },
    setOrderBookLoad: (state, payload) => {
      state.load = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeOrderBook, clearOrderBook, addOrderBook, setOrderBookLoad } = orderBookSlice.actions

export default orderBookSlice.reducer