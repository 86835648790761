import React, { useEffect } from "react"
import { Route, useLocation } from "react-router-dom"
import nprogress from "nprogress"
import "nprogress/nprogress.css"

nprogress.configure({ showSpinner: false })

const CustomRoute = ({ children, ...rest }) => {
  const location = useLocation()

  useEffect(() => {
    nprogress.done()
    return () => {
      nprogress.start()
    }
  }, [location])

  return <Route {...rest}>{children}</Route>
}

export default CustomRoute
