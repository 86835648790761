import React from "react"

// react router
import { useHistory } from "react-router-dom"

// styles
import Styles from "./dangerModal.module.css"

// icons
import { ReactComponent as DangerIcon } from "../../../assets/icons/imported/declined-rounded.svg"

// redux
import { useSelector } from "react-redux"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

function DangerModal({ open = false, handleClose }) {
  const accentColor = "var(--danger-color)"
  const modalData = useSelector((state) => state.dangerModalState.data)

  return (
    <>
      <WenbitModal
        open={open}
        showCloseButton={true}
        onClose={handleClose}
        showLineHeader={true}
        lineHeaderColor={accentColor}
        render={() => (
          <div className={Styles.bodyContainer}>
            <DangerIcon height={55} width={55} />
            <span className={Styles.title}>{modalData.title}</span>
            <span className={Styles.description}>{modalData.subtitle}</span>

            <div className={Styles.buttonsContainer}>
              <Button
                text="Cancel"
                variant="dark"
                style={{
                  width: "fit-content",
                  color: accentColor
                }}
                onClick={() => {
                  handleClose()
                }}
              />
              {modalData?.showActionButton && (
                <Button
                  linkTo={modalData.href ?? "#"}
                  type={modalData.href && "link"}
                  text={modalData?.actionButtonText ?? "Confirm"}
                  style={{
                    width: "fit-content",
                    backgroundColor: accentColor
                  }}
                  onClick={() => {
                    modalData.callback
                      ? () => {
                          modalData.callback()
                        }
                      : null
                    handleClose()
                  }}
                />
              )}
            </div>
          </div>
        )}
      />
    </>
  )
}

export default DangerModal
