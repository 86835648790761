import axios from "axios"

const urlHelper = "/api/v2/"
const blogsUrlHelper = `/blogs/api/` // "http://127.0.0.1:8000/api/"

const stageUrl = "https://exchange-stage.wenbit.com/api/v2/"
const serverUrl = "https://exchange.wenbit.com/api/v2/"
const prodUrl = `https://${window.location.host}/api/v2/`
const blogsProdUrl = `https://exchange-stage.wenbit.com/blogs/api/`

export const API = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? prodUrl : urlHelper
})

export const BINANCE_API = axios.create({
  baseURL: `https://api.binance.com/api/v3/`
})

export const Blogs_API = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? blogsProdUrl : blogsUrlHelper
})

export const Axios = { API }
