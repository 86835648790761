import React, { useState, useEffect } from "react"

// styles
import Styles from "./selectCurrencyModal.module.css"

// icons
import { ReactComponent as InfoIcon } from "../../../assets/icons/imported/info.svg"

// components
import Button from "../../../components/Button/Button"
import Search from "../../../components/Search/Search"
import WenbitModal from "../../../components/Modal/Modal"
import CoinTag from "../../../components/CoinTag/CoinTag"
import ShimmerLine from "../../../components/shimmer-components/ShimmerLine"
import ShimmerCircle from "../../../components/shimmer-components/ShimmerCircle"

// APIs

import { _getCurrencies, _getMarketsList } from "./redux/action"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setSelectCurrencyModalOpen } from "./redux/selectCurrencyModalSlice"
import { randomizeShimmerWidth } from "../../../components/shimmer-components/helpers"

function SelectCurrencyModal() {
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.theme.value)

  const [currencies, setCurrencies] = useState([])
  const [filteredCurrencies, setFilteredCurrencies] = useState(currencies)

  const [markets, setMarkets] = useState([])
  const [filteredMarkets, setFilteredMarkets] = useState(markets)

  const [loading, setLoading] = useState(false)

  const modalOpen = useSelector((state) => state.selectCurrencyModalState.open)
  const modalData = useSelector((state) => state.selectCurrencyModalState.data)
  const [selectedTab, setSelectedTab] = useState(modalData.type)
  const marketArray = useSelector((state) => state.marketList.value)

  const handleClose = () => {
    dispatch(setSelectCurrencyModalOpen(false))
  }

  const fetchCurrencies = () => {
    setLoading(true)
    setCurrencies([])
    setFilteredCurrencies([])
    _getCurrencies(
      (data) => {
        setLoading(false)
        setCurrencies(data)
        // Filter the currencies based on the market array
        const filteredCurrencies = data.filter((currency) => {
          const currencyWithUSD = `${currency.id}usd`
          const foundMarket = marketArray.find(
            (el) => el.id === currencyWithUSD
          )
          return (
            foundMarket && foundMarket.last !== 0 && foundMarket.last !== "0.0"
          )
        })

        setFilteredCurrencies(filteredCurrencies)
      },
      {
        type: modalData.showTabs
          ? selectedTab
          : modalData.type === "all" ||
            modalData.type === null ||
            modalData.type === undefined
          ? null
          : modalData.type
      },
      (error) => {
        setLoading(false)
      }
    )
  }

  const fetchMarkets = () => {
    setLoading(true)
    _getMarketsList(
      (data) => {
        setLoading(false)
        setFilteredMarkets(data)
        setMarkets(data)
      },
      (err) => setLoading(false)
    )
  }

  useEffect(() => {
    if (modalData.showMarketPairs) {
      fetchMarkets()
    } else {
      fetchCurrencies()
    }
  }, [selectedTab, modalOpen])

  const handleSearchChange = (e) => {
    if (modalData.showMarketPairs) {
      const tempArray = markets.filter(
        (el) =>
          el?.base_unit
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          el?.id?.toLowerCase().includes(e.target.value?.toLowerCase())
      )
      // console.log(tempArray)
      setFilteredMarkets(tempArray)
    } else {
      const tempArray = currencies.filter(
        (el) =>
          el?.name?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          el?.id?.toLowerCase().includes(e.target.value?.toLowerCase())
      )
      setFilteredCurrencies(tempArray)
    }
  }

  const handleRowClick = (currency) => {
    modalData.callback(currency)
    handleClose()
  }

  return (
    <WenbitModal
      open={modalOpen}
      onClose={handleClose}
      title={modalData.title}
      showCloseButton={true}
      disableBackdropClick={modalData?.disableBackdropClick}
      render={() => (
        <div>
          {modalData.showTabs && (
            <div className={Styles.tabsContainer}>
              <Button
                text="Crypto"
                type="tab"
                style={{
                  flex: 1,
                  fontSize: 16
                }}
                onClick={() => {
                  if (loading) return
                  setSelectedTab("coin")
                }}
                isTabSelected={selectedTab === "coin"}
              />
              <Button
                text="Fiat"
                type="tab"
                style={{
                  flex: 1,
                  fontSize: 16
                }}
                onClick={() => {
                  if (loading) return
                  setSelectedTab("fiat")
                }}
                isTabSelected={selectedTab === "fiat"}
              />
            </div>
          )}

          <Search
            placeholder={modalData.searchPlaceholder ?? "Search coin"}
            containerStyle={{ width: "100%" }}
            onChange={handleSearchChange}
          />

          <div className={`${Styles.rowsContainer}`}>
            {loading ? (
              new Array(7).fill(0).map(() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 7,
                    gap: 15,
                    margin: "0px 0"
                  }}
                >
                  <ShimmerCircle size={35} />
                  <ShimmerLine height={35} width={randomizeShimmerWidth()} />
                </div>
              ))
            ) : modalData.showMarketPairs ? (
              filteredMarkets?.length ? (
                filteredMarkets?.map((market) => {
                  // if (modalData?.currenciesToHide?.includes(currency.id)) {
                  //     return null
                  // }

                  return (
                    <div
                      key={market?.id}
                      className={`${Styles.marketRow} ${
                        theme === "light" && Styles.lightRow
                      }`}
                      onClick={() => {
                        handleRowClick(market)
                      }}
                    >
                      <img src={market?.base_url} />
                      <span>{market?.name.toUpperCase()}</span>
                      <CoinTag tag={market?.base_coin_name} />
                    </div>
                  )
                })
              ) : (
                <div className={Styles.notFound}>
                  <InfoIcon width={12} />
                  Coin not found
                </div>
              )
            ) : filteredCurrencies?.length ? (
              filteredCurrencies?.map((currency) => {
                if (modalData?.currenciesToHide?.includes(currency.id)) {
                  return null
                }
                return (
                  <div
                    className={`${Styles.row} ${
                      theme === "light" && Styles.lightRow
                    }`}
                    key={currency?.id}
                    onClick={() => {
                      handleRowClick(currency)
                    }}
                  >
                    {currency.icon_url && <img src={currency?.icon_url} />}
                    <span>{currency?.name}</span>
                    <CoinTag tag={currency?.id?.toUpperCase()} />
                  </div>
                )
              })
            ) : (
              <div className={Styles.notFound}>
                <InfoIcon width={12} />
                Coin not found
              </div>
            )}
          </div>
        </div>
      )}
    />
  )
}

export default SelectCurrencyModal
