const colors = {
  light: {
    chart: {
      primary: "white",
      secondary: "white",
      grid: "#93a1bd",
      tertiary: "#919EBA",
      up: "var(--primary-color)",
      down: "#F1432F"
    }
  },
  dark: {
    chart: {
      primary: "#131722",
      //'#131722',
      secondary: "#131722",
      //"#131722",
      grid: "#354360",
      tertiary: "#919EBA",
      up: "#23ac50",
      down: "#F1432F"
    }
  }
}

export const customWidgetOptions = (colorTheme) => {
  if (colorTheme === "light") {
    return {
      toolbar_bg: "#f4f9fc",
      loading_screen: {
        backgroundColor: "#f4f9fc"
      },
      overrides: {
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#f4f9fc",
        ["mainSeriesProperties.candleStyle.upColor"]: "#03A66D",
        ["mainSeriesProperties.candleStyle.downColor"]: "#e26363",
        ["mainSeriesProperties.candleStyle.borderUpColor"]: "#03A66D",
        ["mainSeriesProperties.candleStyle.borderDownColor"]: "#e26363",
        ["mainSeriesProperties.candleStyle.wickUpColor"]: "#03A66D",
        ["mainSeriesProperties.candleStyle.wickDownColor"]: "#e26363",
        ["mainSeriesProperties.areaStyle.color1"]: "rgb(0, 230, 230)",
        "mainSeriesProperties.areaStyle.linecolor": "rgb(0, 230, 230)"
      },
      theme: "Light"
    }
  }

  return {
    toolbar_bg: "#00191e",
    loading_screen: {
      backgroundColor: "#00191e"
    },
    overrides: {
      "paneProperties.backgroundType": "solid",
      "paneProperties.background": "#00191e",
      ["mainSeriesProperties.candleStyle.upColor"]: "#03A66D",
      ["mainSeriesProperties.candleStyle.downColor"]: "#e26363",
      ["mainSeriesProperties.candleStyle.borderUpColor"]: "#03A66D",
      ["mainSeriesProperties.candleStyle.borderDownColor"]: "#e26363",
      ["mainSeriesProperties.candleStyle.wickUpColor"]: "#03A66D",
      ["mainSeriesProperties.candleStyle.wickDownColor"]: "#e26363",
      ["mainSeriesProperties.areaStyle.color1"]: "rgb(0, 230, 230)",
      "mainSeriesProperties.areaStyle.linecolor": "rgba(0, 230, 230, 0.5)"
    },
    theme: "Dark"
  }
}
