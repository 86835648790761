import React, { useState, useEffect } from "react"

// react router
import { useHistory, useLocation } from "react-router-dom"

// styles
import Styles from "./orderHistoryTab.module.css"

// icons
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/imported/calendar.svg"
import { ReactComponent as FilterIcon } from "../../../../assets/icons/imported/filter.svg"
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/imported/down-arrow.svg"
import { ReactComponent as BuyArrowSquare } from "../../../../assets/icons/imported/buy-arrow-square.svg"
import { ReactComponent as SellArrowSquare } from "../../../../assets/icons/imported/sell-arrow-square.svg"

// components
import NavLink from "../../../../components/Header/NavLink"
import Search from "../../../../components/Search/Search"
import CoinTag from "../../../../components/CoinTag/CoinTag"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"

// redux
import { _getOrders } from "../../redux/actions"
import { addOrders } from "../../../exchange/redux/slices/openOrdersSlice"
import { _cancelOrder } from '../../../exchange/redux/actions'
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
// helpers
import { isoStringToDate } from "../../../helpers/localeDate"
import { useSelector, useDispatch } from "react-redux"
import Button from "../../../../components/Button/Button"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"
import getOrderStateColor from "../../../../utility/orders/getOrderStateColor"
import { FormattedMessage, injectIntl } from "react-intl"
import { IconButton, capitalize } from "@mui/material"

function OrderHistoryTab() {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const markets = useSelector((state) => state.marketList.value)
  // const openOrders = useSelector((state) => state.openOrders.value)

  const [selectedTab, setSelectedTab] = useState("open")
  document.title = "Wenbit: Order History"

  // table states
  const [orders, setOrders] = useState("")
  const [page, setPage] = useState("")
  const [pageLimit, setPageLimit] = useState("")
  const [marketRows, setMarketRows] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 677)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 677)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (location.pathname === "/account/orders/open") {
      setSelectedTab("open")
    }
    if (location.pathname === "/account/orders/history") {
      setSelectedTab("history")
    }
  }, [location])

  const fetchOrder = () => {
    setTableLoading(true)
    _getOrders(
      {
        state:
          selectedTab === "open"
            ? "&state=wait"
            : "&state[]=done&state[]=reject&state[]=cancel"
      },
      (data) => {
        setTableLoading(false)
        setMarketRows(data)
        setOrders(data)
      },
      (err) => setTableLoading(false)
    )
  }

  const handleCancelOrder = (id) => {
    _cancelOrder(
      id,
      () => {
        dispatch(
          setSnackbarData({
            alertMessage: "This order will be canceled shortly",
            callback: () => {},
            severity: "success",
            position: "topRight"
          })
        )
        dispatch(setSnackbarOpen(true))
        fetchOrder()
      },
      (error) => {
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    fetchOrder()
  }, [selectedTab])

  const ordersColumns = isMobileView
    ? [
        {
          minWidth: 180,
          flex: 1,
          field: "",
          renderCell: (data) => (
            <MobileRowDatagrid
              renderRowHeader={() => {
                const orderMarketPair = markets.find(
                  (el) => el.id === data.row.market
                )
                return (
                  <CoinDisplay
                    coinImgURL={orderMarketPair.icon_url}
                    coinName={orderMarketPair.base_coin_name}
                    coinTag={orderMarketPair.base_unit?.toUpperCase()}
                  />
                )
              }}
              rows={[
                {
                  title: "Date",
                  value: (
                    <div>
                      <span>
                        {isoStringToDate(data.row.created_at).date}{" "}
                        {isoStringToDate(data.row.created_at).time}
                      </span>
                    </div>
                  )
                },
                {
                  title: "Side",
                  value: (
                    <span className={Styles.sideColumn}>
                      {/* {
                        data.row.side === 'sell' ? (
                          <SellArrowSquare height={24} />
                        ) : (
                          <BuyArrowSquare height={24} />
                        )
                      } */}

                      <div>
                        <div>
                          {data.row.side.charAt(0).toUpperCase() +
                            data.row.side.slice(1)}
                        </div>
                      </div>
                    </span>
                  )
                },
                {
                  title: "Amount",
                  value: <div>{data.row.origin_volume}</div>
                },
                {
                  title: "Status",
                  value: (
                    <span className={Styles.actionColumn}>
                      <span
                        style={{
                          color: getOrderStateColor(data.row.state)
                        }}
                      >
                        {data.row.state}
                      </span>
                    </span>
                  )
                }
              ]}
            />
          )
        }
      ]
    : [
        {
          field: "pair",
          headerName: "Pair",
          minWidth: 160,
          maxWidth: 190,
          editable: false,
          // flex: 1,
          renderCell: (data) => {
            const marketPair = markets.find((el) => el.id === data.row.market)
            return (
              <span className={Styles.pairColumn}>
                <img src={marketPair?.icon_url} />
                {marketPair?.coin_name}
                <CoinTag
                  tag={`${markets
                    .find((el) => el.id === data.row.market)
                    ?.base_unit.toUpperCase()}/${markets
                    .find((el) => el.id === data.row.market)
                    ?.quote_unit.toUpperCase()}`}
                  style={{ width: "fit-content" }}
                  size={11}
                />
              </span>
            )
          }
        },
        // {
        //   field: 'date',
        //   headerName: 'Date',
        //   minWidth: 150,
        //   renderCell: (data) => (
        //     <div className={Styles.orderHistoryRow}>
        //       <span>{isoStringToDate(data.row.created_at).date} {isoStringToDate(data.row.created_at).time}</span>
        //     </div>
        //   )
        // },
        {
          field: "side",
          headerName: "Side",
          minWidth: 190,
          maxWidth: 200,
          editable: false,
          renderCell: (data) => (
            <span className={Styles.sideColumn}>
              {data.row.side === "sell" ? (
                <SellArrowSquare height={24} />
              ) : (
                <BuyArrowSquare height={24} />
              )}

              <div>
                <span>
                  {data.row.side.charAt(0).toUpperCase() +
                    data.row.side.slice(1)}
                </span>
                <span>
                  {isoStringToDate(data.row.created_at).date}{" "}
                  {isoStringToDate(data.row.created_at).time}
                </span>
              </div>
            </span>
          )
          // flex: 1
        },
        {
          field: "price",
          headerName: "Price",
          minWidth: 130,
          maxWidth: 150,
          editable: false,
          // flex: 1,
          renderCell: (data) => (
            <>
              {<div className={Styles.orderHistoryRow}>{data.row.price}</div>}
            </>
          )
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 130,
          maxWidth: 150,
          editable: false,
          // flex: 1,
          renderCell: (data) => (
            <>
              {
                <div className={Styles.orderHistoryRow}>
                  {data.row.origin_volume}
                </div>
              }
            </>
          )
        },
        {
          field: "status",
          headerName: "Status",
          sortable: false,
          minWidth: 110,
          maxWidth: 140,
          editable: false,
          // flex: 1,
          renderCell: (data) => (
            <span className={Styles.actionColumn}>
              <span
                style={{
                  color: getOrderStateColor(data.row.state)
                }}
              >
                {data.row.state}
              </span>
            </span>
          )
        },
        selectedTab !== "open"
        ? {} // If selectedTab is 'open', don't include the "Actions" column (empty object)
        : {
          field: "actions",
          headerName: "Actions",
          sortable: false,
          minWidth: 110,
          maxWidth: 140,
          editable: false,
          renderCell: (data) => (
            <span>
                <Button
                  text="Cancel"
                  variant="link"
                  // icon={<TrashIcon width={14} style={{marginLeft: 10}}/>}
                  style={{
                    padding: 0,
                    marginTop: -10
                  }}
                  onClick={() => handleCancelOrder(data.row.id)}
                />
              
            </span> 
          )
        }
      ]

  return (
    <div className={Styles.ordersCard}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>{selectedTab === "open" ? "Open Orders" : "Order History"}</h5>
          <span>You asked for it; you got it. View all your orders here.</span>
        </div>

        <Search placeholder="Search by coin" />
      </div>

      {/* tabs container */}
      <div className={Styles.tabsContainer}>
        <Button
          text="Open Orders"
          type="tab"
          onClick={() => {
            if (tableLoading) return
            history.replace("/account/orders/open")
          }}
          isTabSelected={location.pathname === "/account/orders/open"}
        />
        <Button
          text="Order History"
          type="tab"
          onClick={() => {
            if (tableLoading) return
            history.replace("/account/orders/history")
          }}
          isTabSelected={location.pathname === "/account/orders/history"}
          style={{ marginRight: "auto" }}
        />

        {/* <div className={Styles.filterContainer}>
            <CalendarIcon width={13} />
            Month
            <DownArrowIcon />
          </div>
          <div className={Styles.filterContainer}>
            <FilterIcon width={13} />
            Filter
            <DownArrowIcon />
          </div> */}
      </div>

      {/* rows container */}
      <div>
        <MUIDatagrid
          disableSorting
          page={page}
          pageLimit={pageLimit}
          rows={selectedTab === "open" ? orders : marketRows}
          hideFooter
          hideHeader={isMobileView}
          totalRowsCount={totalRows}
          loading={tableLoading}
          getRowId={(row) => row.id}
          onPageChange={handlePageChange}
          columns={ordersColumns}
          rowHeight={isMobileView && 170}
        />
      </div>
    </div>
  )
}

export default injectIntl(OrderHistoryTab)