import React from "react"

// styles
import Styles from "./coinDisplay.module.css"
import CoinTag from "../CoinTag/CoinTag"

function CoinDisplay({ coinName, coinImgURL, coinTag, fontSize = 12.5 }) {
  return (
    <div className={Styles.container}>
      {coinImgURL && <img src={coinImgURL} className={Styles.coinImg} />}
      {coinName && (
        <span className={Styles.coinName} style={{ fontSize }}>
          {coinName}
        </span>
      )}
      {coinTag && <CoinTag tag={coinTag.toUpperCase()} />}
    </div>
  )
}

export default CoinDisplay
