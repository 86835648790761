import React, { useState, useEffect } from "react"

// styles
import Styles from "./confirmConvertModal.module.css"

// icons
import { ReactComponent as BackArrowIcon } from "../../../assets/icons/imported/back-arrow.svg"
import { ReactComponent as InfoIcon } from "../../../assets/icons/imported/info.svg"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"
import Alert from "../../../components/Alert/Alert"
import Tooltip from "../../../components/Tooltip/Tooltip"
import { toast } from "react-toastify"
import { FormattedMessage, injectIntl } from "react-intl"
import SuccessConvertModal from "./SuccessConvertModal"

// helpers
import { estimateUnitValue } from "../../helpers/estimateValueBase"

// redux
import { setConfirmConvertModalData } from "./redux/confirmConvertModalSlice"
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../snackbar/redux/snackbarSlice"
import { _previewConversion, _convert } from "../../convert/redux/actions"
import {
  setSuccessConvertModalOpen,
  setSuccessConvertModalData
} from "./redux/successConvertSlice"
import { _getBalances } from "../../../redux/actions"
import { setBalances } from "../../../redux/slices/balancesSlice"

function ConfirmConvertModal({
  open,
  handleClose,
  fromCurrency,
  toCurrency,
  toValue,
  fromValue,
  currencies,
  markets,
  marketTickers
}) {
  const dispatch = useDispatch()

  const timer = 5
  const [timerStarted, setTimerStarted] = useState(true)
  const [countdown, setCountDown] = useState(timer)
  const [clicked, setClicked] = useState(false)
  const modalData = useSelector((state) => state.confirmConvertModalState.data)
  const theme = useSelector((state) => state.theme.value)
  const [refreshBalance, setRefreshBalance] = useState(false)

  const handleConvert = () => {
    // console.log(modalData)
    setClicked(true)
    _convert(
      modalData.id,
      (data) => {
        setRefreshBalance(!refreshBalance)
        setCountDown(timer)
        handleClose()
        dispatch(setSuccessConvertModalOpen(true))
        dispatch(setSuccessConvertModalData(data))
      },
      (error) => {
        setRefreshBalance(!refreshBalance)
        setCountDown(timer)
        // handleClose()
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    _getBalances(
      (res) => {
        dispatch(setBalances(res))
      },
      () => {}
    )
  }, [refreshBalance])

  const handleRefresh = () => {
    setClicked(true)
    setRefreshBalance(!refreshBalance)
    _previewConversion(
      {
        from: fromCurrency.id,
        to: toCurrency.id,
        volume: fromValue
      },
      (data) => {
        setCountDown(timer)
        setTimerStarted(true)
        dispatch(setConfirmConvertModalData(data))
        setClicked(false)
        // console.log(data)
      },
      (error) => {
        setCountDown(timer)
        // handleClose()
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    if (countdown > 0) {
      if (timerStarted) {
        const timerId = setTimeout(() => {
          setCountDown(countdown - 1)
        }, 1000)
        return () => clearTimeout(timerId)
      }
    } else if (timerStarted && countdown === 0) {
      //handleConvert()
      setTimerStarted(false)
    }
  }, [timerStarted, countdown])

  return (
    <WenbitModal
      open={open}
      onClose={(e) => {
        handleClose()
        setTimerStarted(false)
        setCountDown(timer)
      }}
      title="Confirm"
      showCloseButton={true}
      render={() => (
        <div>
          <div className={Styles.convertRow}>
            <div>
              <img src={fromCurrency?.icon_url} />
              <span>{fromValue}</span>
            </div>
            <span>{fromCurrency?.name}</span>
          </div>

          <BackArrowIcon
            color="var(--primary-color)"
            style={{
              transform: "rotateZ(-90deg) translateY(7.5px)"
            }}
            height={12.5}
            width={12.5}
          />

          <div className={Styles.convertRow}>
            <div>
              <img src={toCurrency?.icon_url} />
              <span>{parseFloat(modalData.total) === parseFloat(fromValue) ? modalData.amount : modalData.total }</span>
            </div>
            <span>{toCurrency?.name}</span>
          </div>

          {/* convert information */}
          <div
            className={Styles.convertInfoContainer}
            style={{
              backgroundColor: theme === "light" && "var(--secondary-color)"
            }}
          >
            <div className={Styles.infoRowContainer}>
              <span>
                Rate
                <div className={Styles.infoIconDiv}>
                  <Tooltip
                    text="Heads up! The rate is subject to market conditions and may differ from the spot rate. The price can always change based on the latest data."
                    color="var(--primary-color)"
                  />
                </div>
              </span>
              <span>
                {fromValue}{" "}
                {fromCurrency.id ? fromCurrency?.id.toUpperCase() : ""} ={" "}
                {parseFloat(modalData.total) === parseFloat(fromValue) ? modalData.amount + ' ' : modalData.total + ' ' }
                {toCurrency.id ? toCurrency.id.toUpperCase() : ""}
              </span>
            </div>

            <div className={Styles.infoRowContainer}>
              <span>Inverse Rate</span>
              <span>
              {parseFloat(modalData.total) === parseFloat(fromValue) ? modalData.amount + ' ' : modalData.total + ' ' }
                {toCurrency.id ? toCurrency?.id.toUpperCase() : ""} ={" "}
                {fromValue}{" "}
                {fromCurrency.id ? fromCurrency.id.toUpperCase() : ""}
              </span>
            </div>

            <div className={Styles.infoRowContainer}>
              <span>Payment Method</span>
              <span>Spot wallet</span>
            </div>

            <div className={Styles.infoRowContainer}>
              <span>
                Transaction Fees
                <div className={Styles.infoIconDiv}>
                  <Tooltip
                    text="The rate here is the final rate, with no extra transaction fees. No surprises or hidden costs here!"
                    color="var(--primary-color)"
                  />
                </div>
              </span>
              <span style={{ color: "var(--positive-color)" }}>No Fees</span>
            </div>

            <div className={Styles.infoRowContainer}>
              <span>You will receive</span>
              <span>
                {modalData.total} {toCurrency.id}
              </span>
            </div>
          </div>

          {/* QUOTE EXPIRED */}
          {!timerStarted && !clicked && (
            <Alert
              alertText="Quote expired. Please request a new quote."
              showButton={true}
              buttonText="Refresh"
              icon={
                <Tooltip
                  color="var(--primary-color)"
                  text="Market prices might have changed! Refresh to get an accurate quote."
                />
              }
              buttonStyle={{
                padding: "0px 9px"
              }}
              containerStyle={{ margin: "14px 0", borderRadius: 10 }}
              buttonOnClick={handleRefresh}
            />
          )}

          <Button
            text={timerStarted ? `Convert ${countdown}s` : "Convert"}
            disabled={!timerStarted}
            onClick={(e) => {
              handleConvert()
              setTimerStarted(false)
            }}
          />
        </div>
      )}
    />
  )
}

export default injectIntl(ConfirmConvertModal)
