// ** Store Import
import { store } from "../../../../redux/store"

// ** Action Imports
import {
  setVerificationCodesInputModalData,
  setVerificationCodesInputModalOpen
} from "./redux/verificationCodesInputModalSlice"

export const showVerificationCodesInputModal = ({
  onOpen = () => {},
  onClose = () => {},
  email = false,
  sms = false,
  otp = false,
  showEmailResendCode = true,
  showSMSResendCode = true,
  handleEmailResendCode = () => {},
  handleSMSResendCode = () => {},
  callback = () => {},
  errCallback = () => {}
}) => {
  store.dispatch(
    setVerificationCodesInputModalData({
      onOpen,
      onClose,
      email,
      sms,
      otp,
      showEmailResendCode,
      showSMSResendCode,
      handleEmailResendCode,
      handleSMSResendCode,
      callback,
      errCallback
    })
  )
  store.dispatch(setVerificationCodesInputModalOpen(true))
}

export const hideVerificationCodesInputModal = () => {
  store.dispatch(setVerificationCodesInputModalOpen(false))
  store.dispatch(
    setVerificationCodesInputModalData({
      onOpen: () => {},
      onClose: () => {},
      email: false,
      sms: false,
      otp: false,
      showEmailResendCode: true,
      showSMSResendCode: true,
      handleEmailResendCode: () => {},
      handleSMSResendCode: () => {},
      callback: () => {},
      errCallback: () => {}
    })
  )
}
