import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

// styles
import Styles from "./Disable2FAModal.module.css"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { _getUserInfo } from "../../../../redux/actions"
import { setLoggedIn } from "../../../../redux/slices/loggedIn"
import { setUser, logoutUser } from "../../../user/redux/slices/userDataSlice"
import { clearBalances } from "../../../../redux/slices/balancesSlice"

// components
import Input from "../../../../components/Input/Input"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"

// utils
import { FormattedMessage, injectIntl } from "react-intl"
import { closeDisable2FAModal } from "./helpers"
import { hideEmail } from "../../../helpers/HideEmail"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { _sendOtp, _disable2FA } from "./redux/actions"
import {
  showErrorSnackbar,
  showSuccessSnackbar
} from "../../../snackbar/helpers"

const inputStyle = {
  marginBottom: 22
}

// schema
const disable2FASchema = Yup.object().shape({
  authenticatorCode: Yup.string()
    .required("Authenticator code is required")
    .matches(/^\d{6}$/, "Authenticator code must be 6 digits")
    .test(
      "is-number",
      "Authenticator code must contain only digits",
      (value) => {
        return /^\d+$/.test(value)
      }
    ),
  emailCode: Yup.string()
    .required("Email code is required")
    .matches(/^\d{5}$/, "Email code must be 5 digits")
    .test("is-number", "Email code must contain only digits", (value) => {
      return /^\d+$/.test(value)
    })
})

function Disable2FAModal() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(disable2FASchema),
    mode: "onSubmit"
  })
  const dispatch = useDispatch()
  const modalOpen = useSelector((state) => state.disable2FAModalState.open)
  const modalData = useSelector((state) => state.disable2FAModalState.data)
  const userData = useSelector((state) => state.userData.value)

  const [loading, setLoading] = useState(false)
  const [emailCodeTimer, setEmailCodeTimer] = useState(null)

  const handleGetEmailCode = () => {
    _sendOtp(
      () => {
        setEmailCodeTimer(180) // 3 minutes in seconds
        showSuccessSnackbar({ alertMessage: "OTP code is sent to your email" })
      },
      (error) => {
        showErrorSnackbar({
          alertMessage: error.errors[0].length ? (
            <FormattedMessage id={error.errors[0]} />
          ) : (
            "Unknown Error"
          )
        })
      }
    )
  }

  const onSubmit = (formData) => {
    setLoading(true)
    _disable2FA(
      {
        email_otp: formData?.emailCode,
        code: formData?.authenticatorCode
      },
      () => {
        _getUserInfo(
          (data) => {
            setLoading(false)
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "Two Factor Authentication Disabled",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            closeDisable2FAModal()
            dispatch(setLoggedIn(true))
            dispatch(setUser(data))
            setLoading(false)
            if (data.errors) {
              dispatch(logoutUser())
              dispatch(setLoggedIn(false))
              dispatch(clearBalances())
              setLoading(false)
            }
          },
          (error) => {
            closeDisable2FAModal()
            setLoading(false)
            if (error?.errors) {
              dispatch(logoutUser())
              dispatch(clearBalances())
              dispatch(setLoggedIn(false))
            }
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => {},
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    if (modalOpen) {
      reset()
      setEmailCodeTimer(null)
    }
  }, [modalOpen])

  useEffect(() => {
    let interval = null
    if (emailCodeTimer !== null && emailCodeTimer > 0) {
      interval = setInterval(() => {
        setEmailCodeTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (emailCodeTimer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [emailCodeTimer])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        onClose={closeDisable2FAModal}
        title={modalData?.title}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            inputLabel="Authenticator Code"
            indicatorLabel="Enter the 6-digit code from Authenticator App"
            showBorder
            containerStyle={{ ...inputStyle }}
            {...register("authenticatorCode")}
            error={errors.authenticatorCode}
            maxLength={6}
          />

          <Input
            inputLabel="Email Verification Code"
            indicatorLabel={
              userData?.email &&
              `Enter the 6-digit code sent to ${hideEmail(userData.email)}.com`
            }
            showBorder
            containerStyle={{ ...inputStyle }}
            icon={
              <Button
                variant="link"
                text={
                  emailCodeTimer !== null
                    ? `Resend in ${emailCodeTimer}s`
                    : "Get Code"
                }
                onClick={emailCodeTimer === null ? handleGetEmailCode : null}
                disabled={emailCodeTimer !== null}
              />
            }
            {...register("emailCode")}
            error={errors.emailCode}
            maxLength={5}
          />

          <Link to="/login">Security verification unavailable?</Link>

          <Button
            text="Submit"
            type="submit"
            style={{
              marginTop: 20
            }}
            isLoading={loading}
          />
        </form>
      </WenbitModal>
    </>
  )
}

export default injectIntl(Disable2FAModal)
