import React from 'react'
import { localeDate } from '../../../helpers/localeDate'
import { getTimezone } from '../../../helpers/timezone'
import {formatNumber} from '../../../helpers/formatNumber'
// styles
import Styles from './recentTradesRow.module.css'

function RecentTradesRow({
    isAsk,
    trade
}) {
  return (
    trade ?
    <div className={Styles.rowContainer}>
        <div style={{
            color: isAsk ? 'var(--positive-color)' : 'var(--negative-color)'
        }}>{formatNumber(trade?.price)}</div>
        <div>{formatNumber(trade?.amount)}</div>
        <div>{localeDate(trade?.date ?? trade["created_at"], getTimezone(), "HH:mm")}</div>
    </div>
    : null
  )
}

export default RecentTradesRow