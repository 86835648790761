import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const wenbitFAQs = [
  {
    summary: <span>Want to know how to register on WenBit?</span>,
    details: (
      <>
        <span>
          Ready to join Wenbit? Great! Head to our website and click the
          'Register' button to start. From there, you'll be taken to a
          registration page where you can enter your details. Be sure to fill in
          all the required fields and click 'Register Now' when finished.
        </span>
        <br />
        <span>
          To complete the KYC and registration process, you'll need to have a
          proof of identity and a proof of address handy. Once you have these
          documents ready, you can access the KYC verification page in one of
          two ways: either by clicking 'Verify now' in the pop-up that appears
          when you log in or by clicking on your profile in the top right
          corner, then select 'My Account' and finally 'KYC Verification'. From
          there, click 'Verify your KYC' to get started. It's that simple!"
        </span>
      </>
    )
  },
  {
    summary: <span>Ready to deposit some fiat? Here's how!</span>,
    details: (
      <>
        <span>
          After registering with WenBit, you will automatically receive three
          unique wallets, one for each supported currency: Euro, Pound Sterling,
          and American Dollar. Once you have your wallets, it's time to fund
          them so you can start investing in crypto.
        </span>
        <br />
        <span>
          To deposit funds, you have several options available. You can deposit
          using fiat currency, standard currency, via a local bank transfer,
          credit/debit card, or through Apple Pay or Google Pay. It's important
          to note that each deposit method may take different amounts of time to
          process, and payment options may vary based on location. Additionally,
          minimum deposit amounts may be required, so check the details for each
          deposit method.
        </span>
      </>
    )
  },
  {
    summary: (
      <span>Ready to buy some crypto? Let us show you how it's done.</span>
    ),
    details: (
      <>
        <span>
          We offer you two ways to buy crypto. First, if you have already
          deposited fiat currency into your wallet, you can easily convert that
          currency to the desired cryptocurrency. Select the currency you wish
          to convert from and the cryptocurrency you want to buy and confirm the
          transaction.
        </span>
        <br />
        <span>
          Alternatively, you can buy cryptocurrency directly using your
          credit/debit card. Please note that there may be limits on the amount
          you can buy, depending on your payment method and country of
          residence.
        </span>
      </>
    )
  },
  {
    summary: (
      <span>Want to convert your assets? Yes, you can - here's how.</span>
    ),
    details: (
      <span>
        You can access the convert feature by navigating to the "Market" page or
        clicking on the "Convert" tab at the top of your screen. On the "Market"
        page, you'll find the Convert panel on the right side of your screen.
        Fill in the required fields and click "Convert". A pop-up window will
        appear in the middle of the screen, displaying the details of your
        conversion and giving you a 5-second window to confirm your conversion.
        Once you click "Convert" to proceed with the conversion, another pop-up
        window will appear with the details of your conversion. It's that easy!
      </span>
    )
  },
  {
    summary: (
      <span>
        Got crypto on another exchange? Here's how to deposit it into WenBit.
      </span>
    ),
    details: (
      <span>
        WenBit allows you to transfer crypto from another exchange by depositing
        it into your WenBit account. To do this, you must ensure the correct
        deposit address and network selected to avoid losing your assets during
        the transfer process. Please remember that the transfer may not be
        instant, so be patient while waiting to complete the transaction.
      </span>
    )
  },
  {
    summary: (
      <span>
        Need to withdraw? We've got you covered - here's how to do it.
      </span>
    ),
    details: (
      <span>
        Withdrawing your assets from WenBit is a breeze! Navigate to the
        "Wallet" tab and click on "Withdraw". Select the asset you wish to
        withdraw, fill in the required details, and complete the 2FA security
        process. Once submitted, you will receive a notification confirming a
        successful withdrawal. At WenBit, we take your security seriously, so
        you can rest assured that your assets are safe with us.
      </span>
    )
  },
  {
    summary: (
      <span>The big question: Are there any fees? Let's break it down.</span>
    ),
    details: (
      <div>
        At WenBit, we believe in giving you control over your investment, so
        we're transparent about our fees. Our fees are listed below:
        <br />
        -- TABLE HERE --
      </div>
    )
  },
  {
    summary: (
      <span>Got a problem? No sweat - contact us, and we'll help you out.</span>
    ),
    details: (
      <span>
        Communication is key, and we take that seriously. That's why we've made
        it easy for you to contact us whenever you need to give us feedback,
        suggest a feature, or file a complaint. Head to our{" "}
        <Link to="/about-us">Talk to us</Link> or{" "}
        <Link to="/contact-support">Contact support</Link> pages and fill out
        the form. We promise to get back to you soon because your input is
        valuable. So don't hesitate to drop us a line – we're all ears.
      </span>
    )
  },
  {
    summary: (
      <span>Worried about security? Rest easy - WenBit has your back.</span>
    ),
    details: (
      <span>
        At WenBit, we understand that security is a top priority for our users.
        That's why we have implemented state-of-the-art security measures to
        protect your assets. Our multi-layered security system and continuous
        monitoring help us prevent potential threats. We are dedicated to
        providing a safe and secure trading environment for our users, and we
        will continue to invest in the latest technology to maintain this
        commitment.
      </span>
    )
  }
]

export default wenbitFAQs
