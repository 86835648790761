import React, { useRef, useState } from "react"

// styles
import Styles from "./languageCurrencySelector.module.css"

// icons
import { ReactComponent as SunIcon } from "../../assets/icons/imported/sun.svg"
import { ReactComponent as MoonIcon } from "../../assets/icons/imported/moon.svg"
import { ReactComponent as GlobalIcon } from "../../assets/icons/imported/global.svg"
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/imported/checkmark.svg"

// redux
import { useDispatch, useSelector } from "react-redux"
import { setLanguage } from "../../redux/slices/languageSlice"

// theme
import { toggleTheme } from "../../redux/slices/themeSlice"

// MUI
import Popper from "@mui/material/Popper"
import Fade from "@mui/material/Fade"
import { ClickAwayListener } from "@mui/material"

// components

function LanguageCurrencySelector() {
  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState("language")
  const language = useSelector((state) => state.language)
  const currency = useSelector((state) => state.currency.value)
  const userData = useSelector((state) => state.userData.value)
  const theme = useSelector((state) => state.theme.value)

  const languagesButtonRef = useRef(null)

  const handleSelect = (lang) => {
    dispatch(setLanguage(lang))
  }
  // language options
  const languageOptions = [
    { value: "en", label: "English" }
    // { value: "es", label: "Española" },
    // { value: "ar", label: "عربي" },
    // { value: "ru", label: "Русский" },
    // { value: "ur", label: "اردو" },
    // { value: "zh", label: "中国人" }
  ]

  const currencyOptions = [
    { value: "usd", label: "USD" }
    // { value: "eur", label: "EUR" },
    // { value: "gbp", label: "GBP" }
  ]

  // popper
  const [popperOpen, setPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const canBeOpen = popperOpen && Boolean(anchorEl)
  const id = canBeOpen ? "transition-popper" : undefined
  const handleOpen = (event) => {
    setAnchorEl(languagesButtonRef.current) //event.currentTarget
    setPopperOpen((previousOpen) => !previousOpen)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(false)
        setPopperOpen(false)
      }}
    >
      <div className={`${Styles.mainContainer}`}>
        {/* language/currency selector */}
        <button
          className={Styles.languageSelectorContainer}
          onClick={(e) => {
            setSelectedTab("language")
            handleOpen(e)
          }}
          ref={languagesButtonRef}
        >
          <GlobalIcon width={13} style={{ marginRight: 3.2 }} />
          {languageOptions.find((el) => el.value === language.code).label}
        </button>
        <div className={Styles.divider}></div>
        {userData.email && (
          <>
            <button
              className={Styles.languageSelectorContainer}
              onClick={(e) => {
                setSelectedTab("currency")
                handleOpen(e)
              }}
            >
              USD
            </button>
            <div className={Styles.divider}></div>
          </>
        )}

        {/* LANGUAGE CURRENCY POPPER */}
        <Popper
          id={id}
          open={canBeOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          sx={{
            zIndex: 2
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div
                className={Styles.popperContainer}
                style={{
                  boxShadow:
                    theme === "dark"
                      ? "0px 2px 20px rgba(0,0,0,.4)"
                      : "0px 2px 20px rgba(0,0,0,.1)"
                }}
              >
                <div className={Styles.tabsContainer}>
                  <button
                    className={`${Styles.tab} ${
                      selectedTab === "language" && Styles.selectedTab
                    }`}
                    onClick={() => setSelectedTab("language")}
                  >
                    Language
                  </button>
                  {userData.email && (
                    <button
                      className={`${Styles.tab} ${
                        selectedTab === "currency" && Styles.selectedTab
                      }`}
                      onClick={() => setSelectedTab("currency")}
                    >
                      Currency
                    </button>
                  )}
                </div>

                <div className={Styles.languageCurrencySelectorRowsContainer}>
                  {selectedTab === "language" &&
                    languageOptions.map((languageOption) => (
                      <button
                        className={`${Styles.languageCurrencyRow} ${
                          language.code === languageOption.value &&
                          Styles.selectedRow
                        }`}
                        key={languageOption.value}
                        onClick={() => {
                          handleSelect(languageOption.value)
                          setAnchorEl(false)
                          setPopperOpen(false)
                        }}
                      >
                        <span>{languageOption.label}</span>
                        {language.code === languageOption.value && (
                          <div className={Styles.selectedIconContainer}>
                            <CheckmarkIcon width={8.5} />
                          </div>
                        )}
                      </button>
                    ))}

                  {selectedTab === "currency" &&
                    currencyOptions.map((currencyOption) => (
                      <button
                        className={`${Styles.languageCurrencyRow} ${
                          currency === currencyOption.value &&
                          Styles.selectedRow
                        }`}
                        key={currencyOption.value}
                      >
                        <span>{currencyOption.label}</span>
                        {currency === currencyOption.value && (
                          <div className={Styles.selectedIconContainer}>
                            <CheckmarkIcon width={8.5} />
                          </div>
                        )}
                      </button>
                    ))}
                </div>
              </div>
            </Fade>
          )}
        </Popper>

        <div
          className={`${Styles.currencySelectorContainer} ${Styles.themeToggleContainer}`}
          onClick={() => dispatch(toggleTheme())}
        >
          {theme === "light" ? <MoonIcon width={15} /> : <SunIcon width={17} />}
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default LanguageCurrencySelector
