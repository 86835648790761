import { createSlice } from '@reduxjs/toolkit'
import { lastKlineArrayToObject,  } from '../../utility/helpers'
export const klineSlice = createSlice({
  name: 'kline',
  initialState: {
    last: '',
    pre_last: '',
    data: [],
    marketId: "btcusdt",
    loading: false,
    period: "1m",
    range: {from: 0, to: 0},
    resolution: "1m",  // current resolution
    oldResolution: null  // previous resolution
  },
  reducers: {
    changeKline: (state, payload) => {
        state.data = payload.payload
    },
    clearKline: (state, payload) => {
      state.data = []
    },
    addKline: (state, payload) => {
      state.data.unshift(payload.payload)
      state.pre_last = state.last
      state.last = lastKlineArrayToObject(payload.payload)
    },
    setKlineLoad: (state, payload) => {
      state.loading = payload.payload
    },
    changeResolution: (state, payload) => {
      state.oldResolution = state.resolution,
      state.resolution = payload.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { changeKline, clearKline, addKline, setKlineLoad, changeResolution } = klineSlice.actions

export default klineSlice.reducer