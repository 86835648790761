import React, { useEffect, useRef, useState } from "react"

// react router
import { useParams } from "react-router-dom"

// utility
import KycModal from "../../helpers/KycModal"

// components
import Orders from "../components/Orders/Orders"
import TradingChart from "../old-components/TradingChart"
import Orderbook from "../components/Orderbook/Orderbook"
import MarketForm from "../components/MarketForm/MarketForm"
import MarketsList from "../components/MarketsList/MarketsList"
import RecentTrades from "../components/RecentTrades/RecentTrades"
import MarketInsight from "../components/MarketInsight/MarketInsight"
import ExchangeFooter from "../components/ExchangeFooter/ExchangeFooter"

// redux
import { useDispatch, useSelector } from "react-redux"
import { addOrder } from "../redux/slices/openOrdersSlice"
import { addOrderBook } from "../redux/slices/orderBookSlice"
import { addTrade, changeTrade } from "../redux/slices/tradesSlice"
import { setMarketTickers } from "../redux/slices/marketTickersSlice"
import { setSelectedMarket } from "../redux/slices/selectedMarketSlice"
import {
  _getMarketTrades,
  _getBalances,
  _getOpenOrders
} from "../redux/actions"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../snackbar/redux/snackbarSlice"

// styles
import Styles from "./Exchange.module.css"
import MobileTabSwitcher from "../components/MobileTabSwitcher/MobileTabSwitcher"

function Exchange() {
  const dispatch = useDispatch()

  const markets = useSelector((state) => state.marketList?.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const selectedMarketRef = useRef(selectedMarket)
  const previousMarket = useSelector((state) => state.previousMarket.value)
  const { marketName } = useParams()

  document.title = "Wenbit: Trading"

  const theme = useSelector((state) => state.theme.value)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isTabletView, setIsTabletView] = useState(window.innerWidth < 990)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 990)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 990)
    setIsTabletView(window.innerWidth < 990)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    _getMarketTrades(
      selectedMarket.id,
      (data) => {
        dispatch(changeTrade(data))
      },
      (err) => {
        // console.log(err)
      }
    )
  }, [selectedMarket])
  // ------------------------------------
  // ------------------------------------

  // MOBILE TABS STATES -----------------
  const [selectedMobileTab, setSelectedMobileTab] = useState("Chart")
  // ------------------------------------

  useEffect(() => {
    if (marketName) {
      dispatch(
        setSelectedMarket({
          id: marketName,
          name: markets.find((market) => market.id === marketName)?.name
        })
      )
    }
  }, [marketName])

  return (
    <div>
      <div className={`${Styles.pageContainer}`}>
        {/* LEFT CONTAINER */}
        <div className={`${Styles.leftContainer}`}>
          <MarketsList />
        </div>

        {/* MIDDLE CONTAINER */}
        <div className={Styles.middleContainer}>
          <MarketInsight />
          {!isMobileView &&
            (theme === "dark" ? (
              <TradingChart
                selectedMarket={selectedMarket}
                style={{ marginBottom: "var(--gap)" }}
              />
            ) : (
              <TradingChart
                selectedMarket={selectedMarket}
                style={{ marginBottom: "var(--gap)" }}
              />
            ))}
          {!isTabletView && <MarketForm />}
          {isTabletView && !isMobileView && (
            <div className={Styles.tableOrderBookTradesContainer}>
              <Orderbook />
              <RecentTrades />
            </div>
          )}
          {isMobileView && (
            <MobileTabSwitcher
              selectedMobileTab={selectedMobileTab}
              setSelectedMobileTab={setSelectedMobileTab}
            />
          )}
          {/* <Orders /> */}
        </div>

        {/* RIGHT CONTAINER */}
        {!isMobileView && (
          <div className={Styles.rightContainer}>
            {!isTabletView && (
              <>
                <Orderbook />
                <RecentTrades />
              </>
            )}
            {isTabletView && <MarketForm />}
          </div>
        )}
      </div>

      <div className={Styles.ordersContainer}>
        <Orders />
      </div>

      {/* FOOTER */}
      <ExchangeFooter />
    </div>
  )
}

export default Exchange
