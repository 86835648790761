import React from 'react'

// Apex Charts packages
import ReactChart from './ReactChart'

// APIs
import { _getCoinKline } from './redux/actions'

function AreaChart({
    kLineData = [],
    color // success - error
}) {

    const series = [{ data: kLineData }]
    const options = {
        chart: {
          parentHeightOffset: 0,
          toolbar: { show: false },
          sparkline: { enabled: true }
        },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        grid: {
          show: false,
          padding: {
            top: 5,
            bottom: 20
          }
        },
        fill: {
          type: 'solid',
          opacity: 0
        },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     opacityTo: 0,
        //     opacityFrom: 1,
        //     shadeIntensity: 1,
        //     stops: [0, 100],
        //     colorStops: [
        //       [
        //         {
        //           offset: 0,
        //           opacity: 0.4,
        //           color: color === 'error' ? 'var(--negative-color)' : 'var(--positive-color)'
        //         },
        //         {
        //           opacity: 0,
        //           offset: 100,
        //           color: '#ffffff20'
        //         }
        //       ]
        //     ]
        //   }
        // },
        theme: {
          monochrome: {
            enabled: true,
            shadeTo: 'light',
            shadeIntensity: 1,
            color: color === 'error' ? '#e26363' : '#31bf8d'
          }
        },
        xaxis: {
          labels: { show: false },
          axisTicks: { show: false },
          axisBorder: { show: false }
        },
        yaxis: { show: false }
      }
      
  return (
    <ReactChart type='area' height={94} series={series} options={options} />
  )
}

export default AreaChart