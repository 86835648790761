// ** React Imports
import React from "react"

// ** Component Imports

import Button from "../../../../components/Button/Button"
import Autocomplete from "../../../../components/Autocomplete/Autocomplete"
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker"

// ** MUI Imports
import { Box } from "@mui/material"

function ConversionFilters() {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "20px", mb: "20px" }}
    >
      <DateRangePicker />
      <Autocomplete placeholder="Pair" />
      <Button text="Reset" variant="link" />
    </Box>
  )
}

export default ConversionFilters
