import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"

// styles
import Styles from "./recentTrades.module.css"

// components
import RecentTradesRow from "./RecentTradesRow"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"

// icons
import { IoMdArrowDropup } from "react-icons/io"
import UpWhiteArrow from "../../../../assets/icons/up-white-arrow.svg"

// redux
import { useDispatch, useSelector } from "react-redux"

function RecentTrades() {
  const trades = useSelector((state) => state.trades.value)
  const marketsList = useSelector((state) => state.marketList.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  return (
    <div className={Styles.card}>
      {trades.length > 0 && (
        <>
          {/* title */}
          <span className={Styles.cardTitle}>
            <FormattedMessage id={"page.body.trade.header.recentTrades"} />
          </span>

          {/* sorting row */}
          <div className={Styles.sortingContainer}>
            <span>
              Price (
              {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.quote_unit.toUpperCase()}
              )
            </span>
            <span>
              Amount (
              {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.base_unit.toUpperCase()}
              )
            </span>
            <span>
              <FormattedMessage
                id={"page.body.trade.header.recentTrades.content.time"}
              />
            </span>
          </div>
        </>
      )}

      {/* rows container */}
      <div>
        {trades.length > 0
          ? trades
              .slice(0, 18)
              .map((trade, index) => (
                <RecentTradesRow
                  key={index}
                  isAsk={trade.taker_type === "sell"}
                  trade={trade}
                />
              ))
          : new Array(17)
              .fill(0)
              .map((el, index) => (
                <ShimmerLine key={index} width="100%" height="24px" />
              ))}
      </div>
    </div>
  )
}

export default injectIntl(RecentTrades)
