import React, { useEffect, useState } from "react"
import TradingView from "../../../charting_library/charting_library.standalone.js"
import { useDispatch, useSelector } from "react-redux"
import {
  dataFeedObject,
  getTradingChartTimezone,
  stdTimezoneOffset
} from "./api"
import { periodStringToMinutes } from "../utility/helpers"
import { customWidgetOptions } from "./TradingChartConfig"

const TradingViewChart = ({ containerStyle = {}, selectedMarketId = null }) => {
  const tickersData = useSelector((state) => state.marketTickers.value)
  const selectedMarket = selectedMarketId
    ? { id: selectedMarketId }
    : useSelector((state) => state.selectedMarket.value)
  const markets = useSelector((state) => state.marketList.value)
  const theme = useSelector((state) => state.theme.value)
  const klineData = useSelector((state) => state.kline)

  const log = console.log
  const datafeed = dataFeedObject(TradingViewChart, markets)
  const currentTimeOffset = new Date().getTimezoneOffset()
  const clockPeriod =
    currentTimeOffset === stdTimezoneOffset(new Date()) ? "STD" : "DST"
  useEffect(() => {
    // log("tickersData", tickersData)
    const colorTheme = theme

    const widgetOptions = {
      allow_symbol_change: false,
      autosize: true,
      calendar: true,
      hide_legend: false,
      save_image: true,
      client_id: "tradingview.com",
      custom_css_url: "/chartStyles.css",
      debug: false,
      details: true,
      enabled_features: [
        "charts_auto_save",
        "control_bar",
        "legend_context_menu",
        "header_in_fullscreen_mode",
        "header_chart_type",
        "header_resolutions",
        "side_toolbar_in_fullscreen_mode",
        "dont_show_boolean_study_arguments",
        "hide_last_na_study_output",
        "save_chart_properties_to_local_storage",
        "remove_library_container_border"
      ],
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "symbol_search_hot_key",
        "header_compare",
        "hide_left_toolbar_by_default",
        "edit_buttons_in_legend",
        "show_hide_button_in_legend",
        "legend_context_menu",
        "header_screenshot",
        "header_undo_redo",
        "go_to_date",
        "study_templates",
        "save_shortcut",
        "header_saveload"
        // "timeframes_toolbar"
        // "widget_logo",
      ],

      hide_side_toolbar: false,
      hotlist: false,
      library_path: "/charting_library/",
      // popup_height: "50",
      // popup_width: "000",
      show_popup_button: true,
      // studies_overrides: {},
      timeframe: "1D",
      user_id: "public_user_id",
      withdateranges: false,
      charts_storage_url: "https://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      symbol: selectedMarket.id ?? "btcusdt",
      datafeed,
      interval: String(periodStringToMinutes("4h")),
      container_id: "tv_chart_container",
      // interval: "1D", // default interval
      container: "tv_chart_container",
      timezone: getTradingChartTimezone(currentTimeOffset, clockPeriod),
      ...customWidgetOptions(colorTheme)
    }
    window.tvWidget = new TradingView.widget(widgetOptions)
  }, [theme])

  // useEffect(() => {
  //   console.log(klineData)
  //   if (klineData.value && window.tvWidget) {
  //     // Convert klineData to the required format
  //     const klineChartData = klineData.value?.map(kline => ({
  //       time: kline.timestamp, // Assuming you have a timestamp field in klineData
  //       open: kline.open,
  //       high: kline.high,
  //       low: kline.low,
  //       close: kline.close,
  //       volume: kline.volume
  //     }))

  //     // Update the chart with new kline data
  //     window.tvWidget.activeChart().updateData([klineData.last])
  //     // datafeed.onRealtimeCallback(klineData.last)
  //   }
  // }, [klineData])
  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        if (klineData.length) {
          const newData = klineData.map((kline) => ({
            time: kline.timestamp * 1000, // Convert to milliseconds
            open: kline.open,
            high: kline.high,
            low: kline.low,
            close: kline.close,
            volume: kline.volume
          }))

          // Use applyNewData to update the chart data
          window.tvWidget.activeChart().SubscribeBarsCallback(newData)
        }
      })
    }
  }, [klineData])

  useEffect(() => {
    if (window.tvWidget) {
      window.tvWidget.onChartReady(() => {
        window.tvWidget.activeChart().setSymbol(selectedMarket.id, () => {
          // print("Symbol set", selectedMarket.id)
        })
      })
    }
  }, [selectedMarket])

  return (
    <div
      id="tv_chart_container"
      style={{
        borderRadius: 10,
        overflow: "hidden",
        height: 600,
        padding: 10,
        backgroundColor: "var(--secondary-color)",
        ...containerStyle
      }}
    ></div>
  )
}

export default TradingViewChart
