import { call, cancel, fork, delay, put, select, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { setMarketTickers } from './modules/exchange/redux/slices/marketTickersSlice'
import { addOrder } from './modules/exchange/redux/slices/openOrdersSlice'
import { addOrderBook, setOrderBookLoad, clearOrderBook } from './modules/exchange/redux/slices/orderBookSlice'
import { addTrade } from './modules/exchange/redux/slices/tradesSlice'
import { addKline, clearKline } from './modules/exchange/redux/slices/klineSlice'

const SUBSCRIBE_TO_PUBLIC_MARKET = 'SUBSCRIBE_TO_PUBLIC_MARKET'
const SUBSCRIBE_TO_PRIVATE_MARKET = 'SUBSCRIBE_TO_PRIVATE_MARKET'
const UNSUBSCRIBE_FROM_MARKET = 'UNSUBSCRIBE_FROM_MARKET'
const CLOSE_PUBLIC_WEBSOCKET = 'CLOSE_PUBLIC_WEBSOCKET'
const CLOSE_PRIVATE_WEBSOCKET = 'CLOSE_PRIVATE_WEBSOCKET'
const OPEN_PRIVATE_CONNECTION = 'OPEN_PRIVATE_CONNECTION'
const OPEN_PUBLIC_CONNECTION = 'OPEN_PUBLIC_CONNECTION'
const PUBLIC_WEBSOCKET_OPENED = 'PUBLIC_WEBSOCKET_OPENED'
const PRIVATE_WEBSOCKET_OPENED = 'PRIVATE_WEBSOCKET_OPENED'
const CHANGE_KLINE_RESOLUTION = 'CHANGE_KLINE_RESOLUTION'

let PublicWsInstance = null
let PrivateWsInstance = null
let publicWsTask = null
let PrivateWsTask = null
let subscribedMarket = null
let publicWsUrl = ''
let privateWsUrl = ''
let newPublicWebsocket = false
let newPrivateWebsocket = false
let resolution = null
let currentSubscribedMarket = null

const createPublicWebSocketChannel = (ws) => {
  return eventChannel((emitter) => {
    ws.onopen = () => {
      console.log('WebSocket connection opened')
      newPublicWebsocket = true
      setTimeout(() => {
        emitter({ type: PUBLIC_WEBSOCKET_OPENED })
        emitter({ type: SUBSCRIBE_TO_PUBLIC_MARKET })
      }, 5000)
      // Additional logic if needed
    }

    ws.onmessage = (event) => {
      // Handle incoming WebSocket messages and emit corresponding actions
      const tickers = JSON.parse(event.data)
      const key = Object.keys(tickers)[0]
      const orderIds = new Set()
      switch (key) {
        case 'global.tickers':
          emitter(setMarketTickers(tickers['global.tickers']))
          break
        case 'order': {
          const order = tickers['order']

          // Check if the order ID already exists
          if (!orderIds.has(order.id)) {
            // Add the order ID to the set
            orderIds.add(order.id)

            // Dispatch the addOrder action
            emitter(addOrder(tickers.order))
          }
          break
        }
        case `${subscribedMarket}.ob-snap`:
          emitter(setOrderBookLoad(false))
          tickers[`${subscribedMarket}.ob-snap`]['asks'].map((item) => {
            emitter(addOrderBook({ asks: item }))
          })
          tickers[`${subscribedMarket}.ob-snap`]['bids'].map((item) => {
            emitter(addOrderBook({ bids: item }))
          })
          break
        case `${subscribedMarket}.ob-inc`:
          emitter(setOrderBookLoad(false))
          if (
            tickers[`${subscribedMarket}.ob-inc`]['bids'] &&
            tickers[`${subscribedMarket}.ob-inc`]['bids'][1] !== ''
          ) {
            emitter(addOrderBook(tickers[`${subscribedMarket}.ob-inc`]))
          }
          if (
            tickers[`${subscribedMarket}.ob-inc`]['asks'] &&
            tickers[`${subscribedMarket}.ob-inc`]['asks'][1] !== ''
          ) {
            emitter(addOrderBook(tickers[`${subscribedMarket}.ob-inc`]))
          }
          break
        case `${subscribedMarket}.trades`:
          emitter(addTrade(tickers[`${subscribedMarket}.trades`].trades[0]))
          break
        case `${subscribedMarket}.kline-${resolution}`:
          if (subscribedMarket !== currentSubscribedMarket) {
            currentSubscribedMarket = subscribedMarket
            emitter(clearKline()) // Clear the kline array if market changed
          }
          emitter(addKline(tickers[`${subscribedMarket}.kline-${resolution}`]))
          break
        default:
          break
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
      newPublicWebsocket = false
      console.log("newPublicWebsocket", newPublicWebsocket)
      // Additional logic if needed
    }

    // Return the unsubscribe function
    return () => {
      // Cleanup logic if needed
    }
  })
}

export function* handlePublicWebSocket(publicWsUrl) {
  PublicWsInstance = new WebSocket(publicWsUrl)
  const channel = yield call(createPublicWebSocketChannel, PublicWsInstance)
  try {
    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    // Cleanup logic if needed
  }
}

export function* openPublicWebSocketConnection() {
  const selectedMarket = yield select((state) => state.selectedMarket.value)
  resolution = yield select((state) => state.kline.resolution)
  const oldResolution = yield select((state) => state.kline.oldResolution)
  const subscribeMessage = {
    event: 'subscribe',
    streams: [
      `${selectedMarket.id}.trades`,
      `${selectedMarket.id}.ob-snap`,
      `${selectedMarket.id}.ob-inc`,
      'global.tickers'
    ]
  }
  const subscribeKlineMessage = {
    event: 'subscribe',
    streams: [`${selectedMarket.id}.kline-${resolution}`]
  }
  const unSubscribeKlineMessage = {
    event: 'unsubscribe',
    streams: [`${selectedMarket.id}.kline-${oldResolution}`]
  }
  yield put(setOrderBookLoad(true))
  publicWsUrl =
    window.location.host.includes('localhost')
      ? `ws://exchange-stage.wenbit.com/api/v2/ws/public/?stream=global.tickers`
      : `wss://${window.location.host}/api/v2/ws/public/?stream=global.tickers`

  if (!PublicWsInstance) {
    publicWsTask = yield call(handlePublicWebSocket, publicWsUrl)
    yield take(PUBLIC_WEBSOCKET_OPENED) // Wait for WebSocket connection to be opened
  }

  // yield take(SUBSCRIBE_TO_PUBLIC_MARKET)
  if (subscribedMarket && subscribedMarket !== selectedMarket.id) {
    yield put({ type: UNSUBSCRIBE_FROM_MARKET, market: subscribedMarket })
  }
  if (!newPublicWebsocket) {
    console.log("CCC")
    yield take(PUBLIC_WEBSOCKET_OPENED)
  }
  if ((newPublicWebsocket || !subscribedMarket) && subscribedMarket !== selectedMarket.id) {
    PublicWsInstance.send(JSON.stringify(subscribeMessage))
    PublicWsInstance.send(JSON.stringify(subscribeKlineMessage))
  }
  subscribedMarket = selectedMarket.id
}

function* openPublicConnection() {
  publicWsUrl =
    window.location.host.includes('localhost')
      ? `wss://exchange-stage.wenbit.com/api/v2/ws/public`
      : `wss://${window.location.host}/api/v2/ws/public`

  // Start a new WebSocket task with the updated URL
  publicWsTask = yield call(handlePublicWebSocket, publicWsUrl)
}

export function* closePublicWebSocketConnection() {
  if (PublicWsInstance) {
    PublicWsInstance.close()
    PublicWsInstance = null
    newPublicWebsocket = false
  }
}

export function* unsubscribeFromMarket(action) {
  const { market } = action
  resolution = yield select((state) => state.kline.resolution)

  const unsubscribeMessage = {
    event: 'unsubscribe',
    streams: [
      `${market}.trades`,
      `${market}.ob-snap`,
      `${market}.ob-inc`,
      `${market}.kline-${resolution}`,
    ],
  }

  PublicWsInstance.send(JSON.stringify(unsubscribeMessage))
}

//*******************************************************************************/

const createPrivateWebSocketChannel = (ws) => {
  return eventChannel((emitter) => {
    ws.onopen = () => {
      console.log('Private webSocket connection opened')
      newPrivateWebsocket = true
      emitter({ type: PRIVATE_WEBSOCKET_OPENED })
      emitter({ type: SUBSCRIBE_TO_PRIVATE_MARKET })
      // Additional logic if needed
    }

    ws.onmessage = (event) => {
      // Handle incoming WebSocket messages and emit corresponding actions
      const tickers = JSON.parse(event.data)
      const key = Object.keys(tickers)[0]
      const orderIds = new Set()
      switch (key) {
        case 'order': {
          const order = tickers['order']

          // Check if the order ID already exists
          if (!orderIds.has(order.id)) {
            // Add the order ID to the set
            orderIds.add(order.id)

            // Dispatch the addOrder action
            emitter(addOrder(tickers.order))
          }
          break
        }
      }
    }

    ws.onclose = () => {
      console.log('WebSocket connection closed')
      newPrivateWebsocket = false
      // console.log("newPrivateWebsocket", newPrivateWebsocket)
      // Additional logic if needed
    }

    // Return the unsubscribe function
    return () => {
      // Cleanup logic if needed
    }
  })
}

export function* handlePrivateWebSocket(privateWsUrl) {
  PrivateWsInstance = new WebSocket(privateWsUrl)
  const channel = yield call(createPrivateWebSocketChannel, PrivateWsInstance)
  try {
    while (true) {
      const action = yield take(channel)
      yield put(action)
    }
  } finally {
    // Cleanup logic if needed
  }
}

export function* openPrivateWebSocketConnection() {
  const loggedIn = yield select((state) => state.loggedIn.value)
  const subscribeMessage = {
    event: 'subscribe',
    streams: ['order', 'account']
  }
  privateWsUrl =
    window.location.host.includes('localhost')
      ? `wss://exchange-stage.wenbit.com/api/v2/ws/private`
      : `wss://${window.location.host}/api/v2/ws/private`

  if (!PrivateWsInstance) {
    PrivateWsTask = yield call(handlePrivateWebSocket, privateWsUrl)
    yield take(PRIVATE_WEBSOCKET_OPENED) // Wait for WebSocket connection to be opened
  }
  PrivateWsInstance.send(JSON.stringify(subscribeMessage))
}

function* openPrivateConnection() {
  privateWsUrl =
    window.location.host.includes('localhost')
      ? `wss://exchange-stage.wenbit.com/api/v2/ws/private`
      : `wss://${window.location.host}/api/v2/ws/private`

  if (PrivateWsInstance) {
    PrivateWsInstance.close()
    PrivateWsInstance = null
    newPrivateWebsocket = false
  }

  // Start a new WebSocket task with the updated URL
  PrivateWsTask = yield call(handlePrivateWebSocket, privateWsUrl)
}

export function* closePrivateWebSocketConnection() {
  if (PrivateWsInstance) {
    PrivateWsInstance.close()
    PrivateWsInstance = null
    newPrivateWebsocket = false
  }
}

function* handleResolutionChange() {
  yield take(CHANGE_KLINE_RESOLUTION)
  console.log("testtttttt")
  const oldResolution = yield select((state) => state.kline.oldResolution)
  const currentResolution = yield select((state) => state.kline.resolution)
  const selectedMarket = yield select((state) => state.selectedMarket.value)
  console.log("---RESOLUTIONS---", oldResolution, currentResolution)
  if (oldResolution && oldResolution !== currentResolution) {
    // Unsubscribe from old resolution stream
    const unSubscribeKlineMessage = {
      event: 'unsubscribe',
      streams: [`${selectedMarket.id}.kline-${oldResolution}`]
    }
    PublicWsInstance.send(JSON.stringify(unSubscribeKlineMessage))
 
    // Subscribe to new resolution stream
    const subscribeKlineMessage = {
      event: 'subscribe',
      streams: [`${selectedMarket.id}.kline-${currentResolution}`]
    }
    PublicWsInstance.send(JSON.stringify(subscribeKlineMessage))
  }
}

export function* websocketSaga() {
  yield takeEvery(SUBSCRIBE_TO_PUBLIC_MARKET, openPublicWebSocketConnection)
  yield takeEvery(SUBSCRIBE_TO_PRIVATE_MARKET, openPrivateWebSocketConnection)
  yield takeEvery(UNSUBSCRIBE_FROM_MARKET, unsubscribeFromMarket)
  yield takeEvery(CLOSE_PUBLIC_WEBSOCKET, closePublicWebSocketConnection)
  yield takeEvery(CLOSE_PRIVATE_WEBSOCKET, closePrivateWebSocketConnection)
  yield takeEvery(OPEN_PRIVATE_CONNECTION, openPrivateConnection)
  yield takeEvery(OPEN_PUBLIC_CONNECTION, openPublicConnection)
  yield takeEvery(CHANGE_KLINE_RESOLUTION, handleResolutionChange)
}