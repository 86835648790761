import { API } from "../../../../utility/API"

//************************************//
export const _getOrders = (data, callback, callbackErr) => {
  API.get(`trading/market/orders?limit=25&page=1${data.state}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getConversions = (callback, callbackErr) => {
  API.get("trading/market/conversions?limit=25&page=1")
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error.response.data)
    })
}
//************************************//
export const _getTrades = (data, callback, callbackErr) => {
  API.get(`trading/market/trades?limit=${data.limit}&page=${data.page}`)
    .then(function ({ data }) {
      callback(data)
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _getWithdrawalHistory = (data, callback, callbackErr) => {
  API.get(`trading/account/withdraws?limit=${data.pageLimit}&page=${data.page}`)
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getDepositHistory = (data, callback, callbackErr) => {
  API.get(`trading/account/deposits?limit=${data.pageLimit}&page=${data.page}`)
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getWalletHistory = (data, callback, callbackErr) => {
  API.get(`trading/account/wallets?limit=${data.pageLimit}&page=${data.page}`)
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function ({ data }) {
      callbackErr(data)
    })
}
//************************************//
export const _getBalances = (data, callback, callbackErr) => {
  API.get(`trading/account/balances`, {
    params: data
  })
    .then(function ({ data, headers }) {
      callback({ data, page: headers["page"], total: headers["total"] })
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
//************************************//
export const _updateBalancePreference = (params, callback, callbackErr) => {
  API.put(`authenticate/resource/users/balance_preference`, params)
    .then(function ({ data }) {
      callback({ data })
    })
    .catch(function (error) {
      callbackErr(error)
    })
}
