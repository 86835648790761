import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const cryptocurrencyFAQs = [
  {
    summary: <span>New to the world of crypto and feeling overwhelmed?</span>,
    details: (
      <span>
        Fear not, friend. At WenBit, we've got your back. Our platform is
        designed to make investing in crypto simple and accessible, even for
        beginners. And if you're still unsure, we have comprehensive{" "}
        <Link to="/guide">guides</Link> to help you catch up and start. Join us
        today, and let's explore the world of crypto together.
      </span>
    )
  },
  {
    summary: <span>Is trading crypto risky? Let's talk about it.</span>,
    details: (
      <span>
        Trading crypto can be risky, as with any investment. However, at WenBit,
        we take the safety and security of our users' funds very seriously. Our
        platform employs industry-standard security measures and fully complies
        with all necessary regulations. So, while there is always some risk
        involved in trading, you can trust that your assets are safe with
        WenBit.
      </span>
    )
  }
]

export default cryptocurrencyFAQs
