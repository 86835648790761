import React from 'react'

// react router
import { useHistory } from 'react-router-dom'

// styles
import Styles from './informationModal.module.css'

// icons
import { ReactComponent as InfoIcon } from '../../../assets/icons/imported/info.svg'

// redux
import { useSelector } from 'react-redux'

// components
import WenbitModal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'


function InformationModal({
    open = false,
    handleClose
}) {

    const modalData = useSelector(state => state.informationModalState)

    const vsar = 'asdsad'
    
  return (
    <>
        <WenbitModal
            open={open}
            showCloseButton={true}
            onClose={handleClose}
            showLineHeader={true}
            lineHeaderColor='var(--primary-color)'
            render={() => (
                <div className={Styles.bodyContainer}>
                    <InfoIcon
                        color='var(--primary-color)'
                        height={55}
                        width={55}
                    />
                    <span className={Styles.title}>{modalData.data.title}</span>
                    <span className={Styles.description}>{modalData.data.subtitle}</span>

                    <div className={Styles.buttonsContainer}>
                        <Button
                            text='Cancel'
                            variant='dark'
                            style={{
                                width: 'fit-content'
                            }}
                            onClick={() => {
                                handleClose()
                            }}
                        />
                        <Button
                            text='Confirm'
                            style={{
                                width: 'fit-content'
                            }}
                            onClick={() => {
                                modalData.data.callback()
                            }}
                        />
                    </div>
                </div>
            )}
        />
    </>
  )
}

export default InformationModal