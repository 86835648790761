import React, { useRef, useState, useEffect } from "react"
import Styles from "./EnableSMSAuthModal.module.css"
import { useSelector, useDispatch } from "react-redux"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../../snackbar/redux/snackbarSlice"
import { ReactComponent as MobileIcon } from "../../../../assets/icons/imported/mobile.svg"
import { ReactComponent as EditIcon } from "../../../../assets/icons/imported/edit.svg"
import Input from "../../../../components/Input/Input"
import InputCode from "../../../../components/InputCode"
import Button from "../../../../components/Button/Button"
import WenbitModal from "../../../../components/Modal/Modal"
import { FormattedMessage, injectIntl } from "react-intl"
import { closeEnableSMSAuthModal } from "./helpers"
import { _sendOtp, _verifyOtp } from "./redux/actions"
import { _getUserInfo } from "../../../../redux/actions"
import { setLoggedIn } from "../../../../redux/slices/loggedIn"
import { setUser, logoutUser } from "../../../user/redux/slices/userDataSlice"
import { clearBalances } from "../../../../redux/slices/balancesSlice"
import { showErrorSnackbar } from "../../../snackbar/helpers"

function EnableSMSAuthModal() {
  const theme = useSelector((state) => state.theme.value)
  const modalOpen = useSelector((state) => state.enableSMSAuthModalState.open)
  const modalData = useSelector((state) => state.enableSMSAuthModalState.data)
  const userData = useSelector((state) => state.userData.value)

  const [phoneNumber, setPhoneNumber] = useState("")
  const phoneInputRef = useRef(null)
  const [phoneOtp, setPhoneOtp] = useState("")
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [remainingTime, setRemainingTime] = useState(0)
  const dispatch = useDispatch()

  const handleSetPhoneNumber = () => {
    setLoading(true)
    const number = phoneInputRef.current?.value
    setPhoneNumber(number)
    _sendOtp(
      {
        phone_number: number
      },
      () => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: `Code Sent to ${number}`,
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
        setStep(2)
        setRemainingTime(180) // 3 minutes in seconds
      },
      (error) => {
        setLoading(false)
        // resource.phone.please.provide.existing.phone.number
        showErrorSnackbar({
          alertMessage: error.errors[0].length ? (
            <FormattedMessage id={error.errors[0]} />
          ) : (
            "Unknown Error"
          )
        })
      }
    )
  }

  const reSendVerification = () => {
    _sendOtp(
      {
        phone_number: phoneNumber
      },
      () => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: `Code Resent to ${phoneNumber}`,
            severity: "success",
            callback: () => {},
            position: "topRight"
          })
        )
        setRemainingTime(180) // 3 minutes in seconds
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  const handleInputCodeSubmit = () => {
    setLoading(true)
    _verifyOtp(
      {
        phone_number: phoneNumber,
        phone_otp: phoneOtp
      },
      () => {
        _getUserInfo(
          (data) => {
            setLoading(false)
            dispatch(setSnackbarOpen(true))
            dispatch(
              setSnackbarData({
                alertMessage: "SMS Authentication Enabled",
                severity: "success",
                callback: () => {},
                position: "topRight"
              })
            )
            closeEnableSMSAuthModal()
            dispatch(setLoggedIn(true))
            dispatch(setUser(data))
            setLoading(false)
            if (data.errors) {
              dispatch(logoutUser())
              dispatch(setLoggedIn(false))
              dispatch(clearBalances())
              setLoading(false)
            }
          },
          (error) => {
            closeEnableSMSAuthModal()
            setLoading(false)
            if (error?.errors) {
              dispatch(logoutUser())
              dispatch(clearBalances())
              dispatch(setLoggedIn(false))
            }
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            callback: () => {},
            position: "topRight"
          })
        )
      }
    )
  }

  useEffect(() => {
    let interval = null
    if (remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [remainingTime])

  return (
    <>
      <WenbitModal
        open={modalOpen}
        onClose={closeEnableSMSAuthModal}
        title={modalData?.title}
      >
        {step === 1 && (
          <>
            <div>Verify your phone</div>
            <div className={Styles.subtitle}>
              What’s your number? Let’s verify your phone number to keep bits
              legit.
            </div>

            <Input type="phoneNumber" showBorder ref={phoneInputRef} />

            <Button
              text={
                remainingTime === 0
                  ? "Get code"
                  : `Wait ${remainingTime}s before sending another code`
              }
              onClick={handleSetPhoneNumber}
              isLoading={loading}
              disabled={remainingTime > 0 || loading}
            />
          </>
        )}

        {step === 2 && (
          <div className={Styles.smsContainer}>
            <div className={Styles.smsIconContainer}>
              <MobileIcon width={17} />
            </div>

            <h5>SMS Phone Verification</h5>

            <Button
              text={
                phoneNumber?.length
                  ? phoneNumber
                  : `+${userData?.phones[0]?.number}`
              }
              variant="outlined"
              icon={<EditIcon width={12} />}
              onClick={() => setStep(1)}
              iconPosition="right"
              style={{ width: "fit-content" }}
            />

            <span className={Styles.smsDescription}>
              Insert the 5-digit code we just sent on your number
              {/* to proceed
              with the KYC process. */}
            </span>

            <InputCode
              length={5}
              onComplete={(e) => {
                setPhoneOtp(e)
              }}
              inputStyle={{
                backgroundColor:
                  theme === "light"
                    ? "var(--secondary-color)"
                    : "var(--background-color)"
              }}
            />

            <Button
              text={
                remainingTime > 0
                  ? `Resend code in ${remainingTime} seconds`
                  : "Resend Code"
              }
              disabled={remainingTime > 0}
              variant="link"
              onClick={reSendVerification}
              className={Styles.resendCodeButton}
            />

            <Button
              text="Confirm"
              disabled={loading}
              isLoading={loading}
              onClick={handleInputCodeSubmit}
            />
          </div>
        )}
      </WenbitModal>
    </>
  )
}

export default injectIntl(EnableSMSAuthModal)
