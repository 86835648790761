import React, { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// styles
import Styles from "./smsCode.module.css"

// icons
import { ReactComponent as EditIcon } from "../../../../assets/icons/imported/edit.svg"
import { ReactComponent as MobileIcon } from "../../../../assets/icons/imported/mobile.svg"

// apis
import { _verify, _reSendCode } from "../../redux/actions"

// redux
import { useDispatch, useSelector } from "react-redux"
import {
  setSnackbarData,
  setSnackbarOpen
} from "../../../snackbar/redux/snackbarSlice"

// utils
import { FormattedMessage } from "react-intl"
import Button from "../../../../components/Button/Button"
import InputCode from "../../../../components/InputCode"

// components

function SMSCode({ setStep, phoneNumber }) {
  // hooks
  const dispatch = useDispatch()

  // states
  const [remainingTime, setRemainingTime] = useState(180)
  const [verificationCode, setVerificationCode] = useState(null)
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")

  const userData = useSelector((state) => state.userData.value)

  // constants
  const waitTime = 180

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true),
      setRecaptchaResponse(value)
  }

  const verify = () => {
    _verify(
      {
        phone_number: phoneNumber,
        verification_code: verificationCode,
        recaptcha_response: recaptchaResponse
      },
      (data) => {
        // console.log(data)
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: "Successfully Verified",
            severity: "success",
            //buttonMessage: 'See order',
            callback: () => { },
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
        setStep(2)
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            ),
            severity: "error",
            //buttonMessage: 'See order',
            callback: () => { },
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      }
    )
  }

  const reSendVerification = () => {
    setRemainingTime(waitTime)
    _reSendCode(
      {
        phone_number: phoneNumber,
        recaptcha_response: recaptchaResponse
      },
      (data) => {
        dispatch(setSnackbarOpen(true))
        dispatch(
          setSnackbarData({
            alertMessage: <FormattedMessage id={data.message} />,
            severity: "success",
            //buttonMessage: 'See order',
            callback: () => { },
            //actionType: 'link', // link - close
            //linkTo: '/account/orders/open',
            position: "topRight"
          })
        )
      },
      () => { }
    )
  }

  useEffect(() => {
    let intervalId
    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, []) // remainingTime

  return (
    <div className={Styles.smsContainer}>
      {/* icon */}
      <div className={Styles.smsIconContainer}>
        <MobileIcon width={17} />
      </div>

      <h5>SMS Phone Verification</h5>

      <span className={Styles.smsDescription}>
        Insert the 4-digit code we just sent on your number to proceed with the
        KYC process.
      </span>

      <Button
        text={`+ ${phoneNumber?.length ? phoneNumber : userData?.phones[0]?.number
          }`}
        variant="outlined"
        icon={<EditIcon width={12} />}
        onClick={(e) => setStep(0)}
        iconPosition="right"
        style={{ margin: "20px 0", width: "fit-content" }}
      />

      <InputCode
        length={5}
        onComplete={(e) => {
          setVerificationCode(e)
          // console.log(e)
        }}
        inputStyle={{
          backgroundColor: "var(--background-color)"
        }}
      />
      <div className="base-sign-up-form__checkbox">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={recaptchaOnChange}
        />
      </div>
      <Button
        text={
          remainingTime > 0
            ? `Resend code in ${remainingTime} seconds`
            : "Resend Code"
        }
        // disabled={disabled}
        variant="link"
        disabled={!recaptchaConfirmed}
        onClick={reSendVerification}
      />

      <Button text="Confirm" disabled={!recaptchaConfirmed} onClick={verify} />
    </div>
  )
}

export default SMSCode
