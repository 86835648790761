import React from "react"
import Layout from "../components/Layout"
import { Switch, Route, Redirect } from "react-router-dom"
import Exchange from "../modules/exchange/views/Exchange"
import Markets from "../modules/markets/views/markets"
// import Profile from '../modules/user/views/profile'
import Settings from "./settings"
import Reset from "./reset"
import OtpVerify from "./otp-verify"
import OtpNumber from "./otp-number"
import Lock from "./lock"
import TermsAndConditions from "../modules/terms-and-conditions/terms-and-conditions"
import NewsDetails from "./news-details"
import Notfound from "../modules/not-found/notfound"
import Convert from "../modules/convert/views/Convert"
import LandingPage from "../modules/landing-page/views/LandingPage"
import Wallet from "../modules/history/views/Wallet/Wallet"
import Profile from "../modules/profile/views/Profile"
import KYCVerification from "../modules/kyc/views/KYCVerification"
import ComingSoon from "../modules/coming-soon/ComingSoon"
import Blogs from "../modules/blogs/views/Blogs"

// redux
import { useSelector } from "react-redux"
import UserTabsNavigation from "../modules/user/views/UserTabsNavigation"
import SessionExpired from "../modules/session-expired/SessionExpired"

// routes
import {
  userRoutes,
  authenticatedRoutes,
  comingSoonRoutes,
  blogRoutes
} from "../constants/routesConstants"

// launchpad pages
import Launchpad from "../modules/launchpad/views/Launchpad/Launchpad"
import CoinDetails from "../modules/launchpad/views/CoinDetails/CoinDetails"
import ApplyLaunchpad from "../modules/launchpad/views/ApplyLaunchpad/ApplyLaunchpad"

// footer pages
import FAQs from "../modules/FAQs/FAQs"
import AboutUs from "../modules/about-us/AboutUs"
import Downloads from "../modules/downloads/Downloads"
import Careers from "../modules/careers/Careers/Careers"
import CareerApply from "../modules/careers/CareerApply/CareerApply"
import ContactSupport from "../modules/contact-support/ContactSupport"
import YourBitMatters from "../modules/your-bit-matters/YourBitMatters"
import CompanySecurity from "../modules/company-security/CompanySecurity"
import BrowseCareers from "../modules/careers/BrowseCareers/BrowseCareers"
import MobileApplication from "../modules/mobile-application/MobileApplication"
import CustomRoute from "../components/CustomRoute/CustomRoute"
import OnOffRamp from "../modules/on-off-ramp/views/OnOffRamp/OnOffRamp"
import PrivacyPolicy from "../modules/privacy-policy/privacy-policy"
import ViewBlog from "../modules/blogs/views/view-blog"

// mobile
import MobileChart from "../components/MobileChart"

export default function index() {
  const userData = useSelector((state) => state.userData.value)
  const isLoggedIn = useSelector((state) => state.userData.value.email)
  const hasEmailLabel = userData?.labels?.some((label) => label.key === "email")

  return (
    <>
      <Layout>
        <Switch>
          <CustomRoute exact path="/">
            <LandingPage />
          </CustomRoute>
          <CustomRoute exact path="/trade/:marketName?">
            {/* {
              isLoggedIn && hasEmailLabel ? <Exchange /> : isLoggedIn && !hasEmailLabel ? <Notfound /> : <Exchange />
            }          */}
            <Exchange />
          </CustomRoute>
          <CustomRoute path="/markets">
            {/* {
              isLoggedIn && hasEmailLabel ? <Markets /> : isLoggedIn && !hasEmailLabel ? <Notfound /> : <Markets />
            } */}
            <Markets />
          </CustomRoute>

          {/* ------------  BLOG PAGES ------------ */}
          {/* ------------------------------------- */}
          {blogRoutes.map((route) => (
            <CustomRoute exact path={route} key={route}>
              <Blogs />
            </CustomRoute>
          ))}
          <CustomRoute path="/blogs/:id">
            <ViewBlog />
          </CustomRoute>

          {/* ------------  LAUNCHPAD PAGES ------------ */}
          {/* ------------------------------------------ */}
          {/* <CustomRoute exact path="/launchpad/apply">
            <ApplyLaunchpad />
          </CustomRoute>
          <CustomRoute exact path="/launchpad">
            <Launchpad />
          </CustomRoute>
          <CustomRoute path="/launchpad/:coinName?">
            <CoinDetails />
          </CustomRoute> */}
          {/* ------------------------------------------ */}
          {/* ------------------------------------------ */}
          <CustomRoute path="/account/orders">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/account/trade">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/account/convert">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/account/wallet">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/account/launchpad">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/convert/history">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute path="/account">
            {isLoggedIn && hasEmailLabel ? (
              <Profile />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          {/* <CustomRoute path="/convert">
            <Convert />
          </CustomRoute > */}
          <CustomRoute path="/convert/:marketId?">
            {/* {
              isLoggedIn && hasEmailLabel ? <Convert /> : isLoggedIn && !hasEmailLabel ? <Notfound /> : <Convert />
            } */}
            <Convert />
          </CustomRoute>
          <CustomRoute path="/history">
            {isLoggedIn && hasEmailLabel ? (
              <Wallet />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>

          {/* On Off Ramp */}
          <CustomRoute path="/on-off-ramp">
            <OnOffRamp />
          </CustomRoute>

          {/* <CustomRoute path="/settings">
            {isLoggedIn && hasEmailLabel ? (
              <Settings />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute > */}

          {/* -------------- FOOTER PAGES -------------- */}
          {/* ------------------------------------------ */}
          <CustomRoute path="/contact-support">
            <ContactSupport />
          </CustomRoute>
          <CustomRoute path="/downloads">
            <Downloads />
          </CustomRoute>
          <CustomRoute path="/about-us">
            <AboutUs />
          </CustomRoute>
          <CustomRoute path="/company-security">
            <CompanySecurity />
          </CustomRoute>
          <CustomRoute path="/faq">
            <FAQs />
          </CustomRoute>
          <CustomRoute path="/your-bit-matters">
            <YourBitMatters />
          </CustomRoute>
          <CustomRoute exact path="/careers">
            <Careers />
          </CustomRoute>
          <CustomRoute exact path="/careers/browse">
            <BrowseCareers />
          </CustomRoute>
          <CustomRoute exact path="/careers/:jobId">
            <CareerApply />
          </CustomRoute>
          <CustomRoute path="/mobile-application">
            <MobileApplication />
          </CustomRoute>
          {/* ------------------------------------------ */}
          {/* ------------------------------------------ */}

          <CustomRoute path="/kyc">
            {isLoggedIn && hasEmailLabel ? (
              <KYCVerification />
            ) : isLoggedIn && !hasEmailLabel ? (
              <Redirect to="/email-verification-required" />
            ) : (
              <Redirect to="/trade" />
            )}
          </CustomRoute>
          <CustomRoute
            path={`/:pathname(${userRoutes.join("|")})`}
            component={UserTabsNavigation}
          />
          {/* <CustomRoute path="/login">
            <Login />
          </CustomRoute >
          <CustomRoute path="/signup">
            <Signup />
          </CustomRoute >
          <CustomRoute path="/recover-password">
            <PasswordRecovery />
          </CustomRoute >
          <CustomRoute path="/2fa">
            <TwoFA />
          </CustomRoute >
          <CustomRoute path="/confirmation-code">
            <CodeConfirmation />
          </CustomRoute > */}
          {/* <CustomRoute path="/otp-number">
            <OtpNumber />
          </CustomRoute > */}
          {/* <CustomRoute path="/reset">
            <Reset />
          </CustomRoute > */}
          {/* <CustomRoute path="/lock">
            <Lock />
          </CustomRoute > */}
          {/* <CustomRoute path="/otp-verify">
            <OtpVerify />
          </CustomRoute > */}
          <CustomRoute path="/terms-and-conditions">
            <TermsAndConditions />
          </CustomRoute>
          <CustomRoute path="/privacy-and-policy">
            <PrivacyPolicy />
          </CustomRoute>
          <CustomRoute path="/news-details">
            <NewsDetails />
          </CustomRoute>
          {/* <CustomRoute path="/accounts/confirmation">
            <EmailVerified />
          </CustomRoute > */}
          {/* <CustomRoute path="/accounts/password_reset">
            <ResetPassword />
          </CustomRoute > */}
          <CustomRoute
            path={`/:pathname(${comingSoonRoutes.join("|")})`}
            component={ComingSoon}
          />

          {/* MOBILE VIEW CHART */}
          <CustomRoute path="/mobile/chart" component={MobileChart} />

          <CustomRoute path="/session-expired">
            <SessionExpired />
          </CustomRoute>
          <CustomRoute>
            <Notfound />
          </CustomRoute>
        </Switch>
      </Layout>
    </>
  )
}
