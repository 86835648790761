import React from 'react'

// utils
import {formatNumber} from '../../../helpers/formatNumber'

// styles
import Styles from './insightCard.module.css'

// redux
import { useSelector } from 'react-redux'

// icons
import UpArrow from '../../../../assets/icons/up-arrow.svg'
import DownArrow from '../../../../assets/icons/down-arrow.svg'
import Tooltip from '../../../../components/Tooltip/Tooltip'

function InsightCard({
    title,
    titleIcon,
    price,
    percentageChange,
    tooltipText = ''
}) {

  const theme = useSelector(state => state.theme.value)

  return (
    <div className={`${theme === 'light' && Styles.lightCard} ${Styles.card}`}>

        <div className={Styles.header}>
            {titleIcon}
            {title}
        </div>

        <div className={Styles.price}>{formatNumber(price)}</div>

        {
          percentageChange >= 0 ? (
            <div className={Styles.coinChange} style={{color: 'var(--positive-color)'}}>
              +{percentageChange}%
              <img
                src={UpArrow}
              />
            </div>
          ) : (
            <div className={Styles.coinChange} style={{color: 'var(--negative-color)'}}>
              {percentageChange}
              %
              <img
                src={DownArrow}
              />
            </div>
          )
        }

        {
          tooltipText && (
            <Tooltip
              text={tooltipText}
              iconWidth={10}
              toolTipStyle={{
                position: 'absolute',
                right: 4,
                bottom: 1
              }}
              arrowPlacement='bottom'
            />
          )
        }

    </div>
  )
}

export default InsightCard