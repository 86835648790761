import React, { useState } from "react"

// ** Styles Imports
import Styles from "./onOffRamp.module.css"

// ** Assets Imports
import { ReactComponent as Visa } from "../../../../assets/images/payments/visa.svg"
import { ReactComponent as PayPal } from "../../../../assets/images/payments/paypal-text.svg"
import { ReactComponent as Maestro } from "../../../../assets/images/payments/maestro-text.svg"

// ** Icons Imports
import { ReactComponent as LinkIcon } from "../../../../assets/icons/imported/link.svg"
import { ReactComponent as GraphIcon } from "../../components/StepsCard/assets/graph-icon.svg"
import { ReactComponent as AddUserIcon } from "../../components/StepsCard/assets/add-user-icon.svg"
import { ReactComponent as CreditCardIcon } from "../../components/StepsCard/assets/credit-card-icon.svg"

// ** MUI Imports
import { Box, Grid, useMediaQuery } from "@mui/material"

// ** Redux Imports
import { useSelector } from "react-redux"
import { showSelectCoinModal } from "../../../modals/select-currency/helpers"

// ** Components Imports
import Card from "../../components/StepsCard"
import Accordions from "../../../../components/Accordions/Accordions"
import TableButton from "../../../../components/TableButton/TableButton"
import CurrencyInput from "../../../../components/CurrencyInput/CurrencyInput"
import SectionHeader from "../../../../components/SectionHeader/SectionHeader"

function OnOffRamp() {
  // ** Media Queries
  const isTabletView = useMediaQuery("(max-width:800px)")

  // ** States
  const [toCurrency, setToCurrency] = useState("btc")
  const [fromCurrency, setFromCurrency] = useState("eur")
  const markets = useSelector((state) => state.marketList.value)

  return (
    <div className={Styles.pageContainer}>
      <Grid container sx={{ mb: 20 }}>
        {!isTabletView && (
          <Grid item sm={7} className={Styles.headerLeft}>
            <h1>
              Buy crypto <br /> in a few easy steps
            </h1>
            <div style={{ margin: "60px 0" }}>
              Buy Bitcoin & 99+ cryptocurrency with 50+ fiat currencies
            </div>

            <div>We accept</div>
            <div className={Styles.paymentIconsContainer}>
              <PayPal />
              <Visa />
              <Maestro />
            </div>
          </Grid>
        )}
        <Grid
          item
          sm={isTabletView ? 9 : 5}
          sx={{ width: "100%", margin: "0 auto" }}
        >
          <div className={Styles.buyCryptoButtonContainer}>
            <h1>Buy Crypto</h1>
            <TableButton
              //   onClick={redirectToConvertHistory}
              buttonText="Conversion History"
              buttonIcon={
                <LinkIcon
                  style={{
                    width: 12
                  }}
                />
              }
            />
          </div>
          {isTabletView && <h1>in a Few Easy Steps</h1>}
          <CurrencyInput
            title="Pay"
            showDropdownArrow
            containerStyle={{
              backgroundColor: "var(--secondary-color)"
            }}
            currencyImg="https://cryptologos.cc/logos/fantom-ftm-logo.png?v=023"
            coinName="FTM"
            placeholder="0.00"
            onDropdownClick={() => showSelectCoinModal({ type: "fiat" })}
            showChart
          />
        </Grid>
      </Grid>

      <SectionHeader title="3 easy steps" />
      <div className={Styles.cardsContainer}>
        <Card
          title="Sign Up"
          description={
            <>
              Join the ranks of <br />
              our elite trading community.
            </>
          }
          icon={<AddUserIcon width={40} />}
        />
        <Card
          title="Verify"
          description={
            <>
              Keep our security team happy.
              <br />
              We want to make sure you’re elite.
            </>
          }
          icon={<CreditCardIcon width={40} />}
        />
        <Card
          title="Trade"
          description={
            <>
              Take on the market
              <br />
              like a boss.
            </>
          }
          icon={<GraphIcon width={40} />}
        />
      </div>

      {/* FAQs */}
      <SectionHeader title="FAQ" />
      <Accordions
        containerStyle={{ maxWidth: 700, margin: "0 auto" }}
        accordions={[
          {
            summary: "Q1",
            details: "Answer"
          },
          {
            summary: "Q2",
            details: "Answer"
          },
          {
            summary: "Q3",
            details: "Answer"
          },
          {
            summary: "Q4",
            details: "Answer"
          }
        ]}
      />
    </div>
  )
}

export default OnOffRamp
