import React, { useEffect, useState, useMemo } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux'

// components
import InsightCard from './InsightCard'
import Button from '../../../../components/Button/Button'
import { formatNumber } from '../../../helpers/formatNumber'
import CoinTag from '../../../../components/CoinTag/CoinTag'
import ShimmerLine from '../../../../components/shimmer-components/ShimmerLine'
import ShimmerCircle from '../../../../components/shimmer-components/ShimmerCircle'
import MarketsListMobile from '../MarketsListMobile/MarketsListMobile'
import WenbitModal from '../../../../components/Modal/Modal'
import Tooltip from '../../../../components/Tooltip/Tooltip'

// styles
import Styles from './marketInsight.module.css'

// icons
import { FiInfo } from 'react-icons/fi'
import { FaRegClock } from 'react-icons/fa'
import { CgArrowsExchangeV } from 'react-icons/cg'
import { TbActivityHeartbeat } from 'react-icons/tb'
import { ReactComponent as UpArrowIcon } from '../../../../assets/icons/imported/up-arrow.svg'
import { ReactComponent as HighLowIcon } from '../../../../assets/icons/imported/high-low-price.svg'
import { ReactComponent as BuyArrowIcon } from '../../../../assets/icons/imported/buy-arrow.svg'
import { ReactComponent as SellArrowIcon } from '../../../../assets/icons/imported/sell-arrow.svg'
import { setSelectCurrencyModalData, setSelectCurrencyModalOpen } from '../../../modals/select-currency/redux/selectCurrencyModalSlice'
import { clearTrades } from '../../redux/slices/tradesSlice'
import { clearOrderBook } from '../../redux/slices/orderBookSlice'
import { setSelectedMarket } from '../../redux/slices/selectedMarketSlice'
import { setPreviousMarket } from '../../redux/slices/previousMarketSlice'

function MarketInsight() {

    const dispatch = useDispatch()

    const selectedMarket = useSelector(state => state.selectedMarket.value)
    const marketTickers = useSelector((state) => state.marketTickers.value)
    const tickers = marketTickers[selectedMarket.id]
    const marketsList = useSelector(state => state.marketList.value)
    const lastTrade = useSelector(state => state.trades.value[0])
    const memoizedLastTrade = useMemo(() => lastTrade, [lastTrade])
    const selectedMarketDetails = marketTickers[selectedMarket.id]
    const [targetedMarket, setTargetedMarket] = useState(marketsList?.find(el => el.id === selectedMarket.id))
    const [selectPairModalOpen, setSelectPairModalOpen] = useState(false)
    // ----- RESPONSIVENESS STATE ---------
    // ------------------------------------
    const [hideMarketsList, setHideMarketsList] = useState(window.innerWidth > 990)

    const handleResize = () => {
        setHideMarketsList(window.innerWidth > 990)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
        window.removeEventListener('resize', handleResize)
        }
    }, [])
    // ------------------------------------
    // ------------------------------------

    useEffect(() => {
        setTargetedMarket(marketsList?.find(el => el.id === selectedMarket.id))
        // console.log(targetedMarket)
    }, [selectedMarket])

    const changeCurrency = (currency) => {
        dispatch(clearOrderBook())
        dispatch(clearTrades())
        // setSelectedMarket(currency)
        dispatch(setSelectedMarket(currency))
    
        history.push(currency.id)
    }

    return (
            <div className={Styles.card}>

                {/* left container */}
                <div className={Styles.leftContainer}>

                    <div>
                        {
                            targetedMarket ? (
                                <img
                                    src={targetedMarket?.icon_url}
                                />
                            ) : (
                                <ShimmerCircle
                                    size='50px'
                                />
                            )
                        }

                        {
                            targetedMarket ? (
                                <span>{`${targetedMarket?.coin_name.toUpperCase()}`}</span>
                            ) : (
                                <ShimmerLine
                                    height='18px'
                                    width='50px'
                                />
                            )
                        }

                        {
                            targetedMarket && (
                                <CoinTag
                                    //tag={targetedMarket?.base_unit.toUpperCase()}
                                    tag={targetedMarket?.name.split('/')[0] + '/' + targetedMarket?.name.split('/')[1]}
                                    size={12}
                                />
                            )
                        }
                        {
                            !hideMarketsList && (
                                <Button
                                    className={Styles.selectPairButton}
                                    text={`Select Pair`}
                                    icon={
                                        <UpArrowIcon
                                            style={{
                                                transform: 'rotateX(180deg)'
                                            }}
                                        />
                                    }
                                    iconPosition='right'
                                    variant='link'
                                    onClick={() => {
                                        setSelectPairModalOpen(true)
                                        // dispatch(setSelectCurrencyModalData({
                                        //     title: 'Pairs',
                                        //     showTabs: false,
                                        //     type: 'all',
                                        //     showMarketPairs: true,
                                        //     searchPlaceholder: 'Search Coin Pair',
                                        //     // currenciesToHide: [toCurrency],
                                        //     disableBackdropClick: false,
                                        //     callback: (market) => {
                                        //         dispatch(setSelectCurrencyModalOpen(false))
                                        //         changeCurrency({
                                        //             id: market.id,
                                        //             name: market.name
                                        //         })
                                        //     }
                                        //   }))
                                        //   dispatch(setSelectCurrencyModalOpen(true))
                                    }}
                                />
                            )
                        }
                    </div>

                    <div>
                        <InsightCard
                            titleIcon={<TbActivityHeartbeat size={13.5} color='var(--primary-color)' />}
                            title='24 changes'
                            price={(tickers?.last * (parseFloat(tickers?.price_change_percent) / 100)).toFixed(5)}
                            percentageChange={parseFloat(tickers?.price_change_percent)}
                            tooltipText='Check out the price value change over the last 24 hours'
                        />
                        <InsightCard
                            titleIcon={<FaRegClock color='var(--primary-color)' />}
                            title={`24 volume (${selectedMarket?.name?.split('/')[0]})`}
                            price={parseFloat(tickers?.amount).toFixed(4)}
                            percentageChange={-12}
                            tooltipText='See how much crypto was sold'
                        />
                        <InsightCard
                            titleIcon={<FaRegClock color='var(--primary-color)' />}
                            title={`24 volume (${selectedMarket?.name?.split('/')[1]})`}
                            price={parseFloat(tickers?.volume).toFixed(4)}
                            percentageChange={2.8}
                            tooltipText='Find out how much crypto was sold'
                        />
                    </div>

                </div>

                {/* right container */}
                <div className={Styles.rightContainer}>

                    {/* price header */}
                    <div>
                        <div>
                            {
                                memoizedLastTrade?.price ? (
                                    `$${formatNumber(memoizedLastTrade?.price)}`
                                ) : (
                                    <ShimmerLine 
                                        height='25px'
                                        width='100px'
                                    />
                                )
                            }
                            
                            <span style={{ color: selectedMarketDetails?.price_change_percent[0] === '+' ? 'var(--positive-color)' : 'var(--negative-color)' }}>
                                {selectedMarketDetails?.price_change_percent ?? 'Loading...'}
                                { selectedMarketDetails?.price_change_percent[0] === '+' ? <BuyArrowIcon width={12} /> : <SellArrowIcon width={12} /> }
                            </span>
                        </div>
                        <div>
                            {targetedMarket?.coin_name.charAt(0).toUpperCase() + targetedMarket?.coin_name.slice(1)} Price 24h
                            <Tooltip
                                text='Get the latest update on the current crypto price'
                            />
                        </div>
                    </div>

                    {/* time range selector */}
                    <div>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3
                            }}
                        >
                            <HighLowIcon width={11} style={{marginRight: 5}}/>
                            High/Low Price
                            <Tooltip
                                text='Discover the lowest and highest crypto prices in the last 24 hours'
                            />
                        </span>

                        <div style={{ backgroundColor: 'black', borderRadius: 3.5, width: 'fit-content' }}>
                            {/* <SelectInput
                                options={[
                                    { label: '24h', value: '24h' },
                                    { label: '1h', value: '1h' },
                                    { label: '30min', value: '30min' },
                                    { label: '15min', value: '15min' }
                                ]}
                                defaultValue={{ label: '24h', value: '24h' }}
                            /> */}
                        </div>

                    </div>

                    {/* bar container */}
                    <div>
                        {/* progress bar */}
                        <div style={{ width: (((memoizedLastTrade?.price - selectedMarketDetails?.low) / (selectedMarketDetails?.high - selectedMarketDetails?.low)) * 100) + "%" }}></div>
                        {/* <div style={{ width: "73%" }}></div> */}
                    </div>

                    {/* price bar info */}
                    <div>
                        <span>Low : ${formatNumber(selectedMarketDetails?.low) ?? "Loading..."}</span>
                        <span>High : ${formatNumber(selectedMarketDetails?.high) ?? "Loading..."}</span>
                    </div>
                    
                </div>


                {/* MODALS */}
                <WenbitModal
                    open={selectPairModalOpen}
                    title='PAIRS'
                    render={() => (
                        <MarketsListMobile
                            setCloseModal={setSelectPairModalOpen}
                        />
                    )}
                    onClose={() => setSelectPairModalOpen(false)}
                />

            </div>
    )
}

export default MarketInsight