// ** React Imports
import React, { useState, useEffect } from "react"

// ** React Router Imports
import { useHistory, useParams } from "react-router-dom"

// ** Styles Imports
import Styles from "./convert.module.css"

// ** Icons Imports
import { ReactComponent as LinkIcon } from "../../../assets/icons/imported/link.svg"
import { ReactComponent as ConvertIcon } from "../../../assets/icons/imported/convert.svg"

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux"
import { _getBalances } from "../../exchange/redux/actions"
import { setBalances } from "../../../redux/slices/balancesSlice"
import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from "../../modals/select-currency/redux/selectCurrencyModalSlice"
import { setConfirmConvertModalData } from "../../modals/convert/redux/confirmConvertModalSlice"
//other
import { estimateUnitValue } from "../../helpers/estimateValueBase"
import {
  _getCoinKline,
  _getCurrencies,
  _previewConversion
} from "../redux/actions"

// ** Third Party Imports
import { toast } from "react-toastify"
import { FormattedMessage, injectIntl } from "react-intl"

// ** Component Imports
import TableButton from "../../../components/TableButton/TableButton"
import CurrencyInput from "../../../components/CurrencyInput/CurrencyInput"
import Button from "../../../components/Button/Button"
import ConfirmConvertModal from "../../modals/convert/ConfirmConvertModal"
import SuccessConvertModal from "../../modals/convert/SuccessConvertModal"
import Alert from "../../../components/Alert/Alert"
import MarketGraphCard from "../../../components/MarketGraphCard/MarketGraphCard"

// ** Util Imports
import { showErrorSnackbar } from "../../snackbar/helpers"
import { checkCoinID } from "../../../utility/checkCoinID"

function Convert() {
  // ** Hooks
  const dispatch = useDispatch()
  const history = useHistory()
  document.title = "Wenbit: Convert"

  // ** Route Params
  const { marketId } = useParams()

  // ** Store States
  const markets = useSelector((state) => state.marketList.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)

  // ** States
  const [fromCurrency, setFromCurrency] = useState(
    marketId
      ? marketId.split("_")[0]?.toLowerCase()
      : markets.find((el) => el.id === marketId)?.base_unit &&
          markets.find((el) => el.id === selectedMarket.id)?.quote_unit
  )
  const [fromValue, setFromValue] = useState("")
  const [fromKlineArray, setFromKlineArray] = useState([])

  const [toCurrency, setToCurrency] = useState(
    marketId
      ? marketId.split("_")[1]?.toLowerCase()
      : markets.find((el) => el.id === marketId)?.quote_unit &&
          markets.find((el) => el.id === selectedMarket.id)?.base_unit
  )
  const [toValue, setToValue] = useState("")
  const [toKlineArray, setToKlineArray] = useState([])

  const [currencies, setCurrencies] = useState("")
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const balances = useSelector((state) => state.balances.value)
  const userData = useSelector((state) => state.userData?.value)

  const [confirmConvertModalOpen, setConfirmConvertModalOpen] = useState(false)
  const [successConvertModalOpen, setSuccessConvertModalOpen] = useState(
    useSelector((state) => state.successConvertModalState.open)
  )

  useEffect(() => {
    _getCurrencies(
      (data) => {
        setCurrencies(data)
      },
      (err) => {
        // console.log(err)
      }
    )
  }, [])

  useEffect(() => {
    // reset input values on currency change
    setFromValue("")
    setToValue("")

    _getBalances(
      (res) => {
        dispatch(setBalances(res))
      },
      () => {}
    )
  }, [fromCurrency, toCurrency])

  const handleFromValueChange = (event) => {
    setFromValue(event.target.value)
    setToValue("")
  }

  const handleToValueChange = (event) => {
    setToValue(event.target.value)
    setFromValue("")
  }

  const handleConvert = () => {
    const fromCurr =
      currencies.length && currencies?.find((el) => el.id === fromCurrency)
    const toCurr =
      currencies.length && currencies?.find((el) => el.id === toCurrency)
    if (
      fromValue !== "NaN" &&
      toValue !== "NaN" &&
      fromValue > 0 &&
      toValue > 0
    ) {
      _previewConversion(
        {
          from: fromCurr.id,
          to: toCurr.id,
          volume: fromValue
        },
        (data) => {
          dispatch(setConfirmConvertModalData(data))
          dispatch(setConfirmConvertModalOpen(true))
        },
        (error) => {
          // handleClose()
          showErrorSnackbar({
            alertMessage: error.errors[0].length ? (
              <FormattedMessage id={error.errors[0]} />
            ) : (
              "Unknown Error"
            )
          })
        }
      )
    } else {
      showErrorSnackbar({ alertMessage: "You should insert values first" })
      // dispatch(setSnackbarOpen(true))
      // dispatch(
      //   setSnackbarData({
      //     alertMessage: "You should insert values first",
      //     severity: "error",
      //     //buttonMessage: 'See order',
      //     callback: () => {},
      //     //actionType: 'link', // link - close
      //     //linkTo: '/account/orders/open',
      //     position: "topRight"
      //   })
      // )
    }
  }

  let convertedPrice = estimateUnitValue(
    fromValue ? toCurrency : fromCurrency,
    fromValue ? fromCurrency : toCurrency,
    parseFloat(fromValue || toValue),
    currencies,
    markets,
    marketTickers
  )
  useEffect(() => {
    convertedPrice = estimateUnitValue(
      fromValue ? toCurrency : fromCurrency,
      fromValue ? fromCurrency : toCurrency,
      parseFloat(fromValue || toValue),
      currencies,
      markets,
      marketTickers
    )
    if (!fromValue) {
      setFromValue(convertedPrice)
    } else if (!toValue) {
      setToValue(convertedPrice)
    }
  }, [fromValue])

  // get the kline data of coixns to convert to display in ApexChart
  useEffect(() => {
    _getCoinKline(
      {
        pairs: [
          fromCurrency !== "usd" ? `${fromCurrency}usd` : `${fromCurrency}usd`,
          toCurrency !== "usd" ? `${toCurrency}usd` : `${toCurrency}usd`
        ],
        limit: 15
      },
      (data) => {
        setFromKlineArray(
          data.find((el) => Object.keys(el)[0] === `${fromCurrency}usd`)
        )
        setToKlineArray(
          data.find((el) => Object.keys(el)[0] === `${toCurrency}usd`)
        )
      }
    )
  }, [fromCurrency, toCurrency])

  // if there was no market pair in url or invalid coin names
  useEffect(() => {
    // if no currency was defined
    if (
      marketId === undefined ||
      marketId === null ||
      marketId === "" ||
      !marketId.includes("_") ||
      marketId.split("_").length > 2
    ) {
      history.replace("/convert/BTC_USDT")
      setFromCurrency("btc")
      setToCurrency("usdt")
      // return
    }

    // if (marketId.includes("_")) {
    //   let id1 = ""
    //   let id2 = ""
    //   // check if id in URL is valid
    //   console.log(marketId.split("_")[0])

    //   checkCoinID(marketId.split("_")[0]).then((isValid) => {
    //     console.log('1', markets)
    //     if (isValid) {
    //       id1 = markets[0]?.base_unit
    //     } else {
    //       id1 = "BTC"
    //     }
    //     checkCoinID(marketId.split("_")[1]).then((isValid) => {
    //       console.log('2', markets[1])
    //       if (isValid) {
    //         id2 = markets[1]?.quote_unit
    //       } else {
    //         id2 = "USDT"
    //       }
    //       history.replace(`/convert/${id1.toUpperCase()}_${id2.toUpperCase()}`)
    //       setFromCurrency("btc")
    //       setToCurrency("usdt")
    //     })
    //   })
    // }
  }, [])

  const redirectToConvertHistory = (id) => {
    history.push(`/account/convert/history`)
  }

  return (
    <div className={Styles.pageContainer}>
      <div className={Styles.conversionContainer}>
        <h2>WenBit Convert</h2>
        <TableButton
          onClick={redirectToConvertHistory}
          buttonText="Conversion History"
          style={{
            marginTop: -20
            // backgroundColor: 'var(--secondary-color)'
          }}
          buttonIcon={
            <LinkIcon
              style={{
                width: 12
              }}
            />
          }
        />

        {/* from container */}
        <div className={Styles.inputContainer}>
          {
            // userData?.email &&
            // ([null, 0.0, undefined, '0.0', 0].includes(balances?.find(el => el.currency === fromCurrency)?.balance)) && (
            //   <Alert
            //   alertText={`Buy ${currencies && currencies?.find(el => el.id === fromCurrency?.toLowerCase())?.name} with credit card`}
            //   showButton={true}
            //   buttonText='Buy'
            //   />
            // )
          }

          <CurrencyInput
            title="From"
            containerStyle={{
              backgroundColor: "var(--secondary-color)",
              border: "none",
              margin:
                [null, 0.0, undefined, "0.0", 0].includes(
                  balances?.find((el) => el.currency === fromCurrency)?.balance
                ) && "10px 0"
            }}
            coinName={fromCurrency?.toUpperCase()}
            currencyIcon=""
            currencyImg={
              currencies
                ? currencies.find((el) => el.id === fromCurrency?.toLowerCase())
                    ?.icon_url
                : ""
            }
            secondaryTitle={
              userData.email &&
              `Balance: ${
                balances?.find((el) => el.currency === fromCurrency)?.balance ??
                "0.00"
              }`
            }
            type="number"
            value={
              fromValue || convertedPrice ? fromValue || convertedPrice : ""
            }
            placeholder="0.00"
            onChange={handleFromValueChange}
            showDropdownArrow={true}
            onDropdownClick={() => {
              dispatch(
                setSelectCurrencyModalData({
                  title: "Select Currency",
                  searchPlaceholder: "Search coin name",
                  showTabs: false,
                  type: "coin",
                  currenciesToHide: [toCurrency],
                  callback: (coin) => {
                    setFromCurrency(coin.id)
                    history.replace(
                      `/convert/${coin.id.toUpperCase()}_${toCurrency.toUpperCase()}`
                    )
                  }
                })
              )
              dispatch(setSelectCurrencyModalOpen(true))
            }}
          />

          <MarketGraphCard
            icon={
              currencies &&
              currencies?.find((el) => el.id === fromCurrency?.toLowerCase())
                ?.icon_url
            }
            coinName={
              currencies &&
              currencies?.find((el) => el.id === fromCurrency?.toLowerCase())
                ?.name
            }
            price={markets.find((el) => el.id === `${fromCurrency}usd`)?.last}
            priceChangePercentage={
              markets.find((el) => el.id === `${fromCurrency}usd`)
                ?.price_change_percent
            }
            containerStyle={{
              width: "100%"
            }}
            marketKLine={fromKlineArray}
          />
        </div>

        {/* CONVERT ICON */}
        <ConvertIcon
          color="var(--primary-color)"
          onClick={() => {
            setFromCurrency(toCurrency)
            setFromValue(toValue)
            setToCurrency(fromCurrency)
            setToValue(fromValue)
          }}
          style={{ transform: "scale(1.37)" }}
        />

        {/* to container */}
        <div className={Styles.inputContainer} style={{ paddingTop: 5 }}>
          <CurrencyInput
            title="To"
            containerStyle={{
              backgroundColor: "var(--secondary-color)",
              border: "none"
              // margin: '10px 0'
            }}
            secondaryTitle={
              userData.email &&
              `Balance: ${
                balances?.find((el) => el.currency === toCurrency)?.balance ??
                "0.00"
              }`
            }
            currencyIcon=""
            currencyImg={
              currencies
                ? currencies?.find((el) => el.id === toCurrency?.toLowerCase())
                    ?.icon_url
                : ""
            }
            coinName={toCurrency?.toUpperCase()}
            type="number"
            value={toValue || convertedPrice ? toValue || convertedPrice : ""}
            placeholder="0.00"
            onChange={handleToValueChange}
            showDropdownArrow={true}
            onDropdownClick={() => {
              dispatch(
                setSelectCurrencyModalData({
                  title: "Select Currency",
                  showTabs: false,
                  searchPlaceholder: "Search coin name",
                  type: "coin",
                  currenciesToHide: [fromCurrency],
                  callback: (coin) => {
                    setToCurrency(coin.id)
                    history.replace(
                      `/convert/${fromCurrency.toUpperCase()}_${coin.id.toUpperCase()}`
                    )
                  }
                })
              )
              dispatch(setSelectCurrencyModalOpen(true))
            }}
          />

          <MarketGraphCard
            icon={
              currencies &&
              currencies?.find((el) => el.id === toCurrency?.toLowerCase())
                ?.icon_url
            }
            coinName={
              currencies &&
              currencies?.find((el) => el.id === toCurrency?.toLowerCase())
                ?.name
            }
            containerStyle={{
              width: "100%"
            }}
            price={markets.find((el) => el.id === `${toCurrency}usd`)?.last}
            priceChangePercentage={
              markets.find((el) => el.id === `${toCurrency}usd`)
                ?.price_change_percent
            }
            marketKLine={toKlineArray}
          />
        </div>

        <Button
          type={!userData.email && "loggedOut"}
          style={
            !userData.email && {
              backgroundColor: "var(--secondary-color)",
              width: "100%"
            }
          }
          text="Preview Conversion"
          onClick={() => {
            handleConvert()
          }}
        />
      </div>

      {/* -------------- MODALS-------------- */}
      {confirmConvertModalOpen ? (
        <ConfirmConvertModal
          open={confirmConvertModalOpen}
          fromValue={fromValue}
          toValue={toValue}
          currencies={currencies}
          markets={markets}
          marketTickers={marketTickers}
          handleClose={() => setConfirmConvertModalOpen(false)}
          fromCurrency={
            currencies.length &&
            currencies?.find((el) => el.id === fromCurrency)
          }
          toCurrency={
            currencies.length && currencies?.find((el) => el.id === toCurrency)
          }
        />
      ) : null}
      {successConvertModalOpen ? (
        <SuccessConvertModal
          open={successConvertModalOpen}
          fromValue={fromValue}
          toValue={toValue}
          currencies={currencies}
          markets={markets}
          marketTickers={marketTickers}
          handleClose={() => setSuccessConvertModalOpen(false)}
          fromCurrency={
            currencies.length &&
            currencies?.find((el) => el.id === fromCurrency)
          }
          toCurrency={
            currencies.length && currencies?.find((el) => el.id === toCurrency)
          }
        />
      ) : null}
    </div>
  )
}

export default injectIntl(Convert)
