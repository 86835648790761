import React, { useEffect, useState } from "react"

// react router
import { useHistory, useLocation } from "react-router-dom"

// styles
import Styles from "./wallet.module.css"

// icons
import { ReactComponent as WalletIcon } from "../../../../assets/icons/imported/wallet.svg"
import { ReactComponent as MarketIcon } from "../../../../assets/icons/imported/market.svg"
import { ReactComponent as MenuIcon } from "../../../../assets/icons/imported/menu.svg"
import { ReactComponent as ConvertIcon } from "../../../../assets/icons/imported/convert.svg"
import { ReactComponent as LaunchpadIcon } from "../../../../assets/icons/imported/launchpad.svg"

// components
import SpotWalletTab from "../../components/SpotWallet/SpotWalletTab"
import OrderHistoryTab from "../../components/OrdersHistory/OrderHistoryTab"
import TradeHistoryTab from "../../components/TradesHistory/TradeHistoryTab"
import WalletHistoryTab from "../../components/WalletHistory/WalletHistoryTab"
import SideNavButton from "../../../../components/SideNavButton/SideNavButton"
import LaunchpadHistoryTab from "../../components/LaunchpadHistory/LaunchpadHistoryTab"
import ConversionHistoryTab from "../../components/ConversionHistory/ConversionHistoryTab"

// MUI imports
import WenbitModal from "../../../../components/Modal/Modal"
import GoBackHeader from "../../../../components/GoBackHeader/GoBackHeader"

function Wallet() {
  const location = useLocation()
  const history = useHistory()

  // modal state
  // const [walletDepositModalOpen, setWalletDepositModalOpen] = useState(false)
  // const [networkModalOpen, setNetworkModalOpen] = useState(false)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------

  const walletRoutes = [
    "/account/wallet/spot/all",
    "/account/wallet/spot/available",
    "/account/wallet/spot",
    "/account/wallet/history",
    "/account/orders/open",
    "/account/orders/history",
    "/account/trade/history",
    "/account/convert/history"
  ]

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const [currentTabTitle, setCurrentTabTitle] = useState("")
  useEffect(() => {
    switch (location.pathname) {
      case "/account/wallet/spot/all":
        setCurrentTabTitle("Spot Wallet")
        break
      case "/account/wallet/spot/available":
        setCurrentTabTitle("Spot Wallet")
        break
      case "/account/wallet/history":
        setCurrentTabTitle("Wallet History")
        break
      case "/account/orders/open":
        setCurrentTabTitle("Order History")
        break
      case "/account/orders/history":
        setCurrentTabTitle("Order History")
        break
      case "/account/trade/history":
        setCurrentTabTitle("Trade History")
        break
      case "/account/convert/history":
        setCurrentTabTitle("Conversion History")
        break
    }
  }, [location])

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (
      ["/account/wallet", "/account/wallet/"].includes(location.pathname) &&
      !isMobileView
    ) {
      history.replace("/account/wallet/spot/all")
    }
  }, [isMobileView])
  // ------------------------------------
  // ------------------------------------

  return (
    <>
      {isMobileView && !walletRoutes.includes(location.pathname) && (
        <h1 className={Styles.mobilePageTitle}>My account</h1>
      )}

      <div className={Styles.pageContainer}>
        {/* side nav container */}
        <div
          className={`${Styles.sideNavContainer} ${Styles.card}`}
          style={{
            // display: ((isMobileView && !profileRoutes.includes(location.pathname)) || (isMobileView && location.pathname === '/account')) && 'none'
            display:
              isMobileView && walletRoutes.includes(location.pathname) && "none"
          }}
        >
          <SideNavButton
            text="Spot wallet"
            icon={<WalletIcon width={18} />}
            style={{ justifyContent: "flex-start", width: "100%" }}
            isSelected={location.pathname.includes("/account/wallet/spot")}
            onClick={() => history.push("/account/wallet/spot/all")}
          />
          <SideNavButton
            text="Wallet history"
            icon={<WalletIcon width={18} />}
            style={{ justifyContent: "flex-start", width: "100%" }}
            isSelected={location.pathname === "/account/wallet/history"}
            onClick={() => history.push("/account/wallet/history")}
          />
          <SideNavButton
            text="Order history"
            icon={<MenuIcon width={20} />}
            style={{ justifyContent: "flex-start", width: "100%" }}
            isSelected={location.pathname.includes("/account/orders")}
            onClick={() => history.push("/account/orders/open")}
          />
          <SideNavButton
            text="Trade history"
            icon={<MarketIcon width={17} />}
            style={{ justifyContent: "flex-start", width: "100%" }}
            isSelected={location.pathname === "/account/trade/history"}
            onClick={() => history.push("/account/trade/history")}
          />
          <SideNavButton
            text="Conversion history"
            icon={<ConvertIcon width={17} />}
            style={{ justifyContent: "flex-start", width: "100%" }}
            isSelected={location.pathname === "/account/convert/history"}
            onClick={() => history.push("/account/convert/history")}
          />
          {/* <SideNavButton
                text='Launchpad History'
                icon={<LaunchpadIcon width={17}/>}
                style={{justifyContent: 'flex-start', width: '100%'}}
                isSelected={location.pathname === '/account/launchpad'}
                onClick={() => history.push('/account/launchpad')}
            /> */}
        </div>

        {/* main container */}
        <div
          className={Styles.mainContainer}
          style={{
            display:
              isMobileView &&
              !walletRoutes.includes(location.pathname) &&
              "none"
          }}
        >
          {/* {console.log(location.pathname)} */}
          {isMobileView && (
            <GoBackHeader
              title={currentTabTitle}
              onBackClick={() => history.push("/account/wallet")}
            />
          )}

          {location.pathname.includes("/account/wallet/spot") && (
            <SpotWalletTab />
          )}

          {location.pathname.includes("/account/orders") && <OrderHistoryTab />}

          {location.pathname === "/account/wallet/history" && (
            <WalletHistoryTab />
          )}
          {location.pathname === "/account/convert/history" && (
            <ConversionHistoryTab />
          )}
          {/* {
                location.pathname === '/account/launchpad' && (
                    <LaunchpadHistoryTab />
                )
            } */}
          {location.pathname === "/account/trade/history" && (
            <TradeHistoryTab />
          )}
        </div>
      </div>
    </>
  )
}

export default Wallet
