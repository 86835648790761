import React, { useEffect, useState } from "react"

// styles
import Styles from "./walletHistoryTab.module.css"

// icons
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/imported/down-arrow.svg"
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/imported/calendar.svg"
import { ReactComponent as FilterIcon } from "../../../../assets/icons/imported/filter.svg"
import deposited from "../../../../assets/icons/deposited.svg"
import withdrawn from "../../../../assets/icons/withdrawn.svg"

// components
import Search from "../../../../components/Search/Search"
import CoinTag from "../../../../components/CoinTag/CoinTag"

import { _getCurrencies } from "../../../exchange/redux/actions"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"
import {
  _getDepositHistory,
  _getWalletHistory,
  _getWithdrawalHistory
} from "../../redux/actions"
import { formatDateTime } from "../../../../utility/formatDateTime"
import { formatDate } from "../../../../utility/formatDate"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"
import { capitalize } from "@mui/material"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"

function HistoryTab() {
  const [testCurrencies, setTestCurrencies] = useState([])

  // table states
  const [page, setPage] = useState(1)
  const [tableLoading, setTableLoading] = useState(false)
  const [pageLimit, setPageLimit] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [walletHistoryArray, setWalletHistoryArray] = useState([])
  document.title = "Wenbit: Wallet History"

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 740)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 740)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const fetchHistory = () => {
    setTableLoading(true)
    _getWalletHistory(
      {
        page,
        pageLimit
      },
      (res) => {
        const dataArray = res.data
        dataArray.map((el, index) => ({
          id: index,
          ...el
        }))
        setTableLoading(false)
        setTotalRows(res.total)
        setWalletHistoryArray(dataArray)
        setTableLoading(false)
      },
      (err) => setTableLoading(false)
    )
  }
  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    fetchHistory()
  }, [page])

  useEffect(() => {
    _getCurrencies((data) => {
      setTestCurrencies(data)
    })
  }, [])

  const walletHistoryColumns = isMobileView
    ? [
        {
          flex: 1,
          field: "currency",
          headerName: "Coin",
          minWidth: 200,
          renderCell: (data) => (
            <MobileRowDatagrid
              rows={[
                {
                  title: "Action",
                  value: (
                    <span className={Styles.actionColumn}>
                      {data.row.type === "Deposit" ? "Deposited" : "Withdrawn"}
                    </span>
                  )
                },
                { title: "Amount", value: data.row.amount },
                {
                  title: "Date/Time",
                  value: <span>{formatDateTime(data.row?.created_at)}</span>
                },
                {
                  title: "Status",
                  value: (
                    <span
                      style={{
                        color: ["succeed", "accepted"].includes(data.row.state)
                          ? "var(--positive-color)"
                          : "var(--negative-color)",
                        textAlign: "center"
                      }}
                    >
                      {capitalize(data.row.state)}
                    </span>
                  )
                  // value: <CoinTag
                  //     tag={data.row.state}
                  //     size={12}
                  // style={{
                  //     color: ['succeed', 'accepted'].includes(data.row.state) ? (
                  //         'var(--positive-color)'
                  //     ) : ('var(--negative-color)'),
                  //     textAlign: 'center'
                  // }}
                  // />
                }
              ]}
              renderRowHeader={() => {
                return (
                  <CoinDisplay
                    coinImgURL={data.row.icon_url}
                    coinName={data.row.coin_name}
                    coinTag={data.row.currency.toUpperCase()}
                  />
                )
              }}
            />
          )
        }
      ]
    : [
        {
          flex: 1,
          field: "currency",
          headerName: "Coin",
          minWidth: 200,
          renderCell: (data) => {
            const currentCoin = testCurrencies.find(
              (el) => el?.id === data.row?.currency
            )
            return (
              <span className={Styles.coinColumn}>
                <img src={data.row.icon_url} />
                <span>{data.row.coin_name}</span>
                <CoinTag tag={data.row.currency.toUpperCase()} size={12} />
              </span>
            )
          }
        },
        {
          flex: 1,
          field: "fee",
          headerName: "Action",
          minWidth: 140,
          renderCell: (data) => {
            // const currentCoin = testCurrencies.find(el => el?.id === data.row?.currency)
            return (
              <span className={Styles.actionColumn}>
                <img
                  src={data.row.type === "Deposit" ? deposited : withdrawn}
                />
                {data.row.type}
              </span>
            )
          }
        },
        // {
        //     field: 'ip',
        //     headerName: 'IP Address',
        //     minWidth: 200,
        // }
        {
          flex: 1,
          field: "amount",
          headerName: "Amount",
          minWidth: 100,
          maxWidth: 140
        },
        {
          flex: 1,
          field: "completed_at",
          headerName: "Date/Time",
          minWidth: 140,
          renderCell: (data) => (
            <span style={{ color: "var(--gray-color)" }}>
              {formatDateTime(data.row?.created_at)}
            </span>
          )
        },
        {
          flex: 1,
          field: "state",
          headerName: "Status",
          minWidth: 80,
          maxWidth: 90,
          renderCell: (data) => (
            <span
              style={{
                color: ["succeed", "accepted", "collected"].includes(
                  data.row.state
                )
                  ? "var(--positive-color)"
                  : "var(--negative-color)"
              }}
            >
              {capitalize(data.row.state)}
            </span>
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Wallet History</h5>
          <span>
            It comes and goes in bits. Here’s all that’s come in and gone out.
          </span>
        </div>
        <Search placeholder="Search" />
      </div>

      <MUIDatagrid
        disableSorting
        page={page}
        loading={tableLoading}
        pageLimit={pageLimit}
        rows={walletHistoryArray}
        totalRowsCount={totalRows}
        onPageChange={handlePageChange}
        getRowId={(row) => row.created_at}
        columns={walletHistoryColumns}
        rowHeight={isMobileView && 180}
        hideHeader={isMobileView}
      />
    </div>
  )
}

export default HistoryTab
