// react
import React, { useEffect, useState } from "react"

// react router
import { useHistory } from "react-router-dom"

// redux
import { _getConversions } from "../../redux/actions"

// styles
import Styles from "./conversionHistoryTab.module.css"

// components
import ConversionFilters from "./ConversionFilters"
import Search from "../../../../components/Search/Search"
import CoinTag from "../../../../components/CoinTag/CoinTag"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"

// utils
import { isoStringToDate } from "../../../helpers/localeDate"

function ConversionHistoryTab() {
  const history = useHistory()
  document.title = "Wenbit: Conversion History"

  const [conversions, setConversions] = useState([])

  // table states
  const [tableLoading, setTableLoading] = useState(false)

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  const fetchConversions = () => {
    setTableLoading(true)
    _getConversions(
      (data) => {
        setTableLoading(false)
        setConversions(data)
      },
      (err) => setTableLoading(false)
    )
  }

  useEffect(() => {
    fetchConversions()
  }, [])

  const conversionHistoryColumns = isMobileView
    ? [
        {
          minWidth: 180,
          flex: 1,
          field: "",
          renderCell: (data) => (
            <MobileRowDatagrid
              renderRowHeader={() => (
                <CoinDisplay
                  coinImgURL={data.row.icon_url}
                  coinName={data.row.coin_name}
                  coinTag={`${data.row.base_unit.toUpperCase()}/${data.row.quote_unit.toUpperCase()}`}
                />
              )}
              rows={[
                {
                  title: "From",
                  value: (
                    <span>
                      {data.row.side === "buy"
                        ? data.row.total
                        : data.row.amount}{" "}
                      {data.row.side === "buy"
                        ? data.row.quote_unit.toUpperCase()
                        : data.row.base_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: "To",
                  value: (
                    <span
                      style={{ color: "var(--text-color)" }}
                      className={Styles.amountColumn}
                    >
                      {data.row.side === "buy"
                        ? data.row.amount
                        : data.row.total}{" "}
                      {data.row.side === "buy"
                        ? data.row.base_unit.toUpperCase()
                        : data.row.quote_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: "Conversion Rate",
                  value: (
                    <span>
                      1 {data.row.base_unit.toUpperCase()} = {data.row.price}{" "}
                      {data.row.quote_unit.toUpperCase()}
                    </span>
                  )
                },
                {
                  title: "Trade Date",
                  value: (
                    <span
                      className={Styles.dateColumn}
                      style={{ color: "var(--text-color)" }}
                    >
                      {isoStringToDate(data.row.created_at).date}{" "}
                      {isoStringToDate(data.row.created_at).time}
                    </span>
                  )
                },
                {
                  title: "Status",
                  value: (
                    <span style={{ color: "var(--positive-color)" }}>
                      {data.row.state.charAt(0).toUpperCase() +
                        data.row.state.slice(1)}
                    </span>
                  )
                }
              ]}
            />
          )
        }
      ]
    : [
        {
          minWidth: 180,
          field: "markets",
          headerName: "Pair",
          renderCell: (data) => (
            <span className={Styles.pairColumn}>
              <img src={data.row.icon_url} style={{ width: 27 }} />
              <span>{data.row.coin_name}</span>
              <CoinTag
                size={11}
                tag={`${data.row.base_unit.toUpperCase()}/${data.row.quote_unit.toUpperCase()}`}
              />
            </span>
          )
        },
        {
          minWidth: 120,
          field: "price",
          headerName: "Amount",
          renderCell: (data) => (
            <span>
              {data.row.side === "buy" ? data.row.total : data.row.amount}{" "}
              {data.row.side === "buy"
                ? data.row.quote_unit.toUpperCase()
                : data.row.base_unit.toUpperCase()}
            </span>
          )
        },
        {
          minWidth: 120,
          field: "amount",
          headerName: "Price",
          renderCell: (data) => (
            <span className={Styles.amountColumn}>
              {data.row.side === "buy" ? data.row.amount : data.row.total}{" "}
              {data.row.side === "buy"
                ? data.row.base_unit.toUpperCase()
                : data.row.quote_unit.toUpperCase()}
            </span>
          )
        },
        {
          minWidth: 185,
          headerName: "Conversion Rate",
          renderCell: (data) => (
            <span>
              1 {data.row.base_unit.toUpperCase()} = {data.row.price}{" "}
              {data.row.quote_unit.toUpperCase()}
            </span>
          )
        },
        {
          minWidth: 170,
          field: "done_at",
          headerName: "Trade Date",
          renderCell: (data) => (
            <span className={Styles.dateColumn}>
              {isoStringToDate(data.row.created_at).date}{" "}
              {isoStringToDate(data.row.created_at).time}
            </span>
          )
        },
        {
          minWidth: 50,
          field: "state",
          headerName: "Status",
          renderCell: (data) => (
            <span className={Styles.actionColumn}>
              {data.row.state === "done" ? (
                <span style={{ color: "var(--positive-color)" }}>Done</span>
              ) : (
                <span style={{ color: "var(--negative-color)" }}>Rejected</span>
              )}
            </span>
          )
        }
      ]

  return (
    // <div className={Styles.pageContainer}>
    <div className={Styles.tabContainer}>
      {/* tab header */}
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Conversion History</h5>
          <span>Check out all your previous converted bits.</span>
        </div>
        <Search placeholder="Search by date" />
      </div>

      <ConversionFilters />

      <MUIDatagrid
        disableSorting
        loading={tableLoading}
        rows={conversions}
        hideFooter={true}
        getRowId={(row) => row.id}
        hideHeader={isMobileView}
        columns={conversionHistoryColumns}
        rowHeight={isMobileView && 220}
      />
    </div>
    // </div>
  )
}

export default ConversionHistoryTab
