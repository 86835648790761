import React, { useRef, useState } from "react"

// styles
import Styles from "./checkboxInput.module.css"

// icons
import { ReactComponent as CheckboxIcon } from "../../assets/icons/imported/checkmark.svg"

function CheckboxInput({
  checkboxId,
  label,
  labelStyle,
  checkboxStyle,
  containerStyle,
  error,
  register, // for Yup form validation
  ...rest
}) {
  const inputRef = useRef(null)
  const [checked, setChecked] = useState(false)

  const handleCheckboxClick = (event) => {
    setChecked(event.target.checked)
  }

  return (
    <div
      className={Styles.container}
      style={{ ...containerStyle }}
      onClick={handleCheckboxClick}
    >
      <label
        htmlFor={checkboxId}
        className={Styles.label}
        style={{ ...labelStyle }}
      >
        <input
          id={checkboxId}
          name={checkboxId}
          type="checkbox"
          className={Styles.input}
          ref={inputRef}
          {...register}
        />
        <div className={Styles.checkbox} style={{ ...checkboxStyle }}>
          {checked && <CheckboxIcon width={13} color="white" />}
        </div>
        <div>{label}</div>
      </label> 

      <span className={Styles.errorSpan}>
        {error?.message ? error.message : " "}
      </span>
    </div>
  )
}

export default CheckboxInput
