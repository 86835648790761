import {
  clearOrderBook,
  setOrderBookLoad
} from "./modules/exchange/redux/slices/orderBookSlice"
import { addOrder } from "./modules/exchange/redux/slices/openOrdersSlice"
import {
  addTrade,
  changeTrade
} from "./modules/exchange/redux/slices/tradesSlice"
import {
  setSnackbarOpen,
  setSnackbarData
} from "./modules/snackbar/redux/snackbarSlice"
// packages
import ReconnectingWebSocket from "reconnecting-websocket"
import { startWebSocketConnection } from "./redux/slices/webSocketSlice"
import { configureStore } from "@reduxjs/toolkit"
import React, { Component, useEffect, useState, useRef } from "react"
import { BrowserRouter, Route, useLocation, useParams } from "react-router-dom"
import { ThemeProvider } from "./context/ThemeContext"
import Index from "./pages"
// import { Provider } from 'react-redux'
import { store, persistor } from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { toggleTheme } from "./redux/slices/themeSlice"
import { useDispatch, useSelector, Provider } from "react-redux"
import { FormattedMessage, injectIntl, IntlProvider } from "react-intl"
import { languageMap } from "./translations"
import { ToastContainer } from "react-toastify"
import languageReducer from "./redux/slices/languageSlice"
import { _getUserInfo, _getFundsData } from "./redux/actions"
import { setUser, logoutUser } from "./modules/user/redux/slices/userDataSlice"
import { setMarketsList } from "./modules/exchange/redux/slices/marketListSlice"
import {
  setFunds,
  setWithdrawals,
  setBtcBalance,
  setDeposits
} from "./redux/slices/fundsSlice"
import { clearBalances } from "./redux/slices/balancesSlice"
import { setCurrencies } from "./modules/exchange/redux/slices/currenciesSlice"

import {
  _getCurrencies,
  _getMarketsList,
  _getMarketTickers,
  _getMarketTrades,
  _getBalances,
  _getOpenOrders
} from "./modules/exchange/redux/actions"
import { formatNumber } from "./modules/helpers/formatNumber"

// MODALS
import KYCReminderModal from "./modules/modals/kyc/KYCReminderModal"
// ----------------
import InformationModal from "./modules/modals/information/informationModal"
import { setInformationModalOpen } from "./modules/modals/information/redux/informationModalSlice"
// ----------------
import SuccessModal from "./modules/modals/success/successModal"
import { setSuccessModalOpen } from "./modules/modals/success/redux/successModalSlice"
// ----------------
import DangerModal from "./modules/modals/danger/DangerModal"
import { setDangerModalOpen } from "./modules/modals/danger/redux/dangerModalSlice"
// ----------------
import WarningModal from "./modules/modals/warning/WarningModal"
import { setWarningModalOpen } from "./modules/modals/warning/redux/warningModalSlice"
// ----------------
import SelectCurrencyModal from "./modules/modals/select-currency/SelectCurrencyModal"
// ----------------
import ViewBeneficiaryModal from "./modules/modals/beneficiaries/view/ViewBeneficiaryModal"
import CreateBeneficiaryModal from "./modules/modals/beneficiaries/create/CreateBeneficiaryModal"
import DeleteBeneficiaryModal from "./modules/modals/beneficiaries/delete/DeleteBeneficiaryModal"
// ----------------
import ActionsBeneficiaryModal from "./modules/modals/beneficiaries/actions/ActionsBeneficiaryModal"
import ActivateBeneficiaryModal from "./modules/modals/beneficiaries/activate/ActivateBeneficiaryModal"
import SecurityVerificationModal from "./modules/modals/security-verification/SecurityVerificationModal"
import EnableSMSAuthModal from "./modules/modals/security/EnableSMSAuthModal/EnableSMSAuthModal"
import DisableSMSAuthModal from "./modules/modals/security/DisableSMSAuthModal/DisableSMSAuthModal"
import ModifySMSAuthModal from "./modules/modals/security/ModifySMSAuthModal/ModifySMSAuthModal"
import SelectAvatarModal from "./modules/modals/avatar/SelectAvatarModal"
import Enable2FAModal from "./modules/modals/security/Enable2FAModal/Enable2FAModal"
import LanguageCurrencySelectModal from "./modules/modals/language-currency-select/LanguageCurrencySelectModal"
import Disable2FAModal from "./modules/modals/security/Disable2FAModal/Disable2FAModal"
import SelectBeneficiaryModal from "./modules/modals/beneficiaries/select/SelectBeneficiaryModal"
import { showKYCReminderModal } from "./modules/modals/kyc/helpers"
import VerificationCodesInput from "./modules/modals/auth/VerificationCodesInput"

import Snackbar from "./modules/snackbar/Snackbar"
import { setLoggedIn } from "./redux/slices/loggedIn"
import WalletLoadingModal from "./modules/modals/wallet-loading/WalletLoadingModal"
// GLOBAL STYLING
import "./assets/css/nprogress.css"
import "./assets/css/muiDrawer.css"
import "./assets/css/rc-slider.css"
import "./assets/css/muiSelect.css"
import "./assets/css/muiModals.css"
import "./assets/css/muiTooltip.css"
import "./assets/css/reactSlick.css"
import "./assets/css/muiCircularProgress.css"
import "./components/MUIDatagrid/muiDatagrid.css"
import "./components/Autocomplete/muiAutocomplete.css"
// import { openWebSocketConnection } from './websocketSaga'

//MUI
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { setBackdropOpen } from "./redux/slices/backdropSlice"
import "./components/MUISwitch/muiSwitch.css"
import SelectNetworkModal from "./modules/modals/deposit-withdraw/select-network/SelectNetworkModal"
import WalletDepositAddressModal from "./modules/modals/deposit-withdraw/wallet-deposit-address/WalletDepositAddressModal"
import WithdrawFormModal from "./modules/modals/deposit-withdraw/withdraw-form"
import SuccessConvertModal from "./modules/modals/convert/SuccessConvertModal"
import DatePickerWrapper from "./components/DateRangePicker/DatePickerWrapper"
import DrawerNavigation from "./components/DrawerNavigation/DrawerNavigation"

import {
  subscribeToMarket,
  setMarketTickers
} from "./modules/exchange/redux/slices/marketTickersSlice"

function ScrollToTopOnLocationChange() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  // const themeColor = useSelector((state) => state.themeColor?.value)
  const lang = useSelector((state) => state.language.code)

  // theme
  const theme = useSelector((state) => state.theme.value)
  const SUBSCRIBE_TO_PUBLIC_MARKET = "SUBSCRIBE_TO_PUBLIC_MARKET"
  const CLOSE_WEBSOCKET = "CLOSE_WEBSOCKET"
  const OPEN_PRIVATE_CONNECTION = "OPEN_PRIVATE_CONNECTION"
  const OPEN_PUBLIC_CONNECTION = "OPEN_PUBLIC_CONNECTION"
  const CLOSE_PRIVATE_WEBSOCKET = "CLOSE_PRIVATE_WEBSOCKET"
  const dispatch = useDispatch()
  //const location = useLocation()
  // dispatch(setThemeColor(theme))

  // backdrop
  const backdropOpen = useSelector((state) => state.backdrop.open)
  const userData = useSelector((state) => state.userData.value)
  const hasDocumentsLabel = userData?.labels?.some(
    (label) => label.key === "document"
  )

  // SNACKBAR STATE
  const snackbarOpen = useSelector((state) => state.snackbarState.open)

  // MODALS STATES
  const [KYCReminderOpen, setKYCReminderOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const marketsList = useSelector((state) => state.marketList?.value)
  // const tickers = useSelector((state) => state.marketTickers?.value)
  const selectAvatarModalStateOpen = useSelector(
    (state) => state.selectAvatarModalState.open
  )

  const informationModalState = useSelector(
    (state) => state.informationModalState.open
  )
  const loggedIn = useSelector((state) => state.loggedIn.value)
  const successModalState = useSelector((state) => state.successModalState.open)
  const successConvertModalState = useSelector(
    (state) => state.successConvertModalState.open
  )
  const dangerModalState = useSelector((state) => state.dangerModalState.open)
  const warningModalState = useSelector((state) => state.warningModalState.open)

  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const selectedMarketRef = useRef(selectedMarket)
  const previousMarket = useSelector((state) => state.previousMarket.value)
  const loggedInState = useRef(loggedIn)
  const defaultTicker = {
    last: 0,
    vol: 0,
    price_change_percent: "+0.00%"
  }

  useEffect(() => {
    // Connect to the private WebSocket connection if logged in
    if (loggedInState.current && loggedInState.current !== loggedIn) {
      dispatch({ type: CLOSE_PRIVATE_WEBSOCKET })
    }
    if (loggedIn) {
      dispatch({ type: OPEN_PRIVATE_CONNECTION })
    } else {
      // Connect to the public WebSocket connection if logged out
      dispatch({ type: CLOSE_PRIVATE_WEBSOCKET })
    }

    loggedInState.current = loggedIn
  }, [loggedIn])

  useEffect(() => {
    dispatch(clearOrderBook())
    dispatch({ type: SUBSCRIBE_TO_PUBLIC_MARKET })
  }, [dispatch, previousMarket, selectedMarket])

  useEffect(() => {
    _getMarketsList((markets) => {
      _getMarketTickers((tickers) => {
        let marketsMapped = markets?.map((market) => {
          return {
            ...market,
            last: (tickers[market.id] || defaultTicker).ticker.last,
            low: (tickers[market.id] || defaultTicker).ticker.low,
            high: (tickers[market.id] || defaultTicker).ticker.high,
            price_change_percent: (tickers[market.id] || defaultTicker).ticker
              .price_change_percent,
            price_change_percent_num: Number.parseFloat(
              (tickers[market.id] || defaultTicker).ticker.price_change_percent
            ),
            vol: (tickers[market.id] || defaultTicker).ticker.volume
          }
        })
        _getCurrencies((currenciesArray) => {
          dispatch(setCurrencies(currenciesArray))
          marketsMapped = marketsMapped.map((market) => {
            const targetedMarket = currenciesArray.find(
              (el) => market.base_unit?.toLowerCase() === el.id?.toLowerCase()
            )
            return {
              ...market,
              icon_url: targetedMarket.icon_url,
              coin_name: targetedMarket.name
            }
          })
          dispatch(setMarketsList(marketsMapped))
        })
      })
    })
  }, [])

  // CHECK USER SESSION AND EXTRA INFO
  const getUserInfo = () => {
    setLoading(true)
    dispatch(setBackdropOpen(true))
    _getUserInfo(
      (data) => {
        dispatch(setBackdropOpen(false))
        dispatch(setLoggedIn(true))
        dispatch(setUser(data))
        setLoading(false)
        if (data.errors) {
          dispatch(logoutUser())
          dispatch(setLoggedIn(false))
          dispatch(clearBalances())
          setLoading(false)
        }
        _getFundsData(
          (data) => {
            dispatch(setWithdrawals(data.total_withdraw))
            dispatch(setDeposits(data.total_deposit))
            dispatch(setFunds(data.total_balance))
            dispatch(setBtcBalance(data.total_balance_btc))
          },
          (error) => {
            console.log(error)
          }
        )
      },
      (error) => {
        setLoading(false)
        dispatch(setBackdropOpen(false))
        if (error?.errors) {
          dispatch(logoutUser())
          dispatch(clearBalances())
          dispatch(setLoggedIn(false))
        }
      }
    )
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  // KYC CHECK
  useEffect(() => {
    if (!userData) {
      return
    } else if (!userData?.labels?.some((label) => label.key === "document")) {
      return
    } else if (
      !(
        userData?.labels?.find((el) => el.key === "document")?.value ===
        "verified"
      )
    ) {
      return
    }

    showKYCReminderModal()
  }, [])

  // theme useEffect
  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.remove("light")
      document.body.classList.add("dark")
    } else {
      document.body.classList.remove("dark")
      document.body.classList.add("light")
    }
    // document.body.classList.remove('light')
    // document.body.classList.remove('dark')
    // document.body.classList.add(theme)
  }, [theme])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <IntlProvider locale="en" messages={languageMap[lang]}>
          <BrowserRouter>
            <ScrollToTopOnLocationChange />
            <DatePickerWrapper>
              {!loading && <Index />}
              <ToastContainer newestOnTop />

              {/* BACKDROP */}
              <Backdrop
                sx={{
                  color: "var(--primary-color)",
                  zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={backdropOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {/* ------------SNACKBAR------------ */}
              {snackbarOpen && <Snackbar />}

              {/* ------------MODALS------------ */}
              {
                // userData.email && !hasDocumentsLabel &&
                <KYCReminderModal />
              }

              <InformationModal
                open={informationModalState}
                handleClose={() => {
                  dispatch(setInformationModalOpen(false))
                }}
              />
              <SuccessModal
                open={successModalState}
                handleClose={() => {
                  dispatch(setSuccessModalOpen(false))
                }}
              />
              <DangerModal
                open={dangerModalState}
                handleClose={() => {
                  dispatch(setDangerModalOpen(false))
                }}
              />
              <WarningModal
                open={warningModalState}
                handleClose={() => {
                  dispatch(setWarningModalOpen(false))
                }}
              />

              <SelectCurrencyModal />

              {/* DEPOSIT/WITHDRAW MODALS */}
              <SelectNetworkModal />
              <WalletDepositAddressModal />
              <WithdrawFormModal />
              <WalletLoadingModal />

              {/* SECURITY MODALS */}
              <Enable2FAModal />
              <VerificationCodesInput />
              <Disable2FAModal />
              <ModifySMSAuthModal />
              <EnableSMSAuthModal />
              <DisableSMSAuthModal />

              {/* CONVERT MODALS */}
              {successConvertModalState && (
                <SuccessConvertModal modalOpen={successConvertModalState} />
              )}

              {/* BENEFICIARIES MODALS */}
              <ViewBeneficiaryModal />
              <SelectBeneficiaryModal />
              <CreateBeneficiaryModal />
              <DeleteBeneficiaryModal />
              <ActivateBeneficiaryModal />
              <ActionsBeneficiaryModal />

              {/* SECURITY VERIFICATION MODALS */}
              <SecurityVerificationModal />

              {/* AVATAR MODALS */}
              {selectAvatarModalStateOpen && <SelectAvatarModal />}

              {/* SELECT LANGUAGE/CURRENCY */}
              <LanguageCurrencySelectModal />
            </DatePickerWrapper>
          </BrowserRouter>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
