import { store } from '../../../redux/store'
import { periodStringToMinutes } from '../utility/helpers'

// We initialize the native WebSocket with the given URL
var socket_url = 'wss://exchange-stage.wenbit.com/api/v2/ws/public'
var socket = new WebSocket(socket_url)

// Keep track of subscriptions
var _subs = []
var resolution_var = null
var symbol = null
export default {
    subscribeBars: (symbolInfo, resolution, updateCb, uid, resetCache) => {
        resolution_var = resolution
        symbol = symbolInfo
        const channelString = createChannelString(symbolInfo)
        socket.send(JSON.stringify({ event: 'subscribe', streams: [channelString] }))
        const lastBarFromStore = store.getState().kline.last
        var newSub = {
            channelString,
            uid,
            resolution,
            symbolInfo,
            lastBar: lastBarFromStore,
            listener: updateCb,
        }
        _subs.push(newSub)
    },

    unsubscribeBars: (uid) => {
        var subIndex = _subs.findIndex(e => e.uid === uid)
        // if (subIndex === -1) {
        //     return
        // }
        var sub = _subs[subIndex]
        socket.send(JSON.stringify({ event: 'unsubscribe', streams: [sub.channelString] }))
        _subs.splice(subIndex, 1)
    }
}

// WebSocket listeners
socket.addEventListener('open', function (event) {
    // console.log('===Socket connected')
})

socket.addEventListener('message', function (event) {
    const _dataRaw = event.data
    const _data = JSON.parse(_dataRaw)


    const valuesArray = _data[`${symbol?.full_name}.kline-${resolution_var}`]
    if (valuesArray) {
        const [
            ts,        // timestamp
            openPrice, // opening price
            highPrice, // highest price
            lowPrice,  // lowest price
            closePrice, // closing price
            volume     // trade volume
        ] = valuesArray

        if (_data[0] === "3") {
            return
        }

        const data = {
            ts,
            openPrice,
            highPrice,
            lowPrice,
            closePrice,
            volume
        }

        const channelString = `${symbol.full_name}.kline-${resolution_var}`
        const sub = _subs.find(e => e.channelString === channelString)

        if (sub) {
            if (data.ts < sub.lastBar.time / 1000) {
                return
            }
            var _lastBar = updateBar(data, sub)
            sub.listener(_lastBar)
            sub.lastBar = _lastBar
        }
    }
})

socket.addEventListener('error', function (event) {
    // console.log('====socket error', event)
})

socket.addEventListener('close', function (event) {
    // console.log('===Socket disconnected:', event)
})

function updateBar(data, sub) {
    var lastBar = sub.lastBar
    if (typeof lastBar !== 'object') {
        lastBar = {
            time: data.ts * 1000, // Or some other default value
            open: data.price, // Or some other default value
            high: data.price, // Or some other default value
            low: data.price, // Or some other default value
            close: data.price, // Or some other default value
            volume: 0 // Default value
        }
    }

    const resolution = sub.resolution
    // if (resolution.includes('D')) {
    //     resolution = 1440
    // } else if (resolution.includes('W')) {
    //     resolution = 10080
    // }
    var coeff = periodStringToMinutes(resolution) * 60
    var rounded = Math.floor(data.ts / coeff) * coeff
    var lastBarSec = lastBar.time / 1000
    var _lastBar

    if (rounded > lastBarSec) {
        _lastBar = {
            time: rounded * 1000,
            open: lastBar.close,
            high: lastBar.close,
            low: lastBar.close,
            close: data.price,
            volume: data.volume
        }
    } else {
        if (data.price < lastBar.low) {
            lastBar.low = data.price
        } else if (data.price > lastBar.high) {
            lastBar.high = data.price
        }
        lastBar.volume += data.volume
        lastBar.close = data.price
        _lastBar = lastBar
    }
    return _lastBar
}

function createChannelString(symbolInfo) {
    var channel = symbolInfo.full_name
    const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
    const to = channel[2]
    const from = channel[1]
    return `${channel}.kline-${resolution_var}`
}
