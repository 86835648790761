import React, { useMemo, useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { IoMdArrowDropup } from "react-icons/io"
import Styles from "./orderbook.module.css"
import UpWhiteArrow from "../../../../assets/icons/up-white-arrow.svg"
import OrderbookRow from "./OrderbookRow"
import ShimmerLine from "../../../../components/shimmer-components/ShimmerLine"
import { useDispatch, useSelector } from "react-redux"
import {
  setLastTrade,
  clearOrderBook,
  setOrderBookLoad
} from "../../redux/slices"
import { formatNumber } from "../../../helpers/formatNumber"
import { accumulateVolume } from "./helpers/accumulateVolume"
import { calcMaxVolume } from "./helpers/calcMaxVolume"

function Orderbook() {
  const dispatch = useDispatch()
  const lastTrade = useSelector((state) => state.trades.value[0])

  const orderBooks = useSelector((state) => state.orderBook.value)
  const orderBookLoad = useSelector((state) => state.orderBook.load)
  const memoizedOrderBooks = useMemo(() => orderBooks, [orderBooks])
  const memoizedLastTrade = useMemo(() => lastTrade, [lastTrade])
  const marketsList = useSelector((state) => state.marketList.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const [asks, setAsks] = useState([])
  const [bids, setBids] = useState([])

  useEffect(() => {
    if (orderBooks.length) {
      // dispatch(setOrderBookLoad(false))
      const newBids = orderBooks.reduce((acc, curr) => {
        if (curr.bids) {
          acc.push(curr.bids)
        }
        return acc
      }, [])
      setBids(newBids)
      const newAsks = orderBooks.reduce((acc, curr) => {
        if (curr.asks) {
          acc.push(curr.asks)
        }
        return acc
      }, [])
      setAsks(newAsks)
    } else {
      // dispatch(setOrderBookLoad(false))
      setBids([])
      setAsks([])
    }
  }, [orderBooks])

  useEffect(() => {
    dispatch(setLastTrade(lastTrade))
    const intervalId = setInterval(() => {
      dispatch(setLastTrade(lastTrade))
    }, 2 * 60 * 1000) // 2 minutes in milliseconds
    return () => clearInterval(intervalId)
  }, [dispatch, lastTrade])

  const handleRowWidth = (data, index) => {
    const mapValues = (maxVolume, data) => {
      const resultData =
        data && maxVolume && data.length
          ? data.map((currentVolume) => {
              return {
                value: (currentVolume / maxVolume) * 100
              }
            })
          : []
      return resultData.sort((a, b) => a.value - b.value)
    }
    if (bids && asks) {
      const resultData = mapValues(
        calcMaxVolume(bids, asks),
        accumulateVolume(data)
      )
      if (resultData && resultData.length) return resultData[index].value
    }
  }

  return (
    <div className={Styles.card}>
      {asks && bids && (
        <>
          {/* title */}
          <span className={Styles.cardTitle}>
            <FormattedMessage id={"page.body.trade.orderbook"} />
          </span>
          {/* sorting row */}
          <div className={Styles.sortingContainer}>
            <span>
              Price (
              {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.quote_unit.toUpperCase()}
              )
            </span>
            <span>
              Amount (
              {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.base_unit.toUpperCase()}
              )
            </span>
            <span>
              Total (
              {marketsList
                .find((el) => el.id === selectedMarket.id)
                ?.name.toUpperCase()}
              )
            </span>
          </div>
        </>
      )}

      {/* asks */}
      <div className={Styles.asksContainer}>
        {!orderBookLoad
          ? asks
              .slice(0, 30)
              .map((order, i) => (
                <OrderbookRow
                  key={i}
                  isAsk={true}
                  order={order}
                  total={handleRowWidth(asks, asks.length - i - 1)}
                />
              ))
          : new Array(10)
              .fill(0)
              .map((_, i) => (
                <ShimmerLine key={i} width="100%" height="24px" />
              ))}
      </div>

      {/* last trade */}
      {memoizedLastTrade ? (
        <div
          className={Styles.lastTrade}
          style={{
            backgroundColor:
              memoizedLastTrade.taker_type === "buy"
                ? "var(--negative-color)"
                : "var(--positive-color)"
          }}
        >
          $ {formatNumber(memoizedLastTrade?.price)}
          <img
            src={UpWhiteArrow}
            style={{
              transform:
                memoizedLastTrade.taker_type === "buy" &&
                "rotateY(180deg) rotateZ(90deg) rotateX(180deg)"
            }}
          />
        </div>
      ) : (
        <ShimmerLine style={{ margin: "10px" }} width="100%" height="45px" />
      )}

      {/* bids */}
      <div className={Styles.bidsContainer}>
        {!orderBookLoad
          ? bids
              .slice(0, 30)
              .map((order, i) => (
                <OrderbookRow
                  key={i}
                  isAsk={false}
                  order={order}
                  total={handleRowWidth(bids, bids.length - i - 1)}
                />
              ))
          : new Array(10)
              .fill(0)
              .map((_, i) => (
                <ShimmerLine key={i} width="100%" height="24px" />
              ))}
      </div>
    </div>
  )
}

export default injectIntl(Orderbook)
