// ** React Imports
import React, { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"

// ** Hooks Imports
import { useHistory } from "react-router-dom"

// ** Styles Imports
import Styles from "./yourBitMatters.module.css"

// ** Components Imports
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import PageHeader from "../../components/PageHeader/PageHeader"

// form validations
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// ** Actions Imports
import { _addForm, _getRequestTypes } from "./redux/actions"

// ** Utils Imports
import { showErrorSnackbar, showSuccessSnackbar } from "../snackbar/helpers"
import { useSelector } from "react-redux"

// login schema
const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  fullName: Yup.string().required("You full name is required"),
  requestType: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Your phone number is required"),
  comments: Yup.string().required("Message is required")
})

function YourBitMatters() {
  // ** Hooks
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit"
  })
  const history = useHistory()

  // ** States
  const [requestTypes, setRequestTypes] = useState([])
  const [recaptchaConfirmed, setRecaptchaConfirmed] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState("")
  const [requestType, setRequestType] = useState(null)

  const theme = useSelector((state) => state.theme.value)
  document.title = "Wenbit: Support"

  useEffect(() => {
    _getRequestTypes(
      (data) => setRequestTypes(data.data),
      () => showErrorSnackbar({ alertMessage: "Something went wrong" })
    )
  }, [])

  const recaptchaOnChange = (value) => {
    setRecaptchaConfirmed(true), setRecaptchaResponse(value)
  }

  const onSubmit = (formData) => {
    _addForm(
      {
        full_name: formData.fullName,
        email: formData.email,
        request_id: requestType,
        phone_number: formData.phoneNumber,
        comment: formData.comments
      },
      () => {
        showSuccessSnackbar({ alertMessage: "Form submitted" })
        history.push("/")
      },
      () => {
        showErrorSnackbar({ alertMessage: "Something went wrong" })
      }
    )
  }

  return (
    <div className={Styles.pageContainer}>
      <PageHeader
        title="Your bit matters"
        sectionTitle="Support"
        description={
          <>
            We're in this together, and that means open communication is
            everything. We want to hear from you - your questions, your ideas,
            and your thoughts on our exchange. Because at WenBit, we're not just
            building a platform but a community. So, speak up and let your voice
            be heard. Together, we'll conquer the crypto world.
          </>
        }
      />

      <form className={Styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={Styles.row}>
          <Input
            inputLabel="Full Name"
            variant="secondary"
            placeholder="Alex Johnson"
            {...register("fullName")}
            error={errors.fullName}
          />
          <Input
            inputLabel="Email"
            variant="secondary"
            placeholder="example@mail.com"
            {...register("email")}
            error={errors.email}
          />
        </div>
        <div className={Styles.row}>
          <Input
            type="select"
            inputLabel="Type of request"
            placeholder="Select a type"
            selectOptions={requestTypes.map((el) => ({
              label: el.request_type,
              value: el.id
            }))}
            error={errors.requestType}
            onChange={(e, value) => {
              setValue("requestType", value)
              setRequestType(value)
            }}
            selectValue={requestType}
          />
          <Input
            inputLabel="Phone Number"
            type="phoneNumber"
            inputIconContainerStyle={{
              backgroundColor: "var(--background-color)"
            }}
            register={{ ...register("phoneNumber") }}
            error={errors.phoneNumber}
          />
        </div>
        <Input
          type="textarea"
          inputLabel="Comments"
          variant="secondary"
          placeholder="Speak your mind"
          {...register("comments")}
          error={errors.comments}
        />

        <ReCAPTCHA
          key={theme}
          theme={theme}
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={recaptchaOnChange}
          style={{ margin: "0 auto 20px auto", width: "fit-content" }}
        />

        <Button
          type="submit"
          text="Submit"
          disabled={!recaptchaConfirmed}
          style={{ width: 220, margin: "0 auto" }}
        />
      </form>
    </div>
  )
}

export default YourBitMatters
