import React, { forwardRef } from "react"

// styles
import Styles from "./autocomplete.module.css"

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/imported/search.svg"

// MUI
import {
  Autocomplete as AutocompleteMUI,
  CircularProgress,
  TextField
} from "@mui/material"

const Autocomplete = forwardRef(
  (
    {
      options = [],
      getOptionLabel,
      renderOption = (params, option) => (
        <div {...params} id={option.key}>
          {getOptionLabel(option)}
        </div>
      ),
      renderInput,
      placeholder,
      label,
      onChange,
      searchContainerIcon,
      containerStyle,
      loading = false,
      inputValue,
      onInputChange
      // ...rest
    },
    ref
  ) => {
    return (
      <div style={{ ...containerStyle }}>
        {label && <span className={Styles.label}>{label}</span>}
        <div
          className={Styles.inputContainer}
          style={{
            paddingRight: searchContainerIcon && 10
          }}
        >
          <AutocompleteMUI
            options={options}
            onChange={onChange}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={onInputChange}
                inputRef={ref}
                placeholder={placeholder}
              />
            )}
            style={{
              width: "100%"
            }}
            inputValue={inputValue}
            noOptionsText={
              loading ? (
                <div className={Styles.loadingContainer}>
                  <CircularProgress
                    style={{
                      margin: "10px auto",
                      color: "var(--primary-color)"
                    }}
                  />
                </div>
              ) : (
                <span>No options</span>
              )
            }
            // {...rest}
          />
          {searchContainerIcon && searchContainerIcon}
        </div>
      </div>
    )
  }
)
Autocomplete.displayName = "Autocomplete"

export default Autocomplete
