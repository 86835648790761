import React, { useRef } from 'react'

// styles imports
import Styles from './MarketGraphCard.module.css'

// images/icons imports
import UpArrow from './up-arrow.svg'
import DownArrow from './down-arrow.svg'

// graph photo import
import AreaChart from '../AreaChart/AreaChart'

// redux
import { useSelector } from 'react-redux'

function MarketGraphCard({
  icon = null,
  coinName,
  price,
  priceChangePercentage = 0,
  containerStyle,
  marketKLine = {},
  pairId // ex: btcusdt,
}) {

  const theme = useSelector(state => state.theme.value)

  const convertKLineDataToArray = (arr) => {
    return arr?.map(subArr => subArr[2])
  }

  const coinNameContainerRef = useRef(null)

  return (
    <div
      className={Styles.cardContainer}
      style={{
        backgroundColor: theme === 'light' && 'white',
        ...containerStyle
      }}
    >

      {/* info container */}
      <div className={Styles.infoContainer}>
        
        {/* icon + name container */}
        <div ref={coinNameContainerRef} className={Styles.coinNameContainer}>
          {icon && <img src={icon}/>}
          <span
            style={{
              fontSize: 12.25
            }}
          >{coinName}</span>
        </div>

        {/* details container */}
        <div className={Styles.detailsContainer}>
          {price && (<span className={Styles.coinPrice}>{price}</span>)}
          <div className={Styles.changePercentageContainer}>
            {
              priceChangePercentage !== 0 && (
                <>
                  <span className={`${priceChangePercentage[0] === '+' ? Styles.positiveChange : Styles.negativeChange}`}>{priceChangePercentage}</span>
                  <img
                    src={
                      (priceChangePercentage[0] === '+') ? (
                        UpArrow
                      ) : (
                        DownArrow
                      )
                    }
                  />
                </>
              )
            }
          </div>
        </div>
      </div>

      {/* graph container */}
      <div className={Styles.graphContainer}>
        <AreaChart
          pairId={pairId}
          kLineData={convertKLineDataToArray(marketKLine[Object.keys(marketKLine)[0]])}
          color={priceChangePercentage[0] === '-' ? 'error' : 'success'}
        />
      </div>

    </div>
  )
}

export default MarketGraphCard