import React, { useEffect, useRef, useState } from "react"
import Search from "../../../../components/Search/Search"

// styles
import Styles from "./TradeHistoryTab.module.css"

// icons
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/imported/calendar.svg"
import { ReactComponent as FilterIcon } from "../../../../assets/icons/imported/filter.svg"
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/imported/down-arrow.svg"
import { ReactComponent as UpArrowIcon } from "../../../../assets/icons/imported/up-arrow.svg"
import { ReactComponent as BuyArrowSquare } from "../../../../assets/icons/imported/buy-arrow-square.svg"
import { ReactComponent as SellArrowSquare } from "../../../../assets/icons/imported/sell-arrow-square.svg"
// components
import CoinTag from "../../../../components/CoinTag/CoinTag"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"

// redux
import { useSelector } from "react-redux"
import { _getTrades } from "../../redux/actions"

// utilities
import { formatDate } from "../../../../utility/formatDate"
import { formatDateTime } from "../../../../utility/formatDateTime"
import { debounce } from "../../../../utility/debounce"

// DATAGRID
import { useHistory } from "react-router-dom"
import MobileRowDatagrid from "../../../../components/MUIDatagrid/MobileRowDatagrid"
import CoinDisplay from "../../../../components/CoinDisplay/CoinDisplay"

function TradeHistoryTab() {
  const history = useHistory()

  const searchInputRef = useRef(null)

  const [trades, setTrades] = useState([])
  const [filteredTrades, setFilteredTrades] = useState([])

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700)
  document.title = "Wenbit: Trade History"

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 700)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // table states
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(15)
  const [tableLoading, setTableLoading] = useState(false)

  const marketList = useSelector((state) => state.marketList.value)

  const fetchTrade = () => {
    setTableLoading(true)
    _getTrades(
      {
        page,
        limit: pageLimit
      },
      (data) => {
        setTrades(data)
        const pairs = data.forEach((el) => {
          // el['pair_name'] = marketList.find(marketPair => marketPair.id === el.market)?.name
          // console.log(
          //   marketList.find((marketPair) => marketPair.id === el.market)?.name
          // )
        })
        // console.log("DATA HERE", pairs)
        setTableLoading(false)
      },
      (err) => setTableLoading(false)
    )
  }

  const handleSearch = () => {
    const searchValue = searchInputRef.current.value
    if (
      searchValue === "" ||
      searchValue === null ||
      searchValue === undefined
    ) {
      setFilteredTrades(trades)
      // return
    }
    // setFilteredTrades(trades.filter(el => console.log(el)))
  }

  useEffect(() => {
    fetchTrade()
  }, [])

  const tradesColumns = isMobileView
    ? [
        {
          field: "market",
          headerName: "Pair",
          minWidth: 170,
          renderCell: (data) => {
            const marketPair = marketList.find(
              (el) => el.id === data.row.market
            )
            return (
              <MobileRowDatagrid
                renderRowHeader={() => (
                  <CoinDisplay
                    coinImgURL={marketPair?.icon_url}
                    coinName={marketPair?.coin_name}
                    coinTag={marketPair?.name.toUpperCase()}
                  />
                )}
                rows={[
                  {
                    title: "Side",
                    value: (
                      <span className={Styles.sideColumn}>
                        {/* {
                                            data.row.side === 'sell' ? (
                                                <SellArrowSquare width={26} />
                                            ) : (
                                                <BuyArrowSquare width={26}/>
                                            )
                                        } */}
                        <span>{data.row.side === "sell" ? "Sell" : "Buy"}</span>
                      </span>
                    )
                  },
                  { title: "Price", value: data.row.price },
                  { title: "Amount", value: data.row.amount },
                  {
                    title: "Date",
                    value: <span>{formatDateTime(data.row.created_at)}</span>
                  }
                ]}
              />
            )
          },
          flex: 1
        }
      ]
    : [
        {
          field: "market",
          headerName: "Pair",
          minWidth: 180,
          renderCell: (data) => {
            const marketPair = marketList.find(
              (el) => el.id === data.row.market
            )
            return (
              <span className={Styles.pairColumn}>
                <img src={marketPair?.icon_url} />
                {marketPair?.coin_name}
                <CoinTag tag={marketPair?.name.toUpperCase()} size={11} />
              </span>
            )
          },
          flex: 1
        },
        {
          field: "side",
          headerName: "Side",
          minWidth: 155,
          editable: false,
          renderCell: (data) => (
            <span className={Styles.sideColumn}>
              {data.row.side === "sell" ? (
                <SellArrowSquare width={26} />
              ) : (
                <BuyArrowSquare width={26} />
              )}
              <div>
                <span>{data.row.side === "sell" ? "Sell" : "Buy"}</span>
                <span>{formatDate(data.row.created_at)}</span>
              </div>
            </span>
          ),
          flex: 1
        },
        {
          field: "price",
          headerName: "Price",
          minWidth: 60,
          editable: false,
          flex: 1
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 60,
          editable: false,

          flex: 1
        },
        {
          field: "created_at",
          headerName: "Date",
          minWidth: 120,
          editable: false,
          flex: 1,
          renderCell: (data) => (
            <span style={{ color: "var(--gray-color)" }}>
              {formatDateTime(data.row.created_at)}
            </span>
          )
        }
      ]

  return (
    <div className={Styles.tabContainer}>
      <div className={Styles.tabHeader}>
        <div className={Styles.titlesContainer}>
          <h5>Trade History</h5>
          <span>All that’s in, and all that’s gone out.</span>
        </div>
        <Search
          placeholder="Search by coin"
          onChange={debounce(handleSearch, 500)}
          ref={searchInputRef}
        />
      </div>

      <MUIDatagrid
        // page={page}
        // pageLimit={pageLimit}
        disableSorting
        rows={trades}
        hideFooter={true}
        hideHeader={isMobileView}
        // totalRowsCount={totalRows}
        loading={tableLoading}
        getRowId={(row) => row.id}
        // onRowClick={handleRowClick}
        // onPageChange={handlePageChange}
        // onSortModelChange={handleSortModelChange}
        columns={tradesColumns}
        rowHeight={isMobileView && 170}
      />
    </div>
  )
}

export default TradeHistoryTab
