import React, { useState, useEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"

// styles
import Styles from "./spotWalletTab.module.css"

// icons
import { RiShareBoxFill } from "react-icons/ri"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { ReactComponent as WalletIcon } from "../../../../assets/icons/imported/wallet.svg"
import { ReactComponent as DepositIcon } from "../../../../assets/icons/imported/return.svg"
import { ReactComponent as WithdrawIcon } from "../../../../assets/icons/imported/investments.svg"

// components
import Search from "../../../../components/Search/Search"
import Button from "../../../../components/Button/Button"
import CoinTag from "../../../../components/CoinTag/CoinTag"
import TableButton from "../../../../components/TableButton/TableButton"
import MUIDatagrid from "../../../../components/MUIDatagrid/MUIDatagrid"

// redux
import { _getBalances, _updateBalancePreference } from "../../redux/actions"
import { useSelector, useDispatch } from "react-redux"
import { _getDepositAddress } from "../../../../redux/actions"
import { _getCurrencies } from "../../../exchange/redux/actions"
import { setShowPrice } from "../../redux/slices/showPriceSlice"

import {
  setSelectCurrencyModalData,
  setSelectCurrencyModalOpen
} from "../../../modals/select-currency/redux/selectCurrencyModalSlice"

// utils
import { FormattedMessage, injectIntl } from "react-intl"
import { formatNumber } from "../../../helpers/formatNumber"
import {
  setWithdrawFormModalOpen,
  setwithdrawFormModalData
} from "../../../modals/deposit-withdraw/redux/withdrawFormModalSlice"
import { handleDeposit } from "../../../../utility/deposit-withdraw-helpers/handleDeposit"
import { showSelectNetworkModal } from "../../../modals/deposit-withdraw/select-network/helpers"
import { debounce } from "../../../../utility/debounce"
import { handleWithdraw } from "../../../../utility/deposit-withdraw-helpers/handleWithdraw"
import { setUser } from "../../../user/redux/slices/userDataSlice"
import { showErrorSnackbar } from "../../../snackbar/helpers"
import SpotWalletFooter from "./SpotWalletFooter"

function SpotWalletTab() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  document.title = "Wenbit: Spot Wallet"

  const theme = useSelector((state) => state.theme.value)
  const currencies = useSelector((state) => state.currencies.value)

  // ** Show/Hide Price
  const hideBalance = useSelector((state) => state.userData.value.hide_balance)

  const TotalWithdraws = useSelector(
    (state) => state.fundsData.data.total_withdrawals
  )
  const TotalDeposits = useSelector(
    (state) => state.fundsData.data.total_deposits
  )
  const TotalFunds = useSelector((state) => state.fundsData.data.total_funds)
  const TotalBtcBalance = useSelector(
    (state) => state.fundsData.data.total_balance_btc
  )
  const [selectedTab, setSelectedTab] = useState("all")
  const [filteredBalances, setFilteredBalances] = useState([])

  // wallet table states
  const pageLimit = 8
  const [tableLoading, setTableLoading] = useState(false)
  const [hideBalanceLoading, setHideBalanceLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const searchInputRef = useRef(null)
  const handlePageChange = (e, value) => {
    setPage(value)
  }

  // function to fetch wallets
  const fetchWallets = () => {
    setTableLoading(true)
    setFilteredBalances([])
    _getBalances(
      {
        limit: pageLimit,
        page,
        nonzero: selectedTab === "available"
      },
      ({ data, page, total }) => {
        // setBalances(() => {
        //   const newBalances = currencydata.map((currency, index) => {
        //     return {
        //       ...currency,
        // balance:
        //   data.find((wallet) => wallet.currency === currency.id)
        //     ?.balance ?? 0.0,
        // locked:
        //   data.find((wallet) => wallet.currency === currency.id)
        //     ?.locked ?? 0.0
        //     }
        //   })
        //   if (selectedTab === "available") {
        //     const filteredBalances = newBalances.filter(
        //       (balance) => Number(balance.balance) > 0
        //     )
        //     setFilteredBalances(filteredBalances)
        //   } else {
        //     setFilteredBalances(newBalances)
        //   }

        //   return newBalances
        // })

        // pagination
        setFilteredBalances(() => {
          const balances = data?.map((balance) => {
            const coinData = currencies?.find(
              (currency) => currency?.id === balance?.currency
            )
            return {
              ...coinData,
              ...balance // Use the individual "balance" object instead of the "data" array
            }
          })
          return balances
        })
        setTotalRows(total)
        setTableLoading(false)
      },
      (err) => setTableLoading(false)
    )
  }
  const fetchWalletsSearch = () => {
    const searchValue = searchInputRef.current.value
    if (
      searchValue === "" ||
      searchValue === null ||
      searchValue === undefined
    ) {
      setPage(1)
      fetchWallets()
      return
    }
    setTableLoading(true)
    setFilteredBalances([])
    _getBalances(
      {
        limit: pageLimit,
        page,
        nonzero: selectedTab === "available",
        "search[currency_code]": searchInputRef.current?.value ?? null,
        "search[currency_name]": searchInputRef.current?.value ?? null
      },
      ({ data, page, total }) => {
        // pagination
        setFilteredBalances(() => {
          const balances = data?.map((balance) => {
            const coinData = currencies?.find(
              (currency) => currency?.id === balance?.currency
            )
            return {
              ...coinData,
              ...balance // Use the individual "balance" object instead of the "data" array
            }
          })
          return balances
        })
        setTotalRows(total)
        setTableLoading(false)
      },
      (err) => setTableLoading(false)
    )
  }

  useEffect(() => {
    const searchValue = searchInputRef.current.value
    if (
      searchValue === "" ||
      searchValue === null ||
      searchValue === undefined
    ) {
      fetchWallets()
    } else {
      fetchWalletsSearch()
    }
  }, [selectedTab, page])

  useEffect(() => {
    if (location.pathname === "/account/wallet/spot/all") {
      setSelectedTab("all")
    }
    if (location.pathname === "/account/wallet/spot/available") {
      setSelectedTab("available")
    }
    if (location.pathname === "/account/wallet/spot") {
      setSelectedTab("all")
      history.replace("/account/wallet/spot/all")
    }
  }, [location])

  // const handleDeposit = (coin_id) => {
  //   dispatch(setSelectedCoinId(coin_id))
  //   dispatch(setBackdropOpen(true))
  //   _getDepositAddress(
  //     {
  //       coin: coin_id
  //     },
  //     (data) => {
  //       if (data.address === null) {
  //         setTimeout(() => {
  //           _getDepositAddress(
  //             {
  //               coin: coin_id
  //             },
  //             (data) => {
  //               dispatch(setBackdropOpen(false))
  //               if (data.address) {
  //                 setSelectCurrencyModalOpen(false)
  //                 dispatch(setDepositAddress(data.address))
  //                 dispatch(setWalletDepositModalOpen(true))
  //               } else {
  //                 handleWalletNotFound()
  //               }
  //             },
  //             (err) => {
  //               dispatch(setBackdropOpen(false))
  //               handleWalletNotFound()
  //             }
  //           )
  //         }, 15000) // Delay of 15000 milliseconds (15 seconds)
  //       } else {
  //         dispatch(setBackdropOpen(false))
  //         setSelectCurrencyModalOpen(false)
  //         dispatch(setDepositAddress(data.address))
  //         dispatch(setWalletDepositModalOpen(true))
  //       }
  //     },
  //     (err) => {
  //       dispatch(setBackdropOpen(false))
  //     }
  //   )
  // }

  // ----- RESPONSIVENESS STATE ---------
  // ------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 820)

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 820)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  // ------------------------------------
  // ------------------------------------

  // ** Handlers
  const handleToggleHidePrice = () => {
    setHideBalanceLoading(true)
    _updateBalancePreference(
      { preference: !hideBalance },
      (data) => {
        dispatch(setUser(data.data))
        setHideBalanceLoading(false)
      },
      () => {
        setHideBalanceLoading(false)
        showErrorSnackbar({ alertMessage: "Something went wrong" })
      }
    )
  }

  const walletColumns = isMobileView
    ? [
        {
          minWidth: 180,
          flex: 1,
          field: "currency",
          headerName: "Coin",
          renderCell: (data) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginRight: 10
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 5
                }}
              >
                <img width={34} src={data.row?.icon_url} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    marginBottom: -10
                  }}
                >
                  <span
                    style={{
                      fontSize: 15
                    }}
                  >
                    {data.row?.name}
                  </span>
                  <CoinTag tag={data.row?.id.toUpperCase()} size={10} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right"
                }}
              >
                <span
                  style={{
                    fontSize: 14
                  }}
                >
                  {hideBalance ? "*****" : formatNumber(data.row.balance)}
                </span>
                <span
                  style={{
                    fontSize: 12,
                    color: "var(--gray-color)"
                  }}
                >
                  {hideBalance
                    ? "******"
                    : formatNumber(
                        parseFloat(data.row.balance) +
                          parseFloat(data.row.locked)
                      ) ?? "0.00"}
                </span>
              </div>
            </div>
          )
        }
      ]
    : [
        {
          minWidth: 180,
          flex: 1,
          field: "currency",
          headerName: "Coin",
          renderCell: (data) => (
            <div className={Styles.coinNameColumn}>
              <img src={data.row?.icon_url} />
              <span>{data.row?.name}</span>
              <CoinTag tag={data.row?.id.toUpperCase()} size={12} />
            </div>
          )
        },
        {
          minWidth: 120,
          maxWidth: 135,
          flex: 1,
          field: "balance",
          headerName: "Avbl. Balance",
          renderCell: (data) => (
            <div>{hideBalance ? "******" : formatNumber(data.row.balance)}</div>
          )
        },
        {
          minWidth: 120,
          maxWidth: 135,
          flex: 1,
          field: "locked",
          headerName: "Locked",
          renderCell: (data) => (
            <div>{hideBalance ? "******" : formatNumber(data.row.locked)}</div>
          )
        },
        {
          minWidth: 120,
          maxWidth: 135,
          flex: 1,
          field: "amount",
          headerName: "Amount",
          renderCell: (data) => (
            <div>
              {hideBalance
                ? "******"
                : formatNumber(
                    parseFloat(data.row.balance) + parseFloat(data.row.locked)
                  ) ?? "0.00"}
            </div>
          )
        },
        {
          minWidth: 260,
          flex: 1,
          headerName: "Action",
          renderCell: (data) => (
            <div className={Styles.buttonsContainer}>
              <TableButton
                buttonText="Deposit"
                buttonIcon={<RiShareBoxFill />}
                onClick={() => {
                  handleDeposit(data.row.id)
                }}
              />
              <TableButton
                buttonText="Withdraw"
                buttonIcon={<RiShareBoxFill />}
                onClick={() => {
                  handleWithdraw({ selectedCoinId: data.row.id })
                }}
              />
              <TableButton
                buttonText="Convert"
                buttonIcon={<RiShareBoxFill />}
                onClick={() =>
                  history.push(`/convert/${data.row.id.toUpperCase()}_USDT`)
                }
              />
            </div>
          )
        }
      ]
  return (
    <>
      {/* tab header */}
      <div className={`${Styles.card} ${Styles.tabHeader}`}>
        {/* left section */}
        <div>
          <div className={Styles.TitlesContainer}>
            <h3>Spot wallet</h3>
            <span className={Styles.subTitle}>
              All your crypto bits, stored in one place. Cool huh?
            </span>
          </div>

          <div>
            <WalletIcon width={20} />
            Wallet Balance
            <span
              className={Styles.walletBalanceSpanMobile}
              style={{ marginLeft: "auto" }}
            >
              {hideBalance ? "********" : `$${formatNumber(TotalFunds, 2)}`}
            </span>
            <span className={Styles.walletBalanceSpanMobile}>
              {" "}
              &#8776;{" "}
              {hideBalance
                ? "********"
                : `${formatNumber(TotalBtcBalance, 6)}`}{" "}
              BTC
            </span>
            <button
              onClick={handleToggleHidePrice}
              className={Styles.hidePriceButtonMobile}
              disabled={hideBalanceLoading}
            >
              {hideBalance ? (
                <FiEyeOff color="var(--gray-color)" />
              ) : (
                <FiEye color="var(--gray-color)" />
              )}
            </button>
          </div>

          <div>
            <div className={Styles.walletBalanceContainer}>
              <span>
                {hideBalance ? "********" : `$${formatNumber(TotalFunds, 2)}`}
              </span>
              <span>
                {" "}
                &#8776;{" "}
                {hideBalance
                  ? "********"
                  : `${formatNumber(TotalBtcBalance, 6)}`}{" "}
                BTC
              </span>
            </div>
            <button
              className={Styles.hidePriceButton}
              onClick={handleToggleHidePrice}
              // style={{
              //   backgroundColor: theme === "light" && "var(--background-color)"
              // }}
              disabled={hideBalanceLoading}
            >
              {`${hideBalance ? "Show" : "Hide"} Price`}{" "}
              {hideBalance ? <FiEye /> : <FiEyeOff />}
            </button>
          </div>
        </div>

        {/* right section */}
        <div>
          <div style={{ display: isMobileView && "none" }}>
            <Button
              text="Deposit"
              style={{
                flex: 0.45,
                // minHeight: 33,
                height: 33,
                borderRadius: 8 //5
              }}
              onClick={() => {
                dispatch(
                  setSelectCurrencyModalData({
                    title: "Select coin to deposit",
                    showTabs: false,
                    type: "coin",
                    searchPlaceholder: "Search coin name",
                    currenciesToHide: [],
                    disableBackdropClick: true,
                    callback: (coin) => {
                      handleDeposit(coin.id)
                    }
                  })
                )
                dispatch(setSelectCurrencyModalOpen(true))
              }}
            />
            <Button
              text="Withdraw"
              variant="dark"
              style={{
                flex: 0.45,
                // minHeight: 33,
                height: 34,
                borderRadius: 8, //5
                backgroundColor: theme === "light" && "#D5F9F9",
                color: theme === "light" && "#00191E"
              }}
              onClick={handleWithdraw}
            />
          </div>

          <div
            style={{
              backgroundColor: "var(--background-color)"
            }}
          >
            <div>
              <span>
                <DepositIcon width={16.5} />
                Total Deposited
              </span>
              {hideBalance ? "********" : `$${formatNumber(TotalDeposits)}`}
            </div>

            <div>
              <span>
                <WithdrawIcon width={16.5} />
                Total Withdrawn
              </span>
              <span>
                {hideBalance ? "********" : `$${formatNumber(TotalWithdraws)}`}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* wallets */}
      <div className={`${Styles.card} ${Styles.walletsContainer}`}>
        {/* table header */}
        <div className={Styles.tableHeader}>
          <h4>Spot wallet</h4>
          <Search
            placeholder="Search wallet"
            onChange={debounce(fetchWalletsSearch, 500)}
            containerStyle={{ width: isMobileView && "100%" }}
            ref={searchInputRef}
          />
        </div>
        <div className={Styles.tabsContainer}>
          <Button
            text="All"
            type="tab"
            onClick={() => {
              if (tableLoading) return
              history.replace("/account/wallet/spot/all")
            }}
            isTabSelected={location.pathname === "/account/wallet/spot/all"}
          />
          <Button
            text="Available"
            type="tab"
            onClick={() => {
              if (tableLoading) return
              history.replace("/account/wallet/spot/available")
            }}
            isTabSelected={
              location.pathname === "/account/wallet/spot/available"
            }
            style={{ marginRight: "auto" }}
          />
        </div>

        {/* MUI DATAGRID HERE */}
        <MUIDatagrid
          disableSorting
          page={page}
          pageLimit={pageLimit}
          hideHeader={isMobileView}
          totalRowsCount={totalRows}
          onPageChange={handlePageChange}
          rows={filteredBalances}
          loading={tableLoading}
          getRowId={(row) => row.id}
          columns={walletColumns}
          rowHeight={isMobileView && 80} //original 210
        />
      </div>

      {/* SpotWalletFooter */}
      {isMobileView && <SpotWalletFooter />}
    </>
  )
}

export default injectIntl(SpotWalletTab)
