import React from "react"

// styles
import Styles from "./mobileTabSwitcher.module.css"
import Button from "../../../../components/Button/Button"

// redux
import { useSelector } from "react-redux"

// temp
import TradingChart from "../../old-components/TradingChart"
import Orderbook from "../Orderbook/Orderbook"
import RecentTrades from "../RecentTrades/RecentTrades"

// tab styling
const tabStyling = {
  padding: "0 5%",
  whiteSpace: "nowrap",
  borderRadius: 5,
  flex: 1
}

function MobileTabSwitcher({ selectedMobileTab, setSelectedMobileTab }) {
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const theme = useSelector((state) => state.theme.value)

  return (
    <div className={Styles.card}>
      {/* TABS CONTAINER */}
      <div className={Styles.tabsContainer}>
        <Button
          text="Chart"
          variant={selectedMobileTab === "Chart" ? null : "dark"}
          onClick={() => setSelectedMobileTab("Chart")}
          style={{
            ...tabStyling,
            color: theme === "light" && "var(--text-color)"
          }}
        />
        <Button
          text="Order Book"
          variant={selectedMobileTab === "Order Book" ? null : "dark"}
          onClick={() => setSelectedMobileTab("Order Book")}
          style={{
            ...tabStyling,
            color: theme === "light" && "var(--text-color)"
          }}
        />
        <Button
          text="Recent Trades"
          variant={selectedMobileTab === "Recent Trades" ? null : "dark"}
          onClick={() => setSelectedMobileTab("Recent Trades")}
          style={{
            ...tabStyling,
            color: theme === "light" && "var(--text-color)"
          }}
        />
      </div>

      {selectedMobileTab === "Chart" && (
        <TradingChart
          selectedMarket={selectedMarket}
          style={{ marginBottom: "var(--gap)" }}
        />
      )}
      {selectedMobileTab === "Order Book" && <Orderbook />}
      {selectedMobileTab === "Recent Trades" && <RecentTrades />}
    </div>
  )
}

export default MobileTabSwitcher
