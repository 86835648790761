import React from "react"
import { useHistory, useLocation } from "react-router-dom"

// styles
import Styles from "./jobApplyCard.module.css"
import Button from "../../Button/Button"

function JobApplyCard({ id, title, description, datePosted }) {
  const history = useHistory()

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <h3>{title}</h3>
        <p>{description}</p>
        <span>{datePosted}</span>
      </div>

      <div className={Styles.rightContainer}>
        <Button text="Apply" onClick={() => history.push(`${id}`)} width={130} />
      </div>
    </div>
  )
}

export default JobApplyCard
