import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"
import { all } from "redux-saga/effects"
import { websocketSaga } from "../websocketSaga"

// Reducer imports (Assuming they are wrapped with createSlice)
import marketReducer from "../modules/exchange/redux/slices/marketListSlice"
import marketTickerReducer from "../modules/exchange/redux/slices/marketTickersSlice"
import tradesReducer from "../modules/exchange/redux/slices/tradesSlice"
import openOrdersReducer from "../modules/exchange/redux/slices/openOrdersSlice"
import orderBookReducer from "../modules/exchange/redux/slices/orderBookSlice"
import selectedMarketReducer from "../modules/exchange/redux/slices/selectedMarketSlice"
import klineReducer from "../modules/exchange/redux/slices/klineSlice"
import selectedCoinReducer from "../modules/markets/redux/slices/selectedCoin"
import lastTradeReducer from "../modules/exchange/redux/slices/lastTradeSlice"
import previousMarketReducer from "../modules/exchange/redux/slices/previousMarketSlice"
import currenciesReducer from "../modules/exchange/redux/slices/currenciesSlice"
import balancesReducer from "./slices/balancesSlice"
import beneficiariesReducer from "./slices/beneficiariesSlice"
import userDataReducer from "../modules/user/redux/slices/userDataSlice"
import themeReducer from "./slices/themeSlice"
// import webSocketReducer from './slices/webSocketSlice'
import languageReducer from "./slices/languageSlice"
import currencyReducer from "./slices/currencySlice"
import loggedInReducer from "./slices/loggedIn"
import backdropReducer from "./slices/backdropSlice"
import showPriceReducer from "../modules/history/redux/slices/showPriceSlice"

// drawer navigation reducer
import drawerNavigationReducer from "../components/DrawerNavigation/redux/DrawerNavigationSlice"

// snackbar reducer
import snackbarReducer from "../modules/snackbar/redux/snackbarSlice"

// -------------MODAL REDUCERS-------------
// general modals
import informationModalReducer from "../modules/modals/information/redux/informationModalSlice"
import successModalReducer from "../modules/modals/success/redux/successModalSlice"
import dangerModalReducer from "../modules/modals/danger/redux/dangerModalSlice"
import warningModalReducer from "../modules/modals/warning/redux/warningModalSlice"
import selectCurrencyModalReducer from "../modules/modals/select-currency/redux/selectCurrencyModalSlice"
// deposit-withdraw modals
import selectNetworkModalReducer from "../modules/modals/deposit-withdraw/redux/selectNetworkModalSlice"
import walletDepositModalReducer from "../modules/modals/deposit-withdraw/redux/walletDepositModalSlice"
import Enable2FAModalReducer from "../modules/modals/security/Enable2FAModal/redux/slices/Enable2FAModalSlice"
import withdrawFormModalReducer from "../modules/modals/deposit-withdraw/redux/withdrawFormModalSlice"
import walletLoadingModalReducer from "../modules/modals/wallet-loading/redux/walletLoadingModalSlice"
// convert modals
import successConvertModalReducer from "../modules/modals/convert/redux/successConvertSlice"
import confirmConvertModalReducer from "../modules/modals/convert/redux/confirmConvertModalSlice"
// beneficiaries modals
import beneficiaryReducer from "./slices/beneficiarySlice"
import actionsBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/actionsBeneficiaryModalSlice"
import viewBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/viewBeneficiaryModalSlice"
import createBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/createBeneficiaryModalSlice"
import deleteBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/deleteBeneficiaryModalSlice"
import selectBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/selectBeneficiaryModalSlice"
import activateBeneficiaryModalReducer from "../modules/modals/beneficiaries/redux/slices/activateBeneficiaryModalSlice"
// security modals
import VerificationCodesInputModalReducer from "../modules/modals/auth/VerificationCodesInput/redux/verificationCodesInputModalSlice"
import disable2FAModalReducer from "../modules/modals/security/Disable2FAModal/redux/disable2FAModalSlice"
import modifySMSAuthModalReducer from "../modules/modals/security/ModifySMSAuthModal/redux/modifySMSAuthModalSlice" // modify SMS Auth modal
import enableSMSAuthModalReducer from "../modules/modals/security/EnableSMSAuthModal/redux/EnableSMSAuthModalSlice" // enable SMS Auth modal
import disableSMSAuthModalReducer from "../modules/modals/security/DisableSMSAuthModal/redux/disableSMSAuthModalSlice" // disable SMS Auth modal
import securityVerificationModalReducer from "../modules/modals/security-verification/redux/securityVerificationModalSlice"
// avatar modals
import selectAvatarModalReducer from "../modules/modals/avatar/redux/selectAvatarModalSlice"
// language currency select modal
import languageCurrencySelectModalReducer from "../modules/modals/language-currency-select/redux/LanguageCurrencySelectModalSlice"
// KYC reminder modal
import kycReminderModalReducer from "../modules/modals/kyc/redux/KYCReminderModalSlice"
// ----------------------------------------

// deposit
import depositDataReducer from "../redux/slices/depositSlice"

// funds
import fundsReducer from "../redux/slices/fundsSlice"

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "theme",
    "marketList",
    "language",
    "marketTickers",
    "currencies",
    // "balances",
    "marketsList",
    "selectedMarket",
    "lastTrade"
  ]
}

const rootReducer = combineReducers({
  marketList: marketReducer,
  marketTickers: marketTickerReducer,
  trades: tradesReducer,
  orderBook: orderBookReducer,
  openOrders: openOrdersReducer,
  userData: userDataReducer,
  theme: themeReducer,
  // webSocket: webSocketReducer,
  selectedMarket: selectedMarketReducer,
  kline: klineReducer,
  selectedCoin: selectedCoinReducer,
  lastTrade: lastTradeReducer,
  currencies: currenciesReducer,
  previousMarket: previousMarketReducer,
  language: languageReducer,
  currency: currencyReducer,
  balances: balancesReducer,
  beneficiaries: beneficiariesReducer,
  backdrop: backdropReducer,
  showPrice: showPriceReducer,
  loggedIn: loggedInReducer,

  // drawer navigation
  drawerNavigationState: drawerNavigationReducer,

  // snackbar
  snackbarState: snackbarReducer,

  // modals
  dangerModalState: dangerModalReducer,
  successModalState: successModalReducer,
  warningModalState: warningModalReducer,
  informationModalState: informationModalReducer,
  withdrawFormModalState: withdrawFormModalReducer,
  selectNetworkModalState: selectNetworkModalReducer,
  walletDepositModalState: walletDepositModalReducer,
  successConvertModalState: successConvertModalReducer,
  confirmConvertModalState: confirmConvertModalReducer,
  selectCurrencyModalState: selectCurrencyModalReducer,
  kycReminderModalState: kycReminderModalReducer,
  //beneficiaries
  beneficiary: beneficiaryReducer,
  actionsBeneficiaryModalState: actionsBeneficiaryModalReducer,
  viewBeneficiaryModalState: viewBeneficiaryModalReducer,
  createBeneficiaryModalState: createBeneficiaryModalReducer,
  deleteBeneficiaryModalState: deleteBeneficiaryModalReducer,
  selectBeneficiaryModalState: selectBeneficiaryModalReducer,
  activateBeneficiaryModalState: activateBeneficiaryModalReducer,

  // deposit - withdraw
  depositData: depositDataReducer,
  walletLoadingModalState: walletLoadingModalReducer,

  // funds
  fundsData: fundsReducer,

  // security verification
  VerificationCodesInputModalState: VerificationCodesInputModalReducer,
  Enable2FAModalState: Enable2FAModalReducer,
  disable2FAModalState: disable2FAModalReducer,
  securityVerificationModalState: securityVerificationModalReducer,
  enableSMSAuthModalState: enableSMSAuthModalReducer,
  disableSMSAuthModalState: disableSMSAuthModalReducer,
  modifySMSAuthModalState: modifySMSAuthModalReducer,

  // avatar
  selectAvatarModalState: selectAvatarModalReducer,

  // select language currency modal
  languageCurrencySelectModalState: languageCurrencySelectModalReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create the Redux Saga middleware
const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: persistedReducer, // Use persistedReducer instead of rootReducer
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false }),
    sagaMiddleware
  ] // Disable serializable check for non-serializable values
})

sagaMiddleware.run(function* () {
  yield all([websocketSaga()]) // Run the websocketSaga
})

export const persistor = persistStore(store)
