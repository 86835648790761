import React, { useState, useEffect } from "react"

// styles
import Styles from "./SuccessConvertModal.module.css"

//redux
import { useDispatch, useSelector } from "react-redux"
import { setSuccessConvertModalOpen } from "./redux/successConvertSlice"

// icons
import { ReactComponent as SuccessIcon } from "../../../assets/icons/imported/checkmark.svg"

//helpers
import { estimateUnitValue } from "../../helpers/estimateValueBase"

// components
import WenbitModal from "../../../components/Modal/Modal"
import Button from "../../../components/Button/Button"

function SuccessConvertModal(modalOpen) {
  const modalData = useSelector((state) => state.successConvertModalState?.data)
  // console.log(modalData)
  const [USDTValue, setUSDTValue] = useState("")
  const markets = useSelector((state) => state.marketList.value)

  const dispatch = useDispatch()

  //const modalOpen = useSelector(state => state.successConvertModalState.open)

  const handleClose = () => {
    dispatch(setSuccessConvertModalOpen(false))
  }
  // if (modalOpen === true) {
  //     const usdtPrice = estimateUnitValue('usdt', modalData.fromCurrency?.id, parseFloat(modalData.fromValue), modalData.currencies, modalData.markets, modalData.marketTickers)
  //     //setUSDTValue(usdtPrice)
  // }
  useEffect(() => {
    if (modalOpen === true) {
      const usdtPrice = estimateUnitValue(
        "usdt",
        modalData.fromCurrency?.id,
        parseFloat(modalData.fromValue),
        modalData.currencies,
        modalData.markets,
        modalData.marketTickers
      )
      setUSDTValue(usdtPrice)
    }
  }, [])

  // const convertedPrice = estimateUnitValue(modalData.fromValue ? modalData.toCurrency.id : modalData.fromCurrency.id, modalData.fromValue ? modalData.fromCurrency.id : modalData.toCurrency.id, parseFloat(modalData.fromValue || modalData.toValue), modalData.currencies, modalData.markets, modalData.marketTickers)
  // setValue(convertedPrice)
  return (
    <WenbitModal
      open={modalOpen}
      onClose={handleClose}
      showCloseButton={true}
      render={() => (
        <div className={Styles.mainContainer}>
          <div className={Styles.successIconContainer}>
            <SuccessIcon
              color="var(--primary-color)"
              style={{ transform: "scale(1.9)" }}
            />
          </div>

          <h4 className={Styles.title}>Successful</h4>

          <div className={Styles.spentContainer}>
            <div>You will {modalData.side}</div>
            <div>
              {modalData.amount}{" "}
              {markets
                .find((el) => el.id === modalData.market)
                ?.base_unit?.toUpperCase()}
            </div>
          </div>

          <div className={Styles.rowsContainer}>
            <div>
              <span>Converted</span>
              <span>
                {modalData.total}{" "}
                {markets
                  .find((el) => el.id === modalData.market)
                  ?.quote_unit?.toUpperCase()}
              </span>
            </div>
            <div>
              <span>Price</span>
              <span>
                {" "}
                {modalData.amount}{" "}
                {markets
                  .find((el) => el.id === modalData.market)
                  ?.base_unit?.toUpperCase()}{" "}
                = {modalData.total}{" "}
                {markets
                  .find((el) => el.id === modalData.market)
                  ?.quote_unit?.toUpperCase()}
              </span>
            </div>
            <div>
              <span>Inverse Price</span>
              <span>
                1 {modalData.toCurrency?.id} = {USDTValue} USDT
              </span>
            </div>
          </div>

          <div className={Styles.buttonsContainer}>
            <Button variant="dark" text="Back" onClick={handleClose} />
            <Button
              type="link"
              linkTo="/account/convert/history"
              text="View History"
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    />
  )
}

export default SuccessConvertModal
