import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

// styles
import Styles from "./jobsGrid.module.css"

// icons
import { ReactComponent as RightArrow } from "../../../assets/icons/imported/right-arrow.svg"
import { ReactComponent as SadFaceIcon } from "../../../assets/icons/imported/sad-face.svg"

// redux
import { useSelector } from "react-redux"

// components
import JobCard from "../JobCard/JobCard"
import ShimmerLine from "../../shimmer-components/ShimmerLine"

function JobsGrid({ jobs = [], jobsLoading }) {
  const theme = useSelector((state) => state.theme.value)
  // console.log(
  //   `jobs: ${!jobs.length} + !loading: ${!jobsLoading} = ${
  //     !jobs.length && !jobsLoading
  //   }`
  // )
  if (!jobs.length && !jobsLoading)
    return (
      <div className={Styles.noJobsContainer}>
        <div className={Styles.noJobsIconContainer}>
          <SadFaceIcon />
        </div>
        <p>
          Unfortunately, we don't have any job openings at the moment. But don't
          give up on your dreams just yet - check back with us. You might fit
          the bill.
        </p>
      </div>
    )
  return (
    <>
      <div className={Styles.container}>
        {jobsLoading
          ? new Array(8)
              .fill(0)
              .map((el) => (
                <ShimmerLine borderRadius="10px" height="135px" width="100%" />
              ))
          : jobs.map((el) => <JobCard job={el} />)}
      </div>
      <div className={Styles.linkContainer}>
        <Link to="/careers/browse">Browse all positions available</Link>
        <div
          className={Styles.iconContainer}
          style={{
            backgroundColor:
              theme === "dark" ? "white" : "var(--secondary-color)"
          }}
        >
          <RightArrow />
        </div>
      </div>
    </>
  )
}

export default JobsGrid
