import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

// styles
import tabStyles from "../tab.module.css"
import Styles from "./EmailVerificationRequired.module.css"
import { setSnackbarData, setSnackbarOpen } from '../../../snackbar/redux/snackbarSlice'
import { FormattedMessage, injectIntl } from "react-intl"

import { _logout, _resendEmailVerification } from "../../../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { setBackdropOpen } from "../../../../redux/slices/backdropSlice"
import { setLoggedIn } from "../../../../redux/slices/loggedIn"
import {
  logoutUser,
  setUser
} from "../../../../modules/user/redux/slices/userDataSlice"
// icons
import { ReactComponent as EmailIcon } from "../../../../assets/icons/imported/email-verification.svg"
import Button from "../../../../components/Button/Button"

function EmailVerificationRequired() {
  const dispatch = useDispatch()
  const history = useHistory()
  const userData = useSelector((state) => state.userData.value)
  const isLoggedIn = useSelector(state => state.userData.value.email)

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/login')
    }
  }, [])

  const logout = () => {
    _logout(
      () => {
        dispatch(logoutUser())
        dispatch(setLoggedIn(false))
        dispatch(setUser(""))
        dispatch(setBackdropOpen(false))
        history.push('/login')
      },
      (error) => {
        dispatch(logoutUser())
        dispatch(setLoggedIn(false))
        dispatch(setUser(""))
        dispatch(setBackdropOpen(false))
        // console.log(error)
      }
    )
  }

  const handleVerificationResend = () => {
    _resendEmailVerification(
      {
        email: userData?.email
      },
    
      () => {
        // console.log("sent")
        dispatch(setSnackbarData({
          alertMessage: 'Email successfully sent',
          callback: () => { },
          actionType: 'close', // link - close
          severity: 'success',
          position: 'topRight'
        }))
        dispatch(setSnackbarOpen(true))
        logout()
      },
      (error) => {
        dispatch(setSnackbarOpen(true))
        dispatch(setSnackbarData({
          alertMessage: error.errors[0].length ? (<FormattedMessage id={error.errors[0]} />) : "Unknown Error",
          severity: 'error',
          //buttonMessage: 'See order',
          callback: () => { },
          //actionType: 'link', // link - close
          //linkTo: '/account/orders/open', 
          position: 'topRight'
        }))
      }
    )
  }

  return (
    <div className={Styles.tabContainer}>
      <EmailIcon width={80} />
      <h1 className={tabStyles.title}>Email Verification Required</h1>
      <p>
        <span>Before you can sign in, please verify your email address.</span>
        <br />
        <span>We have sent a verification link to your email</span> <br />
        <br />
        <span>Once verified, you can sign in to access your account.</span>
      </p>

      <div className={Styles.buttonsContainer}>
        <Button text="Resend verification" variant="link" onClick={handleVerificationResend} />
        <Button text="Home" onClick={e => { history.push("/") }} />
      </div>
    </div>
  )
}

export default injectIntl(EmailVerificationRequired)
