import React, { useState, useEffect } from "react"

// redux
import { useSelector, useDispatch } from "react-redux"
import { _getCurrencies, _previewConversion } from "../../convert/redux/actions"
import { _getBalances } from "../../../redux/actions"
import { setBalances, clearBalances } from "../../../redux/slices/balancesSlice"
import { setSelectedCoin } from "../redux/slices/selectedCoin"
import { setCurrencies } from "../../exchange/redux/slices/currenciesSlice"
// styles
import Styles from "./convert.module.css"
import ShimmerLine from "../../../components/shimmer-components/ShimmerLine"
// icons
import { ReactComponent as ConvertIcon } from "../../../assets/icons/imported/convert.svg"

// helpers
import { estimateUnitValue } from "../../helpers/estimateValueBase"
import { toast } from "react-toastify"
import { FormattedMessage, injectIntl } from "react-intl"
import { formatNumber } from "../../helpers/formatNumber"

// components
import CurrencyInput from "../../../components/CurrencyInput/CurrencyInput"
import Button from "../../../components/Button/Button"
import SelectCurrencyModal from "../../modals/convert/SelectCurrencyModal"
import ConfirmConvertModal from "../../modals/convert/ConfirmConvertModal"
import {
  setSnackbarOpen,
  setSnackbarData
} from "../../snackbar/redux/snackbarSlice"
import { setConfirmConvertModalData } from "../../modals/convert/redux/confirmConvertModalSlice"

function Convert({ hideTitle = false, containerStyle = {} }) {
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState("")
  const marketsList = useSelector((state) => state.marketList.value)
  const selectedMarket = useSelector((state) => state.selectedMarket.value)
  const selectedCoin = useSelector((state) => state.selectedCoin.value)
  const marketTickers = useSelector((state) => state.marketTickers.value)
  const userData = useSelector((state) => state.userData?.value)
  const balances = useSelector((state) => state.balances.value)
  const [confirmConvertModalOpen, setConfirmConvertModalOpen] = useState(false)
  //const [currencies, setCurrencies] = useState([])
  const [refreshBalance, setRefreshBalance] = useState(0)
  const currencies = useSelector((state) => state.currencies.value)

  const [price, setPrice] = useState(null)
  const [toCurrency, setToCurrency] = useState(
    marketsList.find((el) => el.id === selectedCoin.id)?.quote_unit
  )
  const [fromCurrency, setFromCurrency] = useState(
    marketsList.find((el) => el.id === selectedCoin.id)?.base_unit
  )

  const [selectCurrencyModalOpen, setSelectCurrencyModalOpen] = useState(false)
  const [currencySide, setCurrencySide] = useState("")

  useEffect(() => {
    setToCurrency(
      marketsList.find((el) => el.id === selectedCoin.id)?.quote_unit
    )
    setFromCurrency(
      marketsList.find((el) => el.id === selectedCoin.id)?.base_unit
    )
  }, [selectedCoin])

  useEffect(() => {
    dispatch(clearBalances())
    _getBalances(
      (res) => {
        dispatch(setBalances(res))
      },
      () => {}
    )
  }, [refreshBalance])

  useEffect(() => {
    _getCurrencies(
      (data) => {
        dispatch(setCurrencies(data))
      },
      () => {}
    )
  }, [])

  let convertedPrice = estimateUnitValue(
    quantity ? fromCurrency : toCurrency,
    quantity ? toCurrency : fromCurrency,
    parseFloat(quantity || price),
    currencies,
    marketsList,
    marketTickers
  )
  useEffect(() => {
    convertedPrice = estimateUnitValue(
      quantity ? fromCurrency : toCurrency,
      quantity ? toCurrency : fromCurrency,
      parseFloat(quantity || price),
      currencies,
      marketsList,
      marketTickers
    )
    // if (!price) {
    //   setPrice(convertedPrice)
    // } else if (!quantity) {
    //   setQuantity(convertedPrice)
    // }
  }, [quantity, price, fromCurrency, toCurrency])

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value)
    setPrice("")
  }

  const handleReverse = () => {
    // console.log(price, quantity)
    setFromCurrency(toCurrency)
    setToCurrency(fromCurrency)
    setPrice(quantity)
    setQuantity(price)
  }
  

  const handlePriceChange = (event) => {
    setPrice(event.target.value)
    setQuantity("")
  }

  const handleConvert = (convertedValue) => {
    // console.log(price, quantity)
    const fromCurr =
      currencies.length && currencies?.find((el) => el.id === fromCurrency)
    const toCurr =
      currencies.length && currencies?.find((el) => el.id === toCurrency)
    if (price) {
      // Perform conversion logic here, using `fromCurrency`, `toCurrency`, and `amount`
      _previewConversion(
        {
          from: fromCurr.id,
          to: toCurr.id,
          volume: price
        },
        (data) => {
          // console.log(data)
          dispatch(setConfirmConvertModalData(data))
          dispatch(setConfirmConvertModalOpen(true))
        },
        (error) => {
          console.log(error)
          dispatch(setSnackbarOpen(true))
          dispatch(
            setSnackbarData({
              alertMessage: error?.errors[0].length ? (
                <FormattedMessage id={error.errors[0]} />
              ) : (
                "Unknown Error"
              ),
              severity: "error",
              //buttonMessage: 'See order',
              callback: () => {},
              //actionType: 'link', // link - close
              //linkTo: '/account/orders/open',
              position: "topRight"
            })
          )
        }
      )
    } else {
      dispatch(setSnackbarOpen(true))
      dispatch(
        setSnackbarData({
          alertMessage: "You should insert values first",
          severity: "error",
          //buttonMessage: 'See order',
          callback: () => {},
          //actionType: 'link', // link - close
          //linkTo: '/account/orders/open',
          position: "topRight"
        })
      )
    }
  }
  return (
    <div className={Styles.card} style={{ ...containerStyle }}>
      {!hideTitle && <h5>CONVERT</h5>}
      <CurrencyInput
        title="From"
        type="number"
        placeholder={"0.00"}
        currencyImg={
          currencies
            ? currencies.find((el) => el.id === fromCurrency?.toLowerCase())
                ?.icon_url
            : ""
        }
        coinName={fromCurrency?.toUpperCase()}
        value={price || convertedPrice ? price || convertedPrice : ""}
        onChange={handlePriceChange}
        showDropdownArrow={true}
        onDropdownClick={() => {
          setCurrencySide("fromCurrency")
          setSelectCurrencyModalOpen(true)
        }}
        secondaryTitle={
          userData.email &&
          (balances ? (
            `Balance: ${
              formatNumber(
                balances.find((el) => el.currency === fromCurrency)?.balance
              ) ?? "0.00"
            }`
          ) : (
            <ShimmerLine height="10px" width="70px" />
          ))
        }
        currencyIcon=""
      />

      <ConvertIcon onClick={handleReverse} color="var(--primary-color)" />

      <CurrencyInput
        title="To"
        type="number"
        placeholder="0.00"
        currencyImg={
          currencies
            ? currencies?.find((el) => el.id === toCurrency?.toLowerCase())
                ?.icon_url
            : ""
        }
        coinName={toCurrency?.toUpperCase()}
        value={quantity || convertedPrice ? quantity || convertedPrice : ""}
        onChange={handleQuantityChange}
        showDropdownArrow={true}
        onDropdownClick={() => {
          setCurrencySide("toCurrency")
          setSelectCurrencyModalOpen(true)
        }}
        currencyIcon=""
        secondaryTitle={
          userData.email &&
          (balances ? (
            `Balance: ${
              formatNumber(
                balances.find((el) => el.currency === toCurrency)?.balance
              ) ?? "0.00"
            }`
          ) : (
            <ShimmerLine height="10px" width="70px" />
          ))
        }
      />

      <Button
        text="Convert"
        type={!userData.email && "loggedOut"}
        style={{ width: "100%" }}
        onClick={() => {
          handleConvert()
        }}
      />

      {/* -------------- MODALS-------------- */}
      <SelectCurrencyModal
        open={selectCurrencyModalOpen}
        handleClose={() => setSelectCurrencyModalOpen(false)}
        currencies={currencies}
        currencySide={currencySide}
        setFromCurrency={setFromCurrency}
        setToCurrency={setToCurrency}
        setRefreshBalance={setRefreshBalance}
        refreshBalance={refreshBalance}
      />

      {confirmConvertModalOpen ? (
        <ConfirmConvertModal
          open={confirmConvertModalOpen}
          fromValue={price}
          toValue={quantity}
          currencies={currencies}
          markets={marketsList}
          marketTickers={marketTickers}
          handleClose={() => setConfirmConvertModalOpen(false)}
          fromCurrency={
            currencies.length &&
            currencies?.find((el) => el.id === fromCurrency)
          }
          toCurrency={
            currencies.length && currencies?.find((el) => el.id === toCurrency)
          }
        />
      ) : null}
    </div>
  )
}

export default injectIntl(Convert)
